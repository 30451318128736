@import '@src/styles/vars.css';

.Payment {
  .cashVoucher {
    padding: 0;

    li {
      align-items: baseline;
      display: flex;
      margin-bottom: 20px;

      &::before {
        content: '';
        border-bottom: 1px dashed $grey-color;
        flex-grow: 1;
        order: 2;
        margin: 0 5px;
      }

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: $grey-color-name;
      }

      .value {
        order: 3;
        display: flex;
        align-items: baseline;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;

        .amount {
          display: flex;
          align-items: baseline;

          .styles.alarm {
            color: e84747;
            margin-left: 5px;
          }
        }

        .statusText {
          margin-left: 5px;
          font-size: 12px;
          line-height: 20px;
          color: $grey-color-dark;
        }
      }

      &.noFlex {
        display: block;
        text-align: center;

        .value {
          display: block;
          text-align: center;
        }
      }
    }
  }
}
