@import "@src/styles/vars.css";

.TextType {
  display: grid;
  grid-template-columns: auto 235px 20px;
  gap: 20px;
  padding-top: 30px;

  .selectTypeContainer {
    .isRequired {
      display: flex;
      padding-top: 15px;
      justify-content: flex-end;

      .fieldRequired {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 21.5px;
        margin-right: 16px;
        color: $grey-color-name;
      }
    }
  }

  .trash {
    height: 50px;
    cursor: pointer;
  }
}

.textContainerQ {
  padding-bottom: 30px;
  border-bottom: 1px solid $grey-green1-color;
}

.MultipleChoiceType,
.SingleChoiceType {
  .bodyChoice {
    display: grid;
    grid-template-columns: auto 255px 20px;
    gap: 20px;
  }
}

.textInputContainer {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
}

.TypeSelect {
  padding: 12px 20px 15px;
  background: $grey-light-color;
  border-radius: 10px;
  height: 47px;
  position: relative;

  .text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.4675px;
    padding-left: 15px;
    flex-grow: 1;
    color: $grey-color-name;
  }

  .long_text {
    width: 24px;
    height: 21px;
    margin-top: -1px;

    svg {
      fill: $grey-light-color;
    }
  }

  .single_choice,
  .single_choice_open {
    width: 24px;
    height: 23px;

    svg {
      fill: $grey-light-color;
    }
  }

  .multiple_choice,
  .multiple_choice_open {
    width: 24px;
    height: 21px;
    margin-top: 2px;
  }

  .label {
    display: flex;
  }

  ul {
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 12px 20px 15px;
    background: $grey-light-color;
    border-radius: 10px;

    li {
      list-style-type: none;
      display: flex;
      margin-bottom: 15px;
      margin-top: 15px;

      &:not(.active) {
        cursor: pointer;
      }

      .check {
        width: 30px;
        height: 30px;
        margin-top: -5px;
      }

      &.active {
        .text {
          color: $purple-color;
        }

        svg {
          stroke: $purple-color;

          rect {
            stroke: $purple-color;
          }

          path {
            stroke: $purple-color;
            fill: $purple-color;
          }
        }

        .check {
          svg {
            fill: $purple-color;
          }
        }
      }
    }
  }

  &.TypeSelectActive {
    ul {
      z-index: 1;
    }
  }
}

.TemplateChoice {
  ul {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;

    li {
      list-style-type: none;
      display: grid;
      grid-template-columns: 18px 50px auto;
      align-items: center;
      margin-bottom: 20px;

      .optionTrash {
        height: 18px;
        width: 18px;
        cursor: pointer;
      }

      .radioBut,
      .checkBox {
        height: 18px;
        width: 18px;
        border: 1.1594px solid $grey-color-name;
        margin-left: 16px;
        margin-right: 16px;
      }

      .radioBut {
        border-radius: 50%;
      }

      .checkBox {
        border-radius: 3px;
      }

      .textOptionsContainer {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.5421px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;

    .footerBut {
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      background: $grey-light-color;
      border-radius: 10px;
      padding: 10px 10px 10px 40px;
      margin-right: 10px;
      position: relative;

      span {
        font-size: 24px;
        font-weight: 400;
        position: absolute;
        left: 15px;
        top: -1px;
      }
    }
  }
}
