.Component {
  display: grid;
  width: 100%;
  grid-template-columns: 250px 250px;
  gap: 40px;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    .sub {
      font-weight: normal;
      color: var(--color-v2-text3);
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
