@import "@src/styles/vars.css";

.MyJoinedPrograms {
  /* display: flex;
  align-items: center;
  justify-content: center; */

  .programsHeader {
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;

    h3.programsHeaderTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.4675px;
      color: $black-text-color;
    }
  }

  .programsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(206px, auto));
    column-gap: 20px;
    row-gap: 20px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: $black-text-color;
      margin-bottom: 20px;
      margin-top: 30px;
    }

    .programContainer {
      height: 190px;
      width: 216px;

      .programLink {
        text-decoration: none;
      }
    }
  }
}

.noResultsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
