@import "../../../../styles/vars.css";

.VoiceMessage {
  background: $purple-color;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  padding: 5px;
  overflow: hidden;
  position: relative;
  min-width: 200px;

  .loaderContainer {
    svg {
      height: 50px;
      width: 50px;
    }
  }

  .progress {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $moderate-slate-blue-light;
    transition: width 0.2s ease-in;
  }

  .contentContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttonContainer {
      cursor: pointer;

      .playIconContainer,
      .pauseIconContainer {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
        background-color: $moderate-slate-blue-light-2;
        padding: 10px;

        .playIcon,
        .pauseIcon {
          fill: $white-color;
        }
      }
    }

    .time {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $moderate-slate-blue-light-2;
    }
  }
}
