.Scrollbar-trackY {
  height: 100% !important;
  top: 0 !important;
  width: 4px !important;
  background-color: rgba(var(--color-v2-background2-rgb), 0.75) !important;
}

.Scrollbar-trackX {
  width: 100% !important;
  left: 0 !important;
  height: 4px !important;
  background-color: rgba(var(--color-v2-background2-rgb), 0.75) !important;
}

.Scrollbar-thumbY,
.Scrollbar-thumbX {
  background-color: var(--color-v2-global1) !important;
}
