@import "@src/modules/library/FileCard.module.css";

.container {
  width: var(--file-card-width);
  height: var(--file-card-height);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(var(--color-v2-border2-rgb), 40%);
  padding: 15px;
  background-color: var(--color-v2-background3);
  position: relative;
  overflow: hidden;

  &.system {
    background-color: var(--color-v2-background5);
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.iconContainer {
  position: relative;
  width: 48px;
  height: 40px;
  display: flex;
  margin-bottom: 20px;
}

.starIcon {
  width: 18px;
  height: 18px;
  fill: var(--light-color-v2-icon2);
  position: absolute;
  left: 15px;
  top: 15px;
}

.dataContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  justify-content: flex-end;
}

.name {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--color-v2-text1);
  max-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: var(--color-v2-text3);
  max-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.moreButton {
  padding: 0;
  background-color: initial;
  position: absolute;
  right: 5px;
  top: 5px;

  svg {
    width: 28px;
    height: 28px;
    fill: var(--color-v2-icon3);
  }
}
