@import "@src/styles/vars.css";
@import url("https://fonts.googleapis.com/css2?family=Mr+De+Haviland&family=Seaweed+Script&display=swap");

.JoinProgram {
  .loadingSpinnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    border-bottom: 1px solid var(--color-v2-border1);
    padding: 20px 0 10px;

    &:first-child {
      padding-top: 0;
    }

    &.noBorder {
      border-bottom: none;
      padding-bottom: 0;
    }

    &.clickable {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        padding-right: 10px;
      }

      svg {
        fill: var(--color-v2-icon3);
      }
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.4675px;
      margin-bottom: 20px;
    }

    .errorTitle {
      color: var(--color-v2-error);
    }

    .text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.41px;
    }

    .subText {
      color: var(--color-v2-text3);
    }
  }

  .cardContainer {
    display: flex;
    align-items: center;
  }

  .cardBrand {
    width: 60px;
  }

  .cancellationText {
    font-size: 13px;
    line-height: 28px;
    letter-spacing: -0.41px;
    color: var(--color-v2-text3);
    padding-top: 10px;
  }

  .footer {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    background-color: var(--color-v2-background1);
  }

  .courseWrapper {
    border-radius: 16px;
    background-color: var(--color-v2-background7);
    padding: 20px 16px;

    .messageContainer {
      display: flex;
      padding-top: 10px;
    }

    .messageLine {
      width: 3px;
      border-radius: 3px;
      background-color: var(--color-v2-error);
      margin-right: 10px;
    }

    .message {
      font-size: 16px;
      line-height: 22px;
      color: var(--color-v2-error);
    }
  }

  .course {
    display: flex;
    justify-content: space-between;
  }

  .courseDate {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 4px;
  }

  .courseText {
    font-size: 16px;
    line-height: 22px;
    color: var(--color-v2-text4);
  }

  .personalInfoContainer {
    border-radius: 16px;
    background-color: var(--color-v2-background7);
    padding: 20px 16px;
  }

  .personalInfoRow {
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 20px;

    &.filled {
      .personalInfoRowTitle {
        color: var(--color-v2-text4);
      }

      .personalInfoRowIcon {
        fill: var(--color-v2-icon4);
      }
    }
  }

  .personalInfoRowTitle {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: var(--color-v2-error);
  }

  .personalInfoRowLine {
    color: var(--color-v2-text3);
    overflow: hidden;
    padding: 0 5px;
  }

  .personalInfoRowIcon {
    width: 24px;
    height: 24px;
    fill: var(--color-v2-error);
  }

  .personalInfoActionsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal.modal {
  max-width: 33vw;
}

.modalBody {
  position: relative;
}

.signContractModalFooter {
  padding: 15px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
