@import "@src/styles/vars.css";

.Practice {
  &.myCoaches {
    position: relative;
  }

  .imageContainer {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    max-width: 297px;
    box-shadow: 0 0 6px 0 rgba(50, 50, 50, 24%);

    .image {
      width: 297px;
      height: 210px;
      box-shadow: 0 4px 20px rgba(66, 56, 181, 10%);
      border-radius: 10px;
    }
  }

  .practiceTitle {
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    text-decoration: none;
    color: $black-text-color;
    max-width: 290px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .coachName {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.4675px;
    color: $grey-color-text;
    max-width: 290px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
