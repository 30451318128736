.Component {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .container.hiddenContainer {
    visibility: hidden;
  }

  .container.removedContainer {
    display: none;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  .actionButton {
    max-width: 250px;
    background-color: var(--color-v2-global1);
  }
}

.descriptionContainer {
  grid-column: span 2;

  textarea {
    height: 100px;
  }
}

.repeatOptions {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;

  .repeatOption {
    background: var(--color-v2-background2);
    border-radius: 100px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 15px;
    display: inline-block;
    cursor: pointer;
    color: var(--color-v2-text3);
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      background: var(--color-v2-global1);
      color: var(--light-color-v2-text2);
    }
  }
}

.eventTypeOptions {
  display: flex;
  justify-content: space-between;

  .eventTypeOption {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-v2-background2);
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    span {
      color: var(--color-v2-text3);
      font-size: 14px;
      font-weight: 600;
    }

    svg {
      height: 30px;
      margin-right: 10px;
      fill: var(--color-v2-text3);
    }

    .eventTypeOptionBorder {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
    }

    &.selected {
      span {
        color: var(--color-v2-global1);
      }

      svg {
        fill: var(--color-v2-global1);
      }

      .eventTypeOptionBorder {
        border: 1px solid var(--color-v2-global1);
      }
    }
  }
}

.courseRow {
  border-width: 1px;
  border-style: none none solid;
  border-color: var(--color-v2-border2);
  width: 100%;
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  &.disabled {
    cursor: initial;
  }

  svg {
    width: 24px;
    fill: var(--color-v2-icon3);
  }

  .courseTitle {
    padding-right: 10px;
  }

  .courseDates {
    font-size: 12px;
    color: var(--color-v2-text3);
  }

  .selectCourse {
    color: var(--color-v2-text3);
  }
}

.courseError {
  color: var(--color-v2-error);
  font-size: 14px;
}
