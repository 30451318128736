@import '@src/styles/vars.css';

.customButtonContainer {
  .customButton {
    background: #f24c00;
    border-radius: 6px;
    padding: 17px 36px 17px 36px;
    border: 1px solid #f24c00;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: $white-color;
    transition: 0.2s ease opacity;
    outline: none;

    &:disabled {
      background: $grey-color-label;
      border: 1px solid $grey-color-label;
    }

    &.standardButton {
      font-size: 13px;
      line-height: 16px;
      font-style: normal;
      font-weight: initial;
      text-transform: initial;
      padding: 13px 39px 13px 39px;
      cursor: pointer;
    }

    &.borderRadius10 {
      border-radius: 10px;
    }

    &.gradientGreenBut {
      background: linear-gradient(
        0deg,
        $green-liner-one-color-text 0%,
        $green-liner-two-color-text 100%
      );
      border-radius: 6px;
    }
  }

  .QuestionnaireHeaderBut {
    height: 38px;
    background: $grey-light-color;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: none;
    padding: 0 20px;
    border: initial;
    cursor: pointer;
    margin-top: 21px;
    margin-bottom: 21px;
    color: $grey-color-dark;

    .IconPrev {
      height: 16px;
      width: 16px;
    }

    span {
      display: inline-block;
      vertical-align: text-bottom;
      margin-left: 16px;
    }
  }

  .QuestionnaireUserAnswerBackBut {
    background: $green-blue-text;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
    padding: 20px 50px;
    border: initial;
    cursor: pointer;
    color: $white-color;

    .IconPrev {
      height: 14px;
      width: 14px;
      fill: $white-color;
    }

    span {
      display: inline-block;
      vertical-align: text-bottom;
      margin-left: 16px;
    }
  }

  .QuestionnaireContinueBut {
    height: 64px;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    text-transform: none;
    padding: 0 80px;
    background: linear-gradient(
      0deg,
      $green-liner-one-color-text 0%,
      $green-liner-two-color-text 100%
    );
    cursor: pointer;

    .IconNext {
      fill: $white-color;
      height: 18px;
      width: 18px;
    }

    span {
      display: inline-block;
      vertical-align: text-bottom;
      margin-right: 16px;
    }
  }

  .blueButt {
    height: 44px;
    width: 100%;
    background: $purple-color;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    text-transform: none;
    padding: 0 30px;
    border: initial;
    cursor: pointer;
    color: $white-color;

    &:disabled {
      background: $purple-opacity-color;
      color: $white-color;
      border: initial;
      cursor: default;
    }
  }

  .greyButt {
    width: 100%;
    background: $grey-light-color;
    border-radius: 10px;
    text-transform: none;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    padding: 10px 0;
    border: initial;
    cursor: pointer;
    color: $grey-color-name;
  }

  .greenBorderButt {
    width: 100%;
    border-radius: 10px;
    text-transform: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: $green-blue-color-text;
    padding: 10px 0;
    background: none;
    cursor: pointer;
  }

  .greyBorderButt {
    border-radius: 10px;
    text-transform: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: $grey-color-dark;
    padding: 0;
    padding-top: 6px;
    height: 44px;
    width: 44px;
    background: none;
    cursor: pointer;
    border: 1px solid $grey-color-dark;
    text-align: center;

    .icon {
      margin-right: 0;

      svg {
        path {
          stroke-width: 1.2px;
        }
      }
    }
  }
}

.containerCButtonDesign {
  margin: 29px 0;

  .CButtonDesign {
    width: 100%;
  }
}

.ovalButton {
  padding: 10px 30px;
  background: $grey-light-but-color;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.41px;
  color: $grey-color-name;
}

.allWidth {
  width: 100%;
}

.gradientGreenBut {
  background: linear-gradient(
    0deg,
    $green-liner-one-color-text 0%,
    $green-liner-two-color-text 100%
  );
  border-radius: 6px;
}

.createBut {
  background: $green-blue-color-text;
  border-radius: 10px;
  width: 100%;
  display: flex;
  padding: 10px 8px 10px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .butTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $white-color;
  }

  .createIcon {
    display: flex;
    height: 17px;
    width: 17px;
    background: $green-color-light;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: $white-color;
    font-size: 14px;
    padding-bottom: 2px;
    margin-left: 10px;
  }
}

.addItemsButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.addLibraryItemsButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 28px;
  gap: 8px;
  width: 100px;
  height: 40px;
  background: #25d2a2;
  border-radius: 12px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #ffffff;
}
