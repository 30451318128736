.LogoOnlyLayout {
  position: relative;

  .header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    position: absolute;
  }

  .logoContainer {
    margin-left: 25px;
    margin-right: 65px;
    width: 40px;
    height: 40px;
  }

  .backButton {
    height: 38px;
    padding-left: 6px;
    display: flex;
    align-items: center;
    background-color: var(--color-v2-button-fill3);
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-v2-text3);

    svg {
      width: 28px;
      height: 28px;
      fill: var(--color-v2-text3);
    }
  }

  &.v2 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .logoContainer {
      position: absolute;
    }

    .header {
      position: relative;
    }

    .content {
      margin: 0 95px;
    }

    .headerRightPart {
      margin: 0 95px;
    }
  }
}
