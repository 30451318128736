.AddSectionQ {
  padding: 30px;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
  }

  .inputsContainer {
    display: grid;
    align-items: end;
    grid-template-columns: auto 105px;
    gap: 35px;

    .mb8 {
      margin-bottom: 8px;
    }
  }
}
