.Component {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.resultsContainer {
  flex: 1;

  .foundCoach {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid var(--color-v2-border2);

    &:last-child {
      border: none;
    }

    &.isntProvider {
      .name {
        color: var(--color-v2-error);
      }
    }

    .leftPart {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      overflow: hidden;
      margin-right: 20px;
    }

    .nameContainer {
      display: flex;
      align-items: center;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4675px;
      color: var(--color-v2-text1);
      margin-right: 20px;
      text-decoration: none;
    }

    .message {
      padding: 6px 10px;
      border-radius: 10px;
      background-color: var(--color-v2-background2);
      font-size: 12px;
      line-height: 20px;
      color: var(--color-v2-text3);
    }

    .addButton {
      background-color: initial;
      padding: 0;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4675px;
      color: var(--color-v2-text3);
    }

    .addIcon {
      width: 24px;
      height: 24px;
      fill: var(--color-v2-icon3);
      margin-right: 10px;
    }
  }
}

.selection {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.selectedCoach {
  display: flex;
  align-items: center;
  background-color: var(--color-v2-background2);
  padding: 0 8px;
  height: 40px;
  border-radius: 40px;
  margin: 0 15px 15px 0;

  .avatar {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    overflow: hidden;
    margin-right: 8px;
  }

  .name {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4675px;
    color: var(--color-v2-text1);
    margin-right: 10px;
  }

  .deleteButton {
    background-color: initial;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--color-v2-icon3);
    }
  }
}

.noResultsContainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-v2-background1);
}

.modalBody {
  width: 70vw;
  min-height: 50vh;
}
