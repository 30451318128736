@import url("../../styles/vars.css");

.AddShortTermGoals {
  text-align: center;
  border-radius: 10px;
  border: 1px dashed #593562;
  padding: 16px 0;
  margin-top: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.41px;
  color: #593562;
  cursor: pointer;
}

.AddStepBtn {
  background-color: #1f313c;
  border-radius: 10px;
  letter-spacing: -0.41px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: 100px;
  padding: 8px 20px;
  color: #fff;
}

.CreateBtn {
  background-color: #f24c00;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  padding: 16px 0;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.SubgoalsAndCRUSubgoalsContainer {
  display: flex;
}

.AddGoals {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;

  .topMenuContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    .topButt {
      background: linear-gradient(0deg, $grey-light-color, $grey-light-color);
      border-radius: 10px;
      width: 275px;
      padding: 12px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      cursor: pointer;

      &.active {
        background: linear-gradient(0deg, $white-color, $white-color);
        border: 1px solid $purple-color;
        color: $purple-color;
        cursor: default;
      }
    }
  }

  .contentContainer {
    flex-grow: 1;
    height: calc(70vh - 104px - 99px);

    .NoResults {
      min-height: 40vh;
    }

    .textContainer {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 15px !important;
      line-height: 18px !important;
      text-align: center !important;
      letter-spacing: -0.41px !important;
      color: $grey-color-dark !important;
    }

    .iconNoResultContainer {
      height: 50px;
      width: 50px;

      svg {
        fill: $grey-light-but-color;
      }
    }
  }

  .butMenuContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    .addGoalBut {
      background: $green-blue-color-text;
      border-radius: 7px;
      padding: 14px 40px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      height: initial;
      text-align: center;
      letter-spacing: -0.192px;
      display: flex;
      align-items: baseline;

      span {
        margin-right: initial;
      }

      .plusIcon {
        margin-right: 8px;
        font-size: 20px;
        font-weight: 300;
      }
    }

    .chooseGoalBut {
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      padding: 14px 40px;
      height: initial;
    }
  }
}

.CRUGoal {
  padding: 30px;
  height: 100%;

  .CreateEvent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin: 30px 0;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .createFooterButtContainer {
    display: flex;
    justify-content: flex-end;

    .createButtContainer {
      max-width: 150px;
    }
  }
}

.GoalsListItem {
  background: $white-color;
  border: 1px solid $grey-color-2;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: 40px auto 40px;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.2s;
  height: 100%;

  &.active {
    border: 1px solid $purple-color;
    box-sizing: border-box;
    border-radius: 15px;
  }

  .checkBoxContainer {
    background: $grey-light-color;
    border-right: 1px solid $grey-color-2;
    box-sizing: border-box;
    border-radius: 15px 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .checkBox {
      width: 17px;
      height: 17px;
      background: $white-color;
      border: 1px solid $grey-color-2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      &.active {
        background: $purple-color;
        border: 1px solid $purple-color;

        svg {
          fill: $white-color;
        }
      }
    }
  }

  .goalContainer {
    padding: 20px 10px 20px 13px;

    .goalLabe {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.41px;
      color: $moderate-slate-blue-light;
    }

    .goalTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.41px;
      color: $purple-color;
    }

    .goalDescription {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.08px;
      color: $moderate-slate-blue-light;
    }
  }

  .moreButContainer {
    display: flex;
    align-items: baseline;
    width: 100%;
    height: 100%;

    .moreBut {
      width: 20px;
      height: 30px;
      margin-top: 15px;
      font-size: 24px;
      color: $moderate-slate-blue-light;
      cursor: pointer;
      text-align: center;
    }
  }
}

.SelectGoal {
  padding: 20px 40px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .menuSearchContainer {
    padding-bottom: 20px;

    .searchContainer {
      padding: initial;
      background: initial;
      border-radius: initial;
    }

    .searchContainerInput {
      display: block;
    }
  }

  .contentContainer {
    flex-grow: 1;
    height: calc(70vh - 104px - 99px);

    .headerSectionContainer {
      display: flex;
      align-items: center;
      padding-top: 10px;

      .sectionAvatarContainer {
        height: 40px;
        width: 40px;
        margin-right: 10px;

        .sectionAvatar {
          border-radius: 12px;
        }
      }

      .headerTitleContainer {
        .programTitleContainer {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.41px;
          color: $grey-color-name;
        }

        .courseTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.41px;
        }
      }
    }

    .goalsContainer {
      margin: 20px 0;
      padding: 20px;
      border: 1px solid $grey-color-2;
      box-sizing: border-box;
      border-radius: 15px;

      &.isSelected {
        border: 1px solid $purple-color;
      }
    }
  }

  .buttonContainer {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;

    .createButtContainer {
      width: 90px;

      .blueButt {
        padding: initial;
      }
    }
  }
}

.checkBox {
  margin-right: 15px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid $grey-color-2;
  background-color: $white-color;
  flex-shrink: 0;

  &.selected {
    border: 1px solid $purple-color;
    background: $purple-color;
    position: relative;

    svg {
      fill: $white-color;
      position: absolute;
      left: -1px;
      top: 1px;
    }
  }
}

.ListItem {
  .mainGoalContainer {
    cursor: pointer;
    display: flex;
    align-items: center;

    .mainGoalContentContainer {
      flex-grow: 1;

      .indexGoal {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.41px;
        color: $moderate-slate-blue-light;
      }

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.41px;
        color: $purple-color;
      }
    }

    .toggleArrowContainer {
      height: 20px;
      width: 20px;
      transform: rotate(-90deg);
      transition: all 0.2s ease;

      &.open {
        transform: rotate(90deg);
      }
    }
  }
}

.PreviousGoalsListItem {
  background: $white-color;
  border: 1px solid $grey-color-2;
  box-sizing: border-box;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 20px;

  .mainGoalContainer {
    display: flex;
    align-items: center;

    .titleContainer {
      flex-grow: 1;

      .mainGoalLabel {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.41px;
        color: $moderate-slate-blue-light;
      }

      .mainGoalTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.41px;
        color: $purple-color;
      }
    }

    .lastMenuContainer {
      display: flex;
      align-items: center;

      .iconTrash {
        height: 20px;
        width: 20px;
        cursor: pointer;
        flex-shrink: 0;

        svg {
          fill: $moderate-slate-blue-light;
        }
      }

      .moreMenu {
        font-size: 20px;
        padding-left: 5px;
        margin-left: 10px;
        color: $moderate-slate-blue-light;
        cursor: pointer;
      }
    }
  }
}

.CRUSubgoal {
  padding: 0 20px 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;

  .label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4675px;
    color: $black-text-color;
  }

  .chooseContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 25px;

    .iconContainer {
      .chooseIconContainer {
        margin-top: 10px;
        height: 48px;
        width: 48px;
        padding: 3px;
        background:
          linear-gradient(
            0deg,
            $periwinkle-crayola,
            $periwinkle-crayola
          );
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .colorContainer {
      .chooseColorContainer {
        margin-top: 10px;
        border-radius: 13px;
        height: 56px;
        width: 56px;
        cursor: pointer;
        position: relative;
        z-index: 1;

        &::before {
          content: "";
          display: block;
          position: absolute;
          border-radius: 12px;
          top: 1px;
          left: 1px;
          border: solid 3px $white-color;
          width: 54px;
          height: 54px;
          z-index: 10;
        }
      }
    }
  }

  .dateRangeContainer {
    padding-top: 25px;
  }

  .typeContentContainer {
    margin-top: 25px;

    .typesContainer {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .typeContainer {
        width: 100%;
        background: #ececec;
        border-radius: 8px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        padding: 11px;
        cursor: pointer;
        transition: all 0.3s ease;
        text-align: center;
        color: $black-text-color;

        &.active {
          background: $white-color;
          border: 1px solid #1f313c;
        }
      }
    }
  }

  .section {
    margin-top: 35px;
    height: 120px;

    .stepContainer {
      display: flex;
      align-items: baseline;

      .radioIconContainer {
        height: 25px;
        width: 27px;
        padding-top: 12px;
        flex-shrink: 0;

        .radioIcon {
          height: 17px;
          width: 17px;
          border: 1px solid $grey-color-border;
          border-radius: 50%;
        }
      }

      .inputContainer {
        flex-grow: 1;

        .input {
          input {
            padding: 10px 0;
            border-bottom: initial;
          }

          label {
            padding: 13px 0 0;
          }
        }
      }

      .trashIconContainer {
        height: 20px;
        width: 20px;
        flex-shrink: 0;
        cursor: pointer;
      }
    }

    .addStepButtonText {
      width: auto;
      margin-top: 10px;
      padding: 5px 20px;
      height: auto;
    }
  }

  .sectionMetric {
    margin-top: 35px;

    .label {
      margin-bottom: 10px;
    }
  }

  .sectionSubgoalCategory {
    .categoriesContainer {
      padding: 10px 0;

      .category {
        background: $grey-light-but-color;
        border-radius: 10px;
        padding: 5px 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        width: auto;
        color: $grey-color-name;
        margin-right: 9px;
        margin-bottom: 9px;
        display: inline-block;
        cursor: pointer;
        transition: all 0.2s ease;

        &.selected {
          background: $grey-color-name;
          color: $white-color;
        }
      }
    }
  }

  .sectionMetricsInput {
    margin-top: 0;
  }

  .createButContainer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

    .createBut {
      width: auto;
    }
  }
}

.SelectGoalIcon {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;

    .item {
      height: 60px;
      width: 60px;
      border-radius: 10px;
      transition: all 0.2s ease;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: $grey-color-border-1;
      }
    }
  }

  .createButContainer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

    .createBut {
      width: auto;
    }
  }
}

.SelectGoalColor {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    padding: 0 20px;

    .item {
      height: 60px;
      width: 60px;
      border-radius: 10px;
      transition: all 0.2s ease;
      padding: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .selectedItem {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: $opacity-black-2;
        position: relative;

        svg {
          position: absolute;
          top: 3px;
          left: 2px;
          width: 80%;
          height: 80%;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: $white-color-00;
        transition: all 0.3s ease;
        top: 0;
        left: 0;
        border-radius: 10px;
      }

      &:not(.active) {
        cursor: pointer;

        &:hover {
          &::after {
            background: $grey-color-name-opacity-1;
          }
        }
      }
    }
  }

  .createButContainer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

    .createBut {
      width: auto;
    }
  }
}

.SubgoalsListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  transition: all 0.2s ease;

  &.onClickable {
    cursor: pointer;
  }

  &.thisSelect {
    background: $grey-color-name;
  }

  .contentWrapper {
    display: flex;
    align-items: center;

    .iconContainer {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .contentContainerSubgoal {
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.4675px;
        transition: all 0.2s ease;
      }

      .durationContainer {
        display: flex;
        align-items: center;

        .flagIconContainer {
          height: 16px;
          width: 16px;
          margin-right: 7px;
          flex-shrink: 0;
        }

        .duration {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: -0.08px;
          transition: all 0.2s ease;
        }
      }
    }
  }

  .deleteIconContainer {
    height: 20px;
    width: 20px;
  }

  .moreBut {
    width: 20px;
    height: 30px;
    font-size: 24px;
    color: $white-color;
    cursor: pointer;
    text-align: center;
  }

  .nextIconContainer {
    height: 18px;
    width: 14px;

    .CustomSelectArrow {
      transform: rotate(180deg);
      fill: $white-color;
    }
  }
}

.SubgoalsListItemVariantHeader {
  margin-bottom: 26px;

  &.onClickable {
    cursor: pointer;
  }

  &.thisSelect {
    background: $grey-color-name;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .durationContainer {
      display: flex;
      align-items: center;
      height: 30px;

      .flagIconContainer {
        height: 16px;
        width: 16px;
        margin-right: 7px;
        flex-shrink: 0;
      }

      .duration {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        transition: all 0.2s ease;
      }
    }

    .moreButContainer {
      .moreBut {
        font-size: 20px;
        padding-left: 5px;
        margin-left: 10px;
        cursor: pointer;
        color: $grey-color-dark;
      }
    }
  }

  .contentWrapper {
    display: flex;

    .iconContainer {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
      border-radius: 10px;
      padding: 10px;
      flex-shrink: 0;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: $black-text-color;
    }
  }
}

.iconNoResult {
  svg {
    fill: $grey-color-dark;
  }
}

.iconNoResultContainer {
  height: 50px;
  width: 50px;

  svg {
    fill: $grey-color-dark;

    path {
      fill: $grey-color-dark;
    }
  }
}

.goal_index {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #000;
}

.goal_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000;
}

.Goal {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .columnSelectGoal {
    width: 100%;
    background: #f7f7f7;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 15%);
    border-radius: 20px;
    padding: 30px 48px 33px 44px;

    @media (min-width: 320px) and (max-width: 799px) {
      border-radius: 18px;
      padding: 14px 26px 25px 19px;
    }
  }

  .columnSelectUser {
    width: 100%;
    margin-left: 20px;
    position: relative;
  }

  .iconNoResultContainer {
    height: 50px;
    width: 50px;
    flex-shrink: 0;

    svg {
      fill: $grey-color-dark;

      path {
        fill: $grey-color-dark;
      }
    }
  }

  .NoResults {
    min-height: 30vh;
  }

  .subgoalContainer {
    .subgoalHeaderContainer {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: -0.4675px;
    }

    .progressSpinnerContainer {
      display: block;
    }

    .subgoalProgressHeaderContainer {
      padding: 0 20px 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .subgoalProgressHeader {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 23px;
      }

      .subgoalProgressSubHeader {
        margin-left: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 23px;
        color: $grey-color-dark;
      }
    }
  }
}

.GoalsSelectCourseTab {
  height: calc(100% + 350px);
  margin-top: 32px;
  margin-right: 29px;
  margin-left: 29px;
  width: 100%;

  .header {
    .titleHeader {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
    }

    .tabButMenu {
      .selectCourseContainer {
        width: 180px;

        .goalsFilterContainer {
          display: flex;
          align-items: baseline;

          .goalsFilter {
            background: $grey-color-1;
            border-radius: 10px;
            cursor: pointer;

            span {
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 18px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              letter-spacing: -0.08px;
              color: $blue-grey-light-color-2;
            }
          }

          .goalsFilterContainerClear {
            color: $blue-grey-light-color-2;
            cursor: pointer;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 799px) {
  .GoalsSelectCourseTab {
    margin: 18px 10px 0;
  }
}

.BoundSubgoalsListItem {
  display: flex;
  padding: 7px 20px;
  align-items: center;
  justify-content: space-between;

  &.onClickable {
    cursor: pointer;
  }

  .userContainer {
    display: flex;
    align-items: center;

    .userAvatar {
      height: 40px;
      width: 40px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .userName {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .progressContainer {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .checkIconContainer {
      width: 30px;
      height: 30px;
      flex-shrink: 0;

      svg {
        fill: $green-blue-color-text;
      }
    }

    .commentIconContainer {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .progress {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      &.isReached {
        color: $green-blue-color-text;
      }

      .checkpoints {
        display: block;
      }
    }
  }

  &.active {
    .userContainer {
      .userName {
        color: $white-color;
      }
    }

    .progressContainer {
      .checkIconContainer {
        svg {
          fill: $mint-green-color;
        }
      }

      .progress {
        color: $white-color;

        &.isReached {
          color: $mint-green-color;
        }

        .checkpoints {
          display: block;
        }
      }
    }
  }
}

.ClientGoal {
  background: $white-color;
  box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
  border-radius: 15px;
  padding: 20px;

  .header {
    padding-bottom: 30px;

    .clientRow {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template-columns: auto 100px auto;
      padding-bottom: 15px;

      .clientButtonInfoContainer {
        width: 100%;

        .clientButtonInfo {
          width: 40px;
          height: 40px;
          background: $grideperlevy;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $blue-grey-light-color-2;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .bigUserAvatar {
        width: 100px;
        height: 100px;
      }

      .clientButtonChatContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .clientButtonChat {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background:
            linear-gradient(
              180deg,
              $green-color 0%,
              $green-color 100%
            );
          transform: scale(-1, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .clientName {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
    }
  }

  .subgoalContainer {
    padding-bottom: 20px;

    .subgoalProgressHeaderContainer {
      display: flex;
      align-items: center;
      padding: initial;

      .subgoalProgressHeader {
        display: block;
      }

      .subgoalProgressSubHeader {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;

        &.reached {
          color: $green-blue-color-text;
        }

        .checkIconContainer {
          width: 30px;
          height: 30px;
          flex-shrink: 0;

          svg {
            fill: $green-blue-color-text;
          }
        }
      }
    }
  }

  .clientPartContainer {
    display: block;

    .checkpointContainer {
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      .checkBox {
        margin-right: 10px;

        &.active {
          display: block;
        }
      }

      .checkpointTitle {
        display: block;

        &.reached {
          text-decoration-line: line-through;
        }
      }
    }

    .progressBar {
      display: block;

      .progressBarFill {
        display: block;
      }
    }

    .commentContainer {
      display: block;

      .commentHeader {
        display: block;
      }

      .comment {
        display: block;
      }
    }

    .attitudeContainer {
      padding-top: 20px;

      .attitudeHeader {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 23px;
      }

      .attitude {
        background: $grideperlevy;
        border-radius: 10px;
        padding: 8px 15px 8px 8px;
        width: max-content;
        font-size: 30px;
        margin-top: 10px;

        .attitudeText {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
}

.section_Container:last-child {
  margin-top: 19px;
  padding: 30px 22px 20px 26px;
  background-color: #fff;
  border: 1px solid #e5ecf0;
  border-radius: 15px;

  @media (min-width: 320px) and (max-width: 799px) {
    padding: 20px 10px;
  }
}

.GoalsTab {
  display: block;

  .CenteredNoGoals {
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .IconNoGoals {
    margin-bottom: 18px;

    @media (min-width: 320px) and (max-width: 799px) {
      margin-bottom: 27px;
    }
  }

  .ParagraphNoGoals {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #778288;
  }

  .CenteredTextNoGoals {
    text-align: center;
    width: 470px;

    @media (min-width: 320px) and (max-width: 799px) {
      width: 290px;
    }
  }

  .sectionContainer {
    margin-bottom: 10px;

    .programContainer {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      .programAvatarContainer {
        height: 32px;
        width: 32px;
        margin-right: 10px;
      }

      .programInfoContainer {
        display: block;

        .programSubTitle {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.41px;
          color: $grey-color-name;
        }

        .programTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 18px;
        }

        .courseClientName {
          font-style: normal;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .goalSection {
      background: #f7f7f7;
      border-radius: 15px;
      padding: 20px 20px 24px;
      margin-bottom: 11px;

      .itemContainer {
        display: block;

        .listItemContainer {
          display: flex;
          flex-direction: column;

          .listItemContentContainer {
            display: block;

            .goalIndex {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: -0.41px;
              color: #819aa9;

              @media (min-width: 320px) and (max-width: 799px) {
                font-size: 12px;
                line-height: 14px;
              }
            }

            .goalTitle {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 27px;
              margin-bottom: 12px;
              color: #000;

              @media (min-width: 320px) and (max-width: 799px) {
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
              }
            }

            .goalShortTermSubtitle {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: -0.46px;
              color: #000;
              padding-bottom: 0.5rem;
            }

            .goalDescription {
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 18px;
              padding-bottom: 20px;
              color: $moderate-slate-blue-light;
            }
          }

          .subgoalsHeaderContainerStyle {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .subgoalsHeaderLabel {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: -0.46px;
              color: #000;
            }
          }

          .subgoalsHeaderButtonText {
            margin-top: 19px;
            border: 1px dashed $moderate-slate-blue-light;
            border-radius: 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            padding: 20px;
            text-align: center;
            color: $purple-color;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 799px) {
  .GoalsTab {
    .sectionContainer {
      .goalSection {
        padding: 13px 10px 10px;
      }
    }
  }
}

.clientPartContainer {
  padding: 0 20px 20px;

  textarea:focus,
  input:focus {
    outline: none;
  }

  .checkpointContainer {
    display: flex;
    align-items: center;
    padding-top: 20px;

    .checkBox {
      margin-right: 10px;
    }

    .checkpointTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;

      &.doneCheckpointTitle {
        text-decoration-line: line-through;
      }
    }
  }

  .progressBar {
    height: 10px;
    width: 100%;
    display: flex;
    background: $grideperlevy-1;
    border-radius: 14px;
    margin-top: 20px;
    margin-bottom: 20px;

    .progressBarFill {
      height: 100%;
      border-radius: 6px;
    }
  }

  .progressBarValue {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: $grideperlevy-1;
  }

  .addProgressContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $grey-light-but-color;
    border-radius: 10px;
    padding: 18px 10px 18px 20px;

    .addProgressTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: $grey-color-name;
    }

    .addProgressContent {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .addProgressButton {
        height: 32px;
        width: 32px;
        border-radius: 6px;
        background: $moderate-slate-blue-light-2;
        color: $purple-color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 22px;
        -webkit-touch-callout: none;
        user-select: none;
      }

      input {
        width: 50px;
        height: 32px;
        border: none;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        background: none;
        text-align: center;
      }
    }
  }

  .commentInputContainer {
    padding-top: 20px;

    textarea {
      background: $white-color;
      border: 1px solid $grey-blue2-color;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px;
      width: 100%;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $grey-color-name;
      }
    }
  }

  .commentContainer {
    padding-top: 30px;

    .commentHeader {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      padding-bottom: 15px;
    }

    .comment {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: $grey-color-name;
    }
  }
}

.saveButContainer {
  padding: 0 20px;

  .saveBut {
    width: 100%;
  }
}

.confirm_button {
  margin-left: 528px;
  width: 150px;
  padding: 10px 46px;
  border-radius: 12px;
  background: #f24c00;
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  letter-spacing: -0.24px;

  @media (min-width: 320px) and (max-width: 799px) {
    margin-left: 0;
    width: 100%;
  }
}

.confirm_subgoal_rate {
  width: 100%;
  padding: 15px 0;
  border-radius: 10px;
  background: #f24c00;
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  letter-spacing: -0.24px;
}

.confirm_goals_rate {
  width: 100%;
  padding: 15px 0;
  border-radius: 10px;
  background: #f24c00;
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  letter-spacing: -0.24px;
}

.RateSubgoal {
  display: block;

  .headerPic {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 41px;
    background-color: #f7f7f7;

    .picContainer {
      position: relative;
      overflow: hidden;

      .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        animation-delay: 0.7s;
      }

      .flowers {
        position: absolute;
        bottom: 0;
      }

      .leftFlags {
        position: absolute;
        left: 0;
        height: 150px;
        width: 150px;
        animation-delay: 0.3s;

        img {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
      }

      .rightFlags {
        position: absolute;
        right: 0;
        height: 150px;
        width: 100px;
        animation-delay: 0.5s;

        img {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
      }

      .woman {
        position: absolute;
        bottom: 0;
        left: 15px;
        animation-delay: 0.9s;
      }
    }
  }

  .firstPanelContainer {
    display: block;
    background-color: #f7f7f7;

    .firstPanelContentContainer {
      padding: 22px 20px 29px;

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #000;
      }

      .description {
        padding-top: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #000;
      }
    }

    .secondPanelContainer {
      background: #fff;
      border-radius: 10px;
      padding: 32px 32px 37px;

      .attitudeTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #f24c00;
      }

      .sliderContainer {
        padding-top: 20px;

        .settingItem {
          display: block;
        }

        .sliderContentContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;

          .swipeItLabel {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: $moderate-slate-blue-light;
          }

          .sliderValueContainer {
            display: flex;
            align-items: center;

            .attitudeIcon {
              font-size: 30px;
            }

            .sliderValue {
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              line-height: 29px;
              width: 80px;
              text-align: right;
              color: $purple-color;
            }
          }
        }
      }

      .confirmButContainer {
        padding-top: 20px;
      }
    }
  }
}

.top_border {
  border-radius: 10px;
}

.RateGoals {
  display: block;

  .headerContainer {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 23px;

    .cup {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .headerTitle {
      padding-top: 14px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      color: #000;
    }

    .headerDescription {
      width: 412px;
      padding-top: 8px;
      padding-left: 83px;
      padding-right: 74px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #000;
    }
  }

  .contentContainer {
    padding: 18px 0 33px;

    .listHeader {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      padding-bottom: 27px;
      color: #f24c00;
      text-align: center;
    }

    .goalsListContainer {
      display: block;

      .itemContainer {
        padding-left: 52px;
        padding-right: 49px;
        margin-bottom: 55px;

        .itemContentContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .goalLabel {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #000;
            letter-spacing: -0.41px;
          }

          .goalTitleText {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #000;
          }

          .goalDescription {
            margin-top: 5px;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            color: $moderate-slate-blue-light;
          }
        }

        .sliderContainer {
          padding-top: 20px;

          .sliderContentContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px;

            .swipeItLabel {
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #000;
              letter-spacing: -0.08px;
            }

            .sliderValueContainer {
              display: flex;
              align-items: center;

              .attitudeIcon {
                font-size: 30px;
              }

              .sliderValue {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                width: 80px;
                text-align: right;
                color: $purple-color;
              }
            }
          }
        }
      }
    }

    .footerButtonContainer {
      margin: 23px 49px 0 50px;
    }
  }
}

.RateGoal {
  background: $white-color;
  box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
  border-radius: 15px;
  width: 100%;
  padding: 20px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }

  .goalContainer {
    padding-bottom: 20px;

    .goalTitleContainer {
      padding-top: 10px;

      .goalSubtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.41px;
        color: $moderate-slate-blue-light;
      }

      .goalTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.41px;
        color: $purple-color;
      }

      .shortTermGoalsTitle {
        font-family: "GT Walsheim Pro";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.4675px;
        color: #000;
      }
    }
  }

  .labelContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.4675px;
    color: $grey-color-dark;
  }
}

.RateBoundGoalsListItem {
  padding-top: 10px;
  display: flex;
  align-items: center;

  .userAvatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .rowContainer {
    flex-grow: 1;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .userName {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.4675px;
      }

      .content {
        display: flex;
        align-items: center;

        .buttonsContainer {
          display: flex;
          align-items: center;
          margin-right: 15px;

          .disabledButton {
            background: $green-blue-color-text;
            border-radius: 10px;
            width: 70px;
            height: 30px;
            padding: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.208px;
            color: $white-color;

            &:disabled {
              background: $green-color-opacity;
            }
          }

          .disagreeDisabledButtonStyle {
            background: $grideperlevy;
            border-radius: 10px;
            width: 70px;
            height: 30px;
            padding: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.208px;
            margin-left: 10px;
            color: $grey-color-name;

            &:disabled {
              background: $grideperlevy-opacity;
              color: $grey-color-name-opacity;
            }
          }
        }

        .attitude {
          .attitudeString {
            display: flex;
            align-items: center;

            .icon {
              font-size: 26px;
            }

            .text {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
              width: 40px;
              text-align: right;
              letter-spacing: -0.4675px;
            }
          }
        }
      }
    }

    .commentContainer {
      padding-top: 0;

      .comment {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: $grey-color-name;
      }
    }
  }
}

.EditGoalAttitude {
  display: block;

  .headerContainer {
    padding: 20px 40px;

    .header {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      padding-bottom: 20px;
    }

    .clientContainer {
      display: block;

      .clientHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        padding-bottom: 10px;
        letter-spacing: -0.4675px;
        color: $grey-color-dark;
      }

      .client {
        display: flex;
        align-items: center;

        .userAvatar {
          height: 40px;
          width: 40px;
          margin-right: 10px;
          flex-shrink: 0;
        }

        .content {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .userName {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }

          .attitude {
            display: block;

            .attitudeString {
              display: flex;
              align-items: center;

              .icon {
                width: 30px;
                text-align: right;
                flex-shrink: 0;
              }

              .text {
                order: -1;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .contentContainer {
    background: $moderate-slate-blue-light-2;
    border-radius: 10px;
    padding: 30px 40px;

    .sliderContainer {
      display: block;

      .settingItem {
        display: block;
      }

      .sliderContentContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5px;

        .swipeItLabel {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          letter-spacing: -0.08px;
          color: $moderate-slate-blue-light;
        }

        .sliderValueContainer {
          display: flex;
          align-items: center;

          .attitudeIcon {
            font-size: 34px;
          }

          .sliderValue {
            width: 75px;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            text-align: right;
            letter-spacing: -0.4675px;
            color: $purple-color;
          }
        }
      }
    }

    .footerButtonContainer {
      padding-bottom: 10px;
      padding-top: 20px;
    }

    .commentInputContainer {
      padding-top: 20px;

      textarea {
        background: $white-color;
        border: 1px solid $grey-blue2-color;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        width: 100%;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: $grey-color-name;
        }
      }
    }
  }
}

.clientSectionHeader {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .clientContent {
    display: flex;
    align-items: center;

    .avatarContainer {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    .clientName {
      padding-left: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.4675px;
    }
  }

  .rightContainer {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .checkIconContainer {
      height: 28px;
      width: 28px;
      flex-shrink: 0;

      svg {
        fill: $green-color;
      }
    }

    .clientSubgoalsCount {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 125%;
      text-align: right;
      letter-spacing: -0.4675px;

      &.done {
        color: $green-color;
      }
    }

    .arrowNextIconContainer {
      height: 22px;
      width: 22px;
      flex-shrink: 0;
      transform: rotate(90deg);
      margin-left: 10px;
      transition: all 0.5s ease-in 0s;

      svg {
        fill: $grey-color-dark;
      }

      &.open {
        transform: rotate(270deg);
      }
    }
  }
}

.LoaderWrapper {
  min-height: 490px;
  display: flex;
  justify-content: center;
}

.LoaderGoals {
  transform: translateY(-50%);
}
