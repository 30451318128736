.Select {
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 3px 0;
    margin: 0;
    width: 100%;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    border-width: 1px;
    border-style: none none solid none;
    border-color: var(--color-v2-border2);

    &::-ms-expand {
      display: none;
    }
  }

  select,
  &::after {
    grid-area: select;
  }

  :global(.empty) {
    color: var(--color-v2-text3);
  }

  &:not(select[multiple])::after {
    content: '';
    justify-self: end;
    width: 12px;
    height: 6px;
    background-color: var(--color-v2-icon3);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }

  select:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .error {
    color: var(--color-v2-error);
    font-size: 14px;
  }
}
