@import "@src/styles/vars.css";

.inputContainer {
  padding: 20px 0 10px;

  &:first-child {
    padding-top: 0;
  }
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.4675px;
  margin-bottom: 20px;
}

.emptyDobField {
  color: var(--color-v2-text3);
}

.clearButton {
  cursor: pointer;
  padding-left: 10px;
  color: var(--color-v2-text4);
  font-size: 16px;
  text-decoration: underline;
}

.footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-v2-background1);
}
