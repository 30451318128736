.TopMenu {
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: auto 150px;

  .LeftPartTopMenu {
    align-self: center;

    .MobileLogoContainer {
      height: 40px;
      width: 40px;
      margin-left: 60%;
      overflow: hidden;
      display: block;

      svg {
        width: 100%;
        height: 100%;
      }

      display: none;
    }
  }

  .RightPartTopMenu {
    display: grid;
    grid-template-columns: 60% auto auto;

    .TopNotificationsContainer {
      align-self: center;
      justify-self: start;
    }

    .TopUserProfileContainer {
      justify-self: end;
      align-self: center;
    }

    .MoreVertical {
      align-self: center;
      justify-self: start;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 12px;
  padding-left: 164px;
  background-color: #fff;
  margin-bottom: 50px;
}

.menu {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  align-items: center;
  cursor: pointer;
}

.menu_text {
  color: #363a3f;
  margin-top: 6px;
  font-size: 12px;
  letter-spacing: -0.3154px;
}

.happify_logo {
  margin-right: 19px;
  cursor: pointer;
}

.menu_icon {
  width: 40px;
  height: 27px;
}

.humana_logo {
  cursor: pointer;
}
