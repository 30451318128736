.customToastNotification {
  width: 100%;
  display: flex;

  &.isClickable {
    cursor: pointer;
  }

  .toastIcon {
    width: 60px;
    height: 60px;
    margin-right: 20px;

    svg {
      fill: var(--color-v2-global1);
    }
  }

  .toastContainer {
    .toastTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }

    .toastMessage {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      color: var(--color-v2-text3);
    }
  }
}
