@import '@src/styles/vars.css';

.BadgeContainer {
  position: relative;

  .numNotif {
    display: block;
    background: $red-notify-color;
    color: $white-color;
    border-radius: 19px;
    min-width: 19px;
    height: initial;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    padding: 1px 5px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 14px;
  }
}
