@import "@src/styles/vars.css";

.container {
  .row {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid var(--color-v2-border2);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: none;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4675px;
}

.footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-v2-background1);
}
