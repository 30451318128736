.iconContainer {
  display: flex;
  margin-left: 28px;
  align-items: center;
}

.icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 30px;
}
