.noResultsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 5px;
  flex-wrap: wrap;

  :global(.Button) {
    height: 34px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
