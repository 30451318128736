@import '../../../styles/vars.css';

.Loader {
  transform: translateY(200%);
}

.ListFolders {
  .ListFolderContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;

    @media (min-width: 320px) and (max-width: 1224px) {
      column-gap: 10px;
      row-gap: 10px;
    }

    .DropdownMenu {
      width: 100%;
      box-shadow: 0 5px 11px 0 rgba(50, 50, 50, 14%);

      .linkDropdownMenu {
        cursor: pointer;
        margin: 15px;
        display: grid;
        grid-template-columns: auto 18px;
        align-items: center;
        justify-content: space-between;

        .iconDropMenu {
          height: 16px;
          width: 16px;
        }
      }
    }

    .SystemFolder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 140px;
      background: rgba(31, 49, 60, 0.1);
      border-radius: 10px;
      max-width: 180px;
      min-width: 162px;
      flex-grow: 1;
      text-align: center;
      position: relative;
      padding: 24px 16px 20px;
      transition: all 1s 0s ease;

      &:hover {
        transform: scale(1.1);
      }

      a {
        text-decoration: none;
      }

      .iconContainer {
        height: 36px;
        width: 42px;
        position: relative;

        .iconFolder {
          position: absolute;
          left: 30%;
          bottom: 5px;
        }
      }

      .folderName {
        margin-top: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #212121;
        text-align: left;

        span {
          text-decoration: none;
          /* stylelint-disable-next-line value-no-vendor-prefix */
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .containerUserFolder {
      width: 100%;
      position: relative;

      .UserFolder {
        height: 160px;
        background: $white-color;
        box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
        border-radius: 10px;
        width: 100%;
        text-align: center;
        position: relative;
        transition: all 1s 0s ease;

        &:hover {
          transform: scale(1.1);
        }

        a {
          text-decoration: none;
          display: block;
          height: 100%;
          width: 100%;
          position: relative;

          .link {
            position: absolute;
            width: 100%;

            .iconContainer {
              height: 41px;
              width: 48px;
              margin: auto;
              margin-top: 40px;
              position: relative;

              .iconFolder {
                position: absolute;
                left: 30%;
                bottom: 5px;
              }
            }
          }
        }

        .folderName {
          margin-top: 16px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #212121;

          span {
            text-decoration: none;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 130px;
            display: block;
            margin: auto;
          }
        }
      }
    }

    a.UserFile {
      text-decoration: none;
      color: initial;
    }

    .UserFile {
      height: 160px;
      background: $white-color;
      box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
      border-radius: 10px;
      width: 100%;
      text-align: center;
      position: relative;

      .documentIconUrl {
        width: 48px;
        margin: auto;
        margin-top: 40px;
        position: relative;
        height: 48px;
        border-radius: 10px;
        background: $grey-blue-color;

        svg {
          height: 30px;
          margin: auto;
          margin-top: 9px;
          fill: $grey-color-dark;
        }
      }

      .documentIcon {
        width: 48px;
        margin: auto;
        margin-top: 40px;
        position: relative;
        height: 49px;
        padding-left: 7px;
      }

      .imagePreviewContainer {
        width: 48px;
        margin: auto;
        margin-top: 40px;
        position: relative;
        height: 48px;
        border-radius: 10px;
        background: $grey-blue-color;

        svg {
          height: 30px;
          margin: auto;
          margin-top: 9px;
          fill: $grey-color-dark;
        }

        .imagePreview {
          object-fit: cover;
          border-radius: 10px;
          width: 100%;
          height: 100%;
        }
      }

      .documentName {
        margin-top: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #212121;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 130px;
          display: block;
          margin: auto;
        }
      }

      .documentSize {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        margin-top: 5px;
        color: $grey-color-dark;
      }
    }
  }
}
