.Tab {
  display: flex;
  align-items: center;
  padding: 14px 35px 14px 26px;
  margin-right: 16px;
  border-radius: 10px 10px 0 0;
  border: 1px solid rgba(var(--current-accent-color-rgb), 0.6);
  border-bottom: 1px solid transparent;
  background: #efedeb;
  cursor: pointer;
  white-space: nowrap;
}

.IsDisabled {
  opacity: 0.2;
  cursor: auto;
}

.IsActive {
  background-color: #fefefe;
  border-color: var(--current-accent-color);
  border-bottom-color: transparent;

  svg {
    fill: var(--current-accent-color);
    stroke: var(--current-accent-color);
  }
}

.Title {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-left: 10px;
  letter-spacing: -0.31px;
}

@media (min-width: 320px) and (max-width: 465px) {
  .firstTab {
    margin-left: 5px;
  }

  .Tab {
    margin-right: 8px;
    padding: 12px 8px;
  }

  .Title {
    font-size: 12px;
    line-height: 14px;
    margin-left: 2px;
  }

  .ActiveLibraryIcon {
    width: 18px;
    height: 18px;
  }
}

@media (min-width: 465px) and (max-width: 1224px) {
  .Tab {
    padding: 14px 12px 14px 9px;
  }

  .Title {
    font-size: 14px;
    line-height: 16px;
    margin-left: 4px;
  }
}
