@import "@src/styles/vars.css";

.HeaderPractice {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-column-gap: 20px;
  margin-bottom: 37px;

  .imgContainer {
    .HeaderImg {
      height: 290px;
      position: relative;
      border-radius: 15px;
      overflow: hidden;

      img {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .OverlayImage {
        background:
          linear-gradient(
            0deg,
            rgba(0, 0, 0, 5%),
            rgba(0, 0, 0, 5%)
          );
      }
    }
  }

  .textContainer {
    margin: 30px;

    .category {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.4675px;
      margin-bottom: 18px;
      color: $grey-color-dark;
      padding-right: 10px;
      display: inline-block;
    }

    h2.title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      margin-bottom: 20px;
      color: $black-text-color;
    }

    .itemsPanel {
      display: flex;
    }

    .coachContainer {
      display: flex;
      flex-grow: 1;
      margin-bottom: 20px;

      &.user {
        align-items: center;
      }

      .coachAvatar {
        height: 40px;
        width: 40px;
        margin-right: 10px;
        border-radius: 20px;
        overflow: hidden;
      }

      .nameContainer {
        .coachName {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.4675px;
          color: $black-text-color;
        }

        .coachFollowingCount {
          display: flex;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.4675px;
          color: $grey-color-dark;

          .coachFollowingNumber {
            padding-right: 3px;
          }
        }
      }
    }

    .actionsContainer {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .actionButton {
        position: relative;
        height: 40px;
        border-radius: 20px;
        background: var(--color-v2-button-fill3);
        border: none;
        color: var(--color-v2-text3);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.46px;
        text-align: left;
        margin-right: 12px;
        padding: 10px 16px 10px 50px;
        cursor: pointer;

        .iconContainer {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: var(--color-v2-icon4);
          fill: var(--light-color-v2-icon2);
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .description {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: -0.24px;
      white-space: pre-line;
      color: $black-text-color;
    }
  }
}

.certificatesModalFooter {
  display: flex;
  justify-content: flex-end;
}
