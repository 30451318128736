.Widget {
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

.Tab_Container {
  display: flex;
  align-items: center;
  margin-bottom: -1px;
}

.Widget_Component_Container {
  background-color: #fefefe;
  height: calc(100% - 54px);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--current-accent-color);
  border-radius: 7px;
  border-top-left-radius: 0;
}

.Widget_Component {
  height: 100%;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (min-width: 320px) and (max-width: 799px) {
  .Widget {
    margin: 0;
  }
}
