@import '@src/styles/vars.css';

.Component {
  --card-width: 345px;
  --card-height: 210px;
}

.cardsContainersWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.cardsContainers {
  width: 450px;
  height: 240px;
  margin: auto;
  position: relative;

  :global(.TextField) {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;
  }

  :global(.TextField input) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.inputContainer {
  position: relative;
}

.errorInputBorder {
  border-radius: 6px;
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid var(--color-v2-error);
}

.numberErrorContainer {
  position: absolute;
  left: -2px;
  top: -24px;
  border-radius: 4px;
  background-color: var(--color-v2-error);
}

.errorText {
  display: block;
  font-size: 12px;
  line-height: 18px;
  padding: 0 7px;
  color: var(--light-color-v2-text2);
}

.cardFront {
  width: var(--card-width);
  height: var(--card-height);
  background: var(--color-background3);
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 12px 17px 0;

  .lines {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .bankLogoContainer {
    height: 50px;
    margin-bottom: 11px;
  }

  .expirationDateLabel {
    font-size: 11px;
    line-height: 14px;
    padding-bottom: 4px;
    padding-top: 14px;
    color: var(--light-color-v2-text2);
  }

  .expirationDateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .expirationDateInput {
    width: 50px;
  }

  .expirationDateSeparator {
    width: 10px;
  }

  .expDateErrorContainer {
    border-radius: 4px;
    margin-left: 5px;
    background-color: var(--color-v2-error);
  }

  .cardBrand {
    width: 60px;
    height: 40px;
    position: absolute;
    right: 0;
  }
}

.cardBack {
  width: var(--card-width);
  height: var(--card-height);
  background: var(--color-background7);
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  right: 0;

  .line {
    margin-top: 40px;
    height: 50px;
    width: 100%;
    background: var(--color-background8);
  }

  .securityCodeWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .securityCodeContainer {
    margin: 20px 20px 0 0;
    width: 60px;
  }

  .securityCodeLabel {
    padding-left: 2px;
    padding-top: 5px;
    font-size: 10px;
    line-height: 14px;

    &.cvvError {
      color: var(--color-v2-error);
    }
  }
}

.actionButtonsContainer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
