.TopMenu {
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: auto 150px;

  .LeftPartTopMenu {
    align-self: center;

    .MobileLogoContainer {
      height: 40px;
      width: 40px;
      margin-left: 60%;
      overflow: hidden;
      display: block;

      svg {
        width: 100%;
        height: 100%;
      }

      display: none;
    }
  }

  .RightPartTopMenu {
    display: grid;
    grid-template-columns: 60% auto auto;

    .TopNotificationsContainer {
      align-self: center;
      justify-self: start;
    }

    .TopUserProfileContainer {
      justify-self: end;
      align-self: center;
    }

    .MoreVertical {
      align-self: center;
      justify-self: start;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
}
