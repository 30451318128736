.Material {
  height: 100%;
  position: relative;

  .filesContainer {
    position: absolute;
    overflow: scroll;
    height: 100%;
    width: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
}
