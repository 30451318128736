@import "@src/styles/vars.css";

.YCToast {
  display: flex;
  align-items: center;

  .YcCoinContainer {
    padding-right: 20px;
  }

  .contentContainer {
    display: block;

    .text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4675px;
      color: $green-blue-color-text;
    }

    .description {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.4675px;
      padding-top: 4px;
    }
  }
}
