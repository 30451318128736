.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.conference {
  border-radius: 20px;
  overflow: hidden;
  flex: 0.9;
  display: flex;
  flex-direction: column;
  background: rgb(0, 0, 0);
  position: relative;

  @media (min-width: 320px) and (max-width: 950px) {
    flex: 1;
    border-radius: 0;
  }
}

.ios.conference {
  border-radius: 0px;
  overflow: hidden;
  flex: 0.9;
  display: flex;
  flex-direction: column;
  background: #4a8dda;
  position: relative;

  @media (min-width: 320px) and (max-width: 950px) {
    flex: 0.9;
  }
}

.popupWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  padding: 12px 50px 18px;
  background: #353535;
  border-radius: 17px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  text-align: center;
  color: #fefefe;

  @media (min-width: 480px) and (max-width: 650px) {
    padding: 12px 20px 18px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    padding: 12px 20px 18px;
    font-size: 16px;
    max-width: stretch;
    width: 80%;
  }
}

.popupTitle {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: inherit;
  line-height: 24px;
}

.popupDescription {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: inherit;
  line-height: 24px;
}

.popupButton {
  width: 140px;
  padding: 4px 20px 6px;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-radius: 8px;
  color: #353535;
  cursor: pointer;
}

.actionsBar {
  background: #1a1a1a;
  padding: 8px 40px 14px;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  @media (min-width: 320px) and (max-width: 950px) {
    padding: 8px 20px 14px;
  }
}

.actionButton {
  display: flex;
  flex-direction: column;
  width: 68px;
  height: 36px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 10px;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--light-color-v2-icon2);
  }

  &:hover {
    opacity: 0.8;
  }
}

.closeButton {
  align-self: end;
  width: 66px;
  height: 27px;
  border-radius: 8px;
  background-color: var(--color-v2-error);

  &:hover {
    opacity: 0.8;
  }
}

.topBar {
  background: #1a1a1a;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 16px;
  line-height: 22px;
  color: var(--light-color-v2-text2);
  font-weight: 600;
}

.status {
  font-size: 16px;
  line-height: 22px;
  color: var(--light-color-v2-text2);
}

.otSessionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.streamsContainer {
  position: relative;
  flex: 1;
  padding: 95px 0;
  display: flex;

  &.oneToOne {
    .coachesStreams,
    .clientsStreams {
      position: initial;
    }

    .stream {
      position: absolute;
      left: 10px;
      top: 10px;
      bottom: 10px;
      right: 10px;
    }

    .publisher {
      position: absolute;
      height: 150px;
      width: 220px;
      top: initial;
      left: initial;
      bottom: 30px;
      right: 30px;
      z-index: 2;
    }
  }

  @media (min-width: 320px) and (max-width: 950px) {
    flex-direction: column;
    padding: 0;
  }
}

.streams {
  position: relative;
  flex: 1;
  display: grid;

  /* grid-gap: 10px; */
  overflow-y: auto;

  /* border-radius: 10px; */

  &.moreThan2 {
    grid-template-columns: 1fr 1fr;
  }

  &.moreThan6 {
    grid-auto-flow: row;
    grid-auto-rows: 33%;

    /* grid-template-columns: unset; */
    grid-template-rows: unset;
  }
}

.coachesStreams {
  margin-right: 1px;
}

.clientsStreams {
  margin-left: 1px;
}

.stream {
  position: relative;
  background: #0009;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--light-color-v2-text2);
  text-align: center;

  & video {
    visibility: hidden;

    /* border-radius: 10px; fix for safari */
  }

  &.hasVideo {
    video {
      visibility: initial;
    }

    .streamNoAudioIcon {
      background-color: #0009;
      padding: 2px;
      border-radius: 50%;
    }
  }
}

.streamAvatarContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.streamAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-bottom: 15px;
}

.streamName {
  font-size: 16px;
  line-height: 22px;
  color: var(--light-color-v2-text2);
}

.streamNoAudioIcon {
  width: 24px;
  height: 24px;
  fill: var(--light-color-v2-icon2);
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.streamBadge {
  background: #0009;
  border-radius: 10px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 18px;
  color: var(--light-color-v2-text2);
  position: absolute;
  left: 10px;
  top: 10px;
}

:global(.OT_bar) {
  display: none !important;
}

:global(.OT_name) {
  display: none !important;
}

:global(.OT_archiving) {
  display: none !important;
}

:global(.OT_edge-bar-item) {
  display: none !important;
}

:global(.OT_video-poster) {
  display: none !important;
}

:global(.OT_video-loading) {
  display: none !important;
}

:global(.OT_audio-level-meter__audio-only-img::before) {
  display: none !important;
}

:global(.OT_audio-blocked-indicator) {
  display: none !important;
}

:global(.OT_video-blocked-indicator) {
  display: none !important;
}

:global(.OTPublisherContainer),
:global(.OTSubscriberContainer) {
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

:global(.OT_widget-container) {
  background-color: initial !important;
}

:global(.OT_video-disabled-indicator) {
  bottom: 8px !important;
  right: 5px !important;
}
