@import '@src/styles/vars.css';

.TopMenuPublic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;

  .buttonContainer {
    .button {
      cursor: pointer;
      background: $green-color;
      border-radius: 10px;
      padding: 14px 30px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: $white-color;
    }
  }
}
