@import '@src/styles/vars.css';

.MySquads {
  .ProgramList {
    h3 {
      font-size: 20px !important;
      margin-top: 40px;
    }

    .Program {
      display: grid;
      grid-template-columns: 180px 500px;
      margin: 30px 0;

      .imageContainer {
        .ProgramImage {
          position: relative;
          width: 180px;
          margin-bottom: 20px;

          & > a {
            width: 100%;
            display: block;
            min-height: 150px;
            height: 150px;
            border-radius: 15px;
            overflow: hidden;
            position: relative;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          .IconSettings {
            position: absolute;
            top: 14px;
            right: 14px;
            height: 20px;
            width: 20px;
          }

          .titleProgram {
            position: absolute;
            left: 17px;
            bottom: 14px;
            right: 11px;

            a {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 19px;
              color: $white-color;
              text-decoration: none;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .coursesContainer {
        margin-left: 20px;

        .CourseList {
          .seeAllBut {
            background: $grey-color;
            box-shadow: 0 4px 15px rgba(66, 56, 181, 0.05);
            border-radius: 10px;
            cursor: pointer;
            padding: 7px;
            text-align: center;
            transition: all 0.3s ease-in;

            &:hover {
              background: $white-color;
            }
          }

          .courseLink {
            text-decoration: none;
          }

          .Course {
            display: grid;
            background: $white-color;
            box-shadow: 0 4px 15px rgba(66, 56, 181, 0.05);
            border-radius: 10px;
            padding: 14px;
            margin-bottom: 11px;
            grid-template-columns: 35px 190px auto 130px 10px;
            grid-template-areas: 'iconCalendar date status size iconArrow';

            .Items {
              align-self: center;
              height: 18px;
            }

            .icon {
              height: auto;
              width: auto;
              margin: 0;
              padding: 0;
            }

            .IconCalendar {
              grid-area: iconCalendar;
            }

            .date {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.24px;
              color: $black-light-text-color;
              grid-area: date;
            }

            .status {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.24px;
              color: $grey-color-dark;
              grid-area: status;
            }

            .size {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              text-align: right;
              letter-spacing: -0.24px;
              color: $grey-color-dark;
              display: flex;
              justify-content: flex-end;
              margin-right: 25px;
              grid-area: size;

              &.sizeGreen {
                color: $green-color-text;

                svg {
                  path {
                    stroke: $green-color-text;
                  }
                }
              }

              .sizeText {
                display: inline-block;
                margin-left: 5px;
              }
            }

            .IconArrow {
              grid-area: iconArrow;
            }
          }
        }
      }
    }
  }
}
