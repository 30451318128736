@import "@src/styles/vars.css";

.QUserFlowStart {
  padding-top: 21px;

  .QUserFlowHeader {
    height: 38px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;

    .tittleContainer {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }

      .user {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $grey-color-name;
      }
    }

    .childrenContainer {
      .butProgress {
        background: $green-blue2-text;
        border: none;
        padding: 10px 20px 0 15px;
      }

      .textButContainer {
        color: $black-text-color;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 13px;
        display: flex;
      }

      .iconList {
        height: 24px;
        width: 30px;
        fill: $black-text-color;
      }
    }
  }
}

.butRow {
  display: flex;
  margin-top: 50px;

  .backBut {
    margin-right: 20px;
  }
}

.QUserFlowQuestionFactory {
  margin-top: 150px;

  .header {
    .sectionName {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: $green-grey-color;
    }

    .questionTitle {
      margin-top: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      min-height: 110px;

      &.questionTitleBig {
        font-size: 24px;
        line-height: 33px;
      }
    }
  }
}

.TextType {
  max-width: 480px;
  padding-bottom: 20px;
}

.QStandardInput {
  input {
    background: $mint-green-color !important;
    border-bottom: 1px solid $green-blue2-text !important;
  }

  label {
    color: $green-grey-dark-color !important;
    line-height: 0px !important;
  }
}

.optionContainer {
  .choiceOption {
    min-width: 280px;
    background: $blue2-grey-color;
    border: 2px solid $blue2-grey-color;
    border-radius: 15px;
    padding: 16px 16px 17px 48px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
    cursor: pointer;

    &:hover {
      border: 2px solid $green-blue-color-text;
    }

    &::before {
      content: "";
      position: absolute;
      left: 17px;
      top: 21px;
      height: 17px;
      width: 17px;
      border: 1px solid $grey-color-name;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }

    &.selected {
      border: 2px solid $green-blue-color-text;

      &::after {
        content: "";
        position: absolute;
        display: block;
        height: 7px;
        width: 7px;
        border: 1px solid $green-blue-color-text;
        background-color: $green-blue-color-text;
        top: 26px;
        left: 22px;
      }
    }

    &.singleChoice {
      &::before {
        border-radius: 50%;
      }

      &.selected {
        &::after {
          border-radius: 50%;
        }
      }
    }
  }
}

.dropdownMenu {
  background: $white-color;
  border-radius: 10px;

  .allSectionContainer {
    max-height: 70vh;
    overflow: auto;

    .sectionName {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 21px;
      color: $grey-color-name;
    }

    .qContainer {
      margin-bottom: 30px;
      padding-right: 15px;
      cursor: pointer;

      .qName {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 11px;

        &.error {
          color: red;
        }
      }

      .answers {
        ul {
          margin-left: 0;
          padding-left: 0;

          li {
            list-style-type: none;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            position: relative;
          }
        }
      }

      .multiC,
      .singleC {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        padding-left: 17px;
        color: $grey-color-name;

        ul {
          li {
            &::before {
              content: "";
              position: absolute;
              height: 10px;
              width: 10px;
              border: 1px solid $grey2-color-dark;
              left: -16px;
              top: 5px;
            }

            &::after {
              content: "";
              position: absolute;
              display: block;
              height: 4px;
              width: 4px;
              border: 1px solid $black-text-color;
              background-color: $black-text-color;
              left: -13px;
              top: 8px;
            }
          }
        }
      }

      .singleC {
        ul {
          li {
            &::before {
              border-radius: 50%;
            }

            &::after {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.doneContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "textA imgA";
  min-height: 55vh;
  align-items: end;

  .doneTextSection {
    grid-area: textA;

    .doneText {
      .text {
        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 49px;
        }

        span {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          color: $green-grey-color;
          display: block;
          padding-bottom: 135px;
          padding-top: 16px;
        }
      }
    }
  }

  .doneImgSection {
    grid-area: imgA;
  }
}

.QUserProgressBar {
  position: relative;
  margin-top: 28px;

  .mainLine {
    width: 100%;
    border-bottom: 1px solid $green-blue2-text;
  }

  .progress {
    border-bottom: 3px solid $green-blue-color-text;
    margin-top: -2px;
    position: relative;

    .labelProgress {
      position: absolute;
      background: $green-blue-color-text;
      box-shadow: 0 4px 25px rgba(68, 48, 131, 13%);
      border-radius: 11.5px;
      padding: 2px 8px;
      right: 0;
      top: -9px;
      min-width: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $white-color;

      &.left0 {
        right: initial;
      }
    }
  }
}
