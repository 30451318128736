@import '@src/styles/vars.css';

.card {
  width: 180px;
  height: 105px;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.clickable {
    cursor: pointer;
  }
}

.brandImage {
  width: 40px;
  height: 30px;
}

.cardNumber {
  font-size: 10px;
  line-height: 13px;
  color: var(--light-color-v2-text2);
  padding-bottom: 13px;
}

.cardDate {
  font-size: 10px;
  line-height: 13px;
  color: var(--light-color-v2-text2);
}
