.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  color: var(--color-v2-text1);
  margin: 30px 0 25px;
}

.loadingSpinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.4675px;
    color: var(--color-v2-text3);
  }
}

.tabs {
  display: flex;
  position: sticky;
  top: 0;
  padding-bottom: 25px;
  background-color: var(--color-v2-background1);
  z-index: 1;

  .tab {
    position: relative;
    flex: 1;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.4675px;
    color: var(--color-v2-text3);
    border-bottom: 2px solid var(--color-v2-border2);
    padding: 15px 0;
    transition: all 0.3s;

    &.last {
      border-bottom-color: rgba(0, 0, 0, 0%);
    }

    &.clickable {
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: calc(2px - 6px);
      width: 6px;
      height: 6px;
      background-color: var(--color-v2-border2);
      border-radius: 6px;
    }

    &.active {
      color: var(--color-v2-text4);

      &::after {
        background-color: var(--color-v2-text4);
      }
    }

    &.active.notCurrent {
      border-bottom-color: var(--color-v2-text4);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background-color: rgba(var(--color-v2-background1-rgb), 90%);
  padding: 20px 0 40px;

  .button {
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 64px;
    background-color: var(--color-v2-button-fill3);
    font-size: 18px;
    color: var(--light-color-v2-text3);
    margin-left: 20px;

    svg {
      position: relative;
      top: 1px;
      width: 28px;
      height: 28px;
      fill: var(--light-color-v2-text3);
    }

    &.main {
      background-color: var(--color-v2-button-fill1);
      color: var(--light-color-v2-text2);

      svg {
        fill: var(--light-color-v2-text2);
      }
    }
  }
}

/* Tabs common styles */

.dataRow {
  width: 50%;
  margin-bottom: 20px;
}

.header {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.4675px;
  color: var(--color-v2-text1);
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .sub {
    color: var(--color-v2-text3);
    font-weight: normal;
  }
}

.message {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--color-v2-background2);
  font-size: 15px;
  line-height: 20px;
  color: var(--color-v2-text3);
}

.cloneButton {
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    fill: var(--light-color-v2-text2);
  }
}
