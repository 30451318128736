@import '@src/styles/vars.css';

.ClientSubgoalsButton {
  background: $purple-color;
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 10px;
  cursor: pointer;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .contentContainer {
      display: flex;
      align-items: center;

      .icon {
        width: 22px;
        height: 22px;
        fill: $white-color;
      }

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 105%;
        letter-spacing: -0.41px;
        padding-left: 10px;
        color: $white-color;
      }
    }

    .button {
      background: $white-color;
      color: $purple-color;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      box-shadow:
        0 8px 10px -9px rgba(247, 247, 247, 0.15),
        0 4px 10px rgba(219, 219, 219, 0.1);
      border-radius: 12px;
      padding: 12px 29px;
      width: 95px;
    }
  }
}
