@import "@src/styles/vars.css";

.navbarVerticalLeftBack {
  &.show {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $opacity-black;
  }
}

.navbarVerticalLeft {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 190px;
  border: none;
  border-radius: 0;
  margin: 0;
  background: $white-color;
  color: $grey-dark-blue-color;
  display: grid;
  grid-template-rows: 120px 240px auto 90px;
  z-index: 2000;
  transition: transform 0.3s;
  overflow: hidden;
  transform: translateX(-100px);

  .BurgerButContainer {
    display: none;
  }

  .logoContainer {
    margin-top: 20px;
    margin-left: 25px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    transition: transform 0.3s;
    transform: translateX(100px);
  }

  .settingsButt {
    a {
      display: block;
      height: 100%;
      color: $grey-color-text;
      padding-top: 30px;
      transition: border-left 0.6s linear;
      border-left: 2px solid $white-color;
      text-decoration: none;

      svg {
        path {
          stroke: $grey-color-text;
        }
      }

      .fill {
        svg {
          fill: $grey-color-text;

          path {
            stroke: none;
            fill: $grey-color-text;
          }

          &.noFill {
            fill: none;

            path {
              stroke: $grey-color-text;
              fill: none;
            }
          }
        }
      }

      &:hover,
      &:active {
        border-left: 2px solid $green-color-text;
        color: $green-color-text;

        svg {
          path {
            stroke: $green-color-text;
          }

          &.noFill {
            fill: none;

            path {
              stroke: $green-color-text;
              fill: none;
            }
          }
        }
      }

      &.selected {
        border-left: 2px solid $green-color-text;
        color: $green-color-text;

        svg {
          path {
            stroke: $green-color-text;
          }
        }
      }
    }

    transition: transform 0.3s;
    transform: translateX(100px);
  }

  .icon {
    transition: transform 0.3s;
    transform: scale(0.7, 0.7);
  }

  .badge {
    transition: transform 0.3s;
    transform: scale(0.7, 0.7);
  }

  &:hover {
    transform: translateX(0);
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 43%);

    .logoContainer {
      transform: translateX(0);
    }

    .navbarNav {
      transform: translateX(0);
    }

    .settingsButt {
      transform: translateX(0);
    }

    .icon {
      transform: scale(1, 1);
    }

    .badge {
      transform: scale(1, 1);
    }
  }

  .navbarNav {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    transition: transform 0.3s;
    transform: translateX(100px);

    li {
      align-self: stretch;
      height: 45px;

      a {
        display: block;
        height: 100%;
        color: $grey-color-text;
        padding-top: 15px;
        transition: border-left 0.6s linear;
        border-left: 2px solid $white-color;
        text-decoration: none;

        svg {
          path {
            stroke: $grey-color-text;
          }
        }

        .fill {
          svg {
            fill: $grey-color-text;

            path {
              stroke: none;
              fill: $grey-color-text;
            }

            &.noFill {
              fill: none;

              path {
                stroke: $grey-color-text;
                fill: none;
              }
            }
          }
        }

        &:hover,
        &:active {
          border-left: 2px solid $green-color-text;
          color: $green-color-text;

          svg {
            path {
              stroke: $green-color-text;
            }
          }

          .fill {
            svg {
              fill: $green-color-text;

              path {
                stroke: none;
                fill: $green-color-text;
              }

              &.noFill {
                fill: none;

                path {
                  stroke: $green-color-text;
                  fill: none;
                }
              }
            }
          }
        }

        &.selected {
          border-left: 2px solid $green-color-text;
          color: $green-color-text;

          svg {
            path {
              stroke: $green-color-text;
            }
          }

          .fill {
            svg {
              fill: $green-color-text;

              path {
                stroke: none;
                fill: $green-color-text;
              }

              &.noFill {
                fill: none;

                path {
                  stroke: $green-color-text;
                  fill: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
