@import "../../../styles/vars.css";

.ErrorModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MainErrorText {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #000;
}

.DescriptionErrorText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.078px;
  color: #000;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 320px) and (max-width: 799px) {
  .DayAndTimeContainer {
    flex-direction: column;
  }

  .ScheduleLiveSessionButton__disabled,
  .ScheduleLiveSessionButton__active {
    width: calc(90vw - 30px) !important;
    padding: 14px 0 !important;
    margin-top: auto !important;
    font-size: 14px !important;
    background-color: var(--current-accent-color);
  }

  .TimeSectionsRowContainer .SelectedTimeSection:last-child {
    margin-right: 0;
  }

  .ScheduleTimeAndHours {
    font-size: 10px !important;
  }

  .DayPickerFooter {
    margin-top: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .DayContainer,
  .TimeContainer {
    padding-left: 0 !important;
  }

  .TimeContainer {
    flex-grow: 1 !important;
  }

  .DayContainer {
    padding-top: 0 !important;
  }

  .NoTimeSection,
  .TimeSection,
  .SelectedTimeSection {
    font-size: 8px !important;
    line-height: 10px !important;
    text-align: center;
    flex-grow: 0;
  }

  .ChooseDayText,
  .ChooseTimeText {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .RowContainer {
    flex-direction: column;
  }
}

@media screen and (min-height: 500px) and (max-height: 1023px) {
  .ScheduleLiveSessionButton__disabled,
  .ScheduleLiveSessionButton__active {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}

.LabelsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ChooseDayText {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.46px;
  color: #20313c;
}

.TimeSectionsRowContainer .SelectedTimeSection:last-child {
  margin-right: 0;
}

.RowContainer {
  display: flex;
  flex-grow: 1;
}

.DayPickerFooterRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DayContainer {
  padding-top: 10px;
  padding-left: 26px;
}

.ChooseTimeText {
  width: 140px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.46px;
  color: #20313c;
  margin-right: 60px;
}

.MarginBott {
  margin-bottom: 20px;
}

.NoTimeSlots_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.NoTimeSlots {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #727c82;
  letter-spacing: -0.46px;
}

.TimeContainer {
  padding-top: 10px;
  padding-left: 33px;
  display: flex;
  flex-direction: column;
}

.TimeHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TimezoneContainerText {
  font-style: normal;
  width: 120px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.46px;
  color: #727c82;

  @media (min-width: 320px) and (max-width: 799px) {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }
}

.DayAndTimeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TimeSectionsRowContainer {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 14px;
}

.TimeSectionsRowContainer:first-child {
  margin-top: 20px;
}

.TimeSectionsRowContainer:last-child {
  margin-bottom: 0;
}

.TimeSection {
  width: 70px;
  text-align: center;
  background: rgba(var(--current-accent-color-rgb), 20%);
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--current-accent-color);
  margin-right: 12px;
  padding: 12px 8px;
  cursor: pointer;
}

.TimeSection__tempCoach {
  background: rgba(41, 83, 132, 20%);
  color: rgb(41, 83, 132);
}

.SelectedTimeSection__tempCoach {
  background: rgb(41, 83, 132) !important;
  color: #fff;
}

.TimeSectionsRowContainer .TimeSection:last-child {
  margin-right: 0;
}

.SelectedTimeSection {
  color: #fff;
  background-color: var(--current-accent-color);
  margin-right: 12px;
  padding: 12px 8px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  width: 70px;
  text-align: center;
}

.ConfirmationImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConfirmationTextContainer {
  font-family: "GT Walsheim Pro", sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.078px;
  margin-top: 29px;
  margin-bottom: 15px;
}

.ConfirmationButton {
  border-radius: 10px;
  background-color: var(--current-accent-color);
  width: 100%;
  padding: 16px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.DayPickerFooter {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #919ba8;
  margin-top: 20px;
}

.AvailableDaysLabel {
  background: var(--current-accent-color);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.TemporaryCoachLabel {
  background: #295384;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 26px;
}

.NoTimeSection {
  width: 70px;
  text-align: center;
  color: #fff;
  background-color: #ececec;
  margin-right: 12px;
  padding: 12px 8px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.3;
}

.ScheduleLiveSessionButton__disabled {
  font-weight: 700;
  width: 340px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  padding: 16px 0;
  background-color: var(--current-accent-color);
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.3;
  margin-top: auto;
}

.ScheduleLiveSessionButton__active {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 340px;
  text-align: center;
  color: #fff;
  padding: 16px 0;
  background-color: var(--current-accent-color);
  border-radius: 10px;
  cursor: pointer;
  margin-top: auto;
}

.ScheduleTimeAndHours {
  font-weight: 700;
  font-size: 14px;
}

.Chats {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  flex-grow: 1;
  position: relative;

  @media (min-width: 320px) and (max-width: 799px) {
    padding: 10px 0 0;
  }
}

.ChatsListContainerMenu {
  margin: 20px 60px 20px 0;
  display: flex;
  align-items: center;

  .menuSearchContainer {
    flex-grow: 1;

    .searchContainer {
      padding: inherit;
      background: initial;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .menuButContainer {
    margin-left: 20px;

    .addChatBut {
      background: $purple-color;
      border-radius: 10px;
      padding: 11px;
      cursor: pointer;

      .IconAddChatContainer {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.CreateChannel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .createChannelSearchContainer {
    margin-bottom: 10px;

    .searchContainer {
      padding: inherit;
      background: initial;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .userListContainer {
    flex-grow: 1;
    max-height: calc(90vh - 73px - 66px - 66px);
    min-height: 100px;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .userListItemContainer {
      display: flex;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;

      .userCheckboxContainer {
        margin-right: 15px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid $blue-grey-light-color-1;
        flex-shrink: 0;

        &.selected {
          border: 1px solid $green-blue-color-text;
          background: $green-blue-color-text;
          position: relative;

          svg {
            fill: $white-color;
            position: absolute;
            left: -1px;
            top: 1px;
          }
        }
      }

      .userAvatarContainer {
        height: 50px;
        width: 50px;
        margin-right: 15px;
        flex-shrink: 0;
      }

      .userNameContainer {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.StoppedCourse {
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background: rgba(var(--current-accent-color-rgb), 0.15);
  font-family: "GT Walsheim Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.41px;
  color: #000;

  svg {
    margin-right: 10px;
    min-width: 25px;
  }

  @media (min-width: 320px) and (max-width: 700px) {
    font-size: 10px;
    padding: 12px 20px;
  }
}

.ChatsListContainer {
  display: flex;
  flex-direction: column;

  .toggleTypeListContainer {
    background: $grideperlevy;
    border-radius: 10px;
    margin-right: 60px;
    padding: 6px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-bottom: 40px;

    .toggleTypeListItem {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      padding: 7px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.4675px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: $grey-color-name;

      span {
        text-align: center;
      }

      .listItemNumMess {
        margin-left: 7px;
        background: $grey-color-dark;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        font-style: normal;
        font-weight: normal;
        font-size: 12.4667px;
        line-height: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.3006px;
        color: $white-color;
      }

      &:hover {
        background: $white-color;
      }

      &.activeListItem {
        background: $white-color;
        box-shadow:
          0 8px 10px -9px rgba(29, 19, 60, 15%),
          0 4px 10px rgba(63, 41, 132, 10%);
        border-radius: 8px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.4675px;
        color: $black-text-color;
      }
    }
  }
}

.Chat_Main_Container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chatListContainer {
  .DirectMessage,
  .ChatsList {
    padding-right: 22px;
    padding-left: 26px;
    height: 100%;

    @media (min-width: 320px) and (max-width: 799px) {
      padding: 0 10px;
    }

    .archiveTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.4675px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: $grey-color-name;
      padding: 30px 0 10px 20px;

      &.archiveTitleActive {
        color: $black-text-color;
      }
    }

    .archiveEmptyContainer {
      height: 50px;
      width: 50%;
    }
  }
}

.Chats_ListItem_More {
  color: #819aa9;
  font-size: 20px;
  cursor: pointer;
}

.Schedule_Call_Button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 13px 22px;
  margin-right: 32px;
  color: #fff;
  background-color: var(--current-accent-color);
  border-radius: 71px;
  cursor: pointer;

  @media screen and (min-width: 320px) and (max-width: 799px) {
    border-radius: 34px;
    margin-right: 0;
    font-size: 12px;
    line-height: 14px;
    padding: 15px 28px 13px 11px;
  }
}

.DisabledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 13px 23px 13px 14px;
  margin-right: 32px;
  color: #fff;
  background-color: var(--current-accent-color);
  border-radius: 71px;
  opacity: 0.5;
  cursor: default;
}

.DesktopScheduleButtonContainer {
  display: flex;

  div:first-child {
    margin-right: 3px;
  }
}

.MobileScheduleButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.Button_And_More {
  display: flex;
  align-items: center;
}

.Separator {
  height: 1px;
  background-color: #f5f5f5;
  margin-top: 20px;
}

.ChatsListItem {
  transition: all 0.3s ease;

  @media (min-width: 320px) and (max-width: 799px) {
    background: #f7f7f7;
    border-radius: 7px;
    border: 5px solid #f7f7f7;
  }

  .channelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image_and_name_Container {
      margin-right: 10px;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .channelAvatar {
        height: 60px;
        width: 60px;
        margin-right: 10px;

        @media (min-width: 320px) and (max-width: 799px) {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }

        .usersAvatar {
          display: block;
        }

        .allUsersContainer {
          bottom: -7px;
          right: 0;

          &.coachUsersContainer {
            right: initial;
            left: 7px;
          }
        }

        .usersAvatarContainer {
          display: flex;
          flex-direction: row-reverse;

          .userAvatar {
            height: 22px;
            width: 22px;
            border: 1px solid $grey-color-dark;
            border-radius: 50%;
            background:
              linear-gradient(
                0deg,
                $grey-color-dark,
                $grey-color-dark
              );
            color: $white-color;
            float: right;
            margin-left: -7px;
            flex-shrink: 0;

            &.coachAvatar {
              border: 1px solid $green-blue-color-text;
            }
          }

          .userAvatarNum {
            height: 22px;
            width: 22px;
            border: 1px solid $grey-color-dark;
            border-radius: 50%;
            background:
              linear-gradient(
                0deg,
                $grey-color-dark,
                $grey-color-dark
              );
            color: $white-color;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;
            margin-left: -7px;
            flex-shrink: 0;

            .userAvatarMore {
              margin-top: -7px;
            }
          }
        }
      }
    }

    .infoContainer {
      flex-grow: 1;

      .infoTopContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .infoTitleContainer {
          display: flex;
          align-items: center;
          flex-grow: 1;

          .iconGroupContainer {
            height: 16px;
            width: 16px;
            margin-right: 4px;
            flex-shrink: 0;

            svg {
              path {
                fill: $black-text-color;
              }
            }
          }

          .title {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.31px;
          }

          @media (min-width: 320px) and (max-width: 799px) {
            .title {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

        .infoDateContainer {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;
          color: $blue-grey-light-color-2;
        }
      }

      .infoButContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .infoDescriptionContainer {
          .courseName {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: $grey-color-name;
          }

          .lastMess {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.08px;
            color: $grey-color-name;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 250px;

            .danger {
              color: $alert-color;
            }
          }
        }

        .infoCountContainer {
          background: $green-blue-color-text;
          min-width: 22px;
          padding: 3px 2px;
          border-radius: 50%;
          font-style: normal;
          font-weight: normal;
          font-size: 12.4667px;
          line-height: 16px;
          text-align: center;
          letter-spacing: -0.3006px;
          color: $white-color;
        }
      }
    }
  }
}

.ChatMainContent {
  padding: 0 23px 0 27px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (min-width: 320px) and (max-width: 800px) {
    padding: 0 10px;
  }

  .ChatMainContentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;

    .ChatMainTextContainer {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

      .ChatMain {
        flex-grow: 1;
        width: 100%;
        position: relative;

        .ChatMainOverFlowContainer {
          position: absolute;
          overflow-y: scroll;
          height: 100%;
          width: 100%;
          padding-bottom: 29px;

          .ChatPlacePostsAtTheBottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            min-height: 100%;
          }

          .ChatMainDaySectionContainer {
            padding-top: 10px 0;

            .ChatMainDaySection {
              display: flex;
              align-items: center;
              height: 1px;
              background-color: #f5f5f5;
              margin-top: 15px;

              .ChatMainDayLine {
                border-top: 1px solid $blue-grey-light-color;
                flex-grow: 1;
              }

              .ChatMainDayTitle {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                padding: 0 10px;
                color: $blue-grey-light-color-3;
              }
            }
          }

          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }
  }
}

.PostsListItem {
  .systemPost {
    margin: 20px;
    background: $blue-grey-light-color-4;
    border-radius: 18px;
    padding: 20px;
    color: $purple-color;
    text-align: center;
  }

  .mainContainer {
    padding: 10px 0;
  }

  .container {
    display: flex;
    align-items: flex-end;

    .innerPostContainer {
      display: flex;
      flex-direction: column;
    }

    &.myContainer {
      flex-direction: row-reverse;
    }

    .rightPosition,
    .leftPosition {
      display: flex;
      flex-direction: column;
      max-width: 70%;
      background: $blue-grey-light-color-5;
      border-radius: 18px 18px 18px 9px;
      width: fit-content;
      padding: 15px;
      padding-bottom: 35px;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      position: relative;
      min-width: 100px;

      a {
        color: $black-text-color;
      }
    }

    .rightPosition {
      display: flex;
      max-width: 70%;
      border-radius: 18px 18px 9px;
      background: $green-blue-color-text;
      color: $white-color;

      a {
        color: $white-color;
      }
    }
  }

  .groupAvatarStub {
    width: 60px;
  }

  .avatarContainer {
    height: 40px;
    width: 40px;
    margin-right: 20px;
  }

  .postInProgress {
    display: block;
  }

  .postHighlighted {
    display: block;
  }

  .postTouchable {
    .timeStamp {
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      right: 15px;
      bottom: 8px;
    }
  }

  .pinnedUiPostTouchable {
    display: block;
  }

  .post {
    white-space: pre-line;
  }

  .adminBackground {
    display: block;
  }

  .myPost {
    display: block;
  }

  .pinnedUiPost {
    display: block;
  }

  .userName {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .myOrAdminTextColor {
    display: block;
  }

  .spice {
    height: 15px;
    width: 100%;
  }

  .PostAttachment {
    cursor: pointer;
    margin-bottom: 10px;

    .userAvatar {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      overflow: hidden;
    }

    .top {
      display: flex;
      align-items: center;

      .imageContainer {
        position: relative;
        height: 70px;
        width: 70px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;

        .image {
          position: absolute;
          height: 70px;
          width: 70px;
          top: 0;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .ico {
          height: 70px;
          width: 70px;
          background: $grey-green1-color;
          padding: 12px;

          svg {
            width: 100%;
            height: 100%;
            fill: $grey-color-dark;
          }
        }
      }

      .contentContainer {
        margin-left: 10px;
        flex-direction: column;
        justify-content: space-between;

        .name {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          max-width: 200px;
        }

        .description {
          color: var(--color-v2-text3);
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }

        .callToAction {
          border-radius: 20px;
          text-align: center;
          background-color: var(--color-v2-global2);
          color: var(--light-color-v2-text2);
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }
  }

  .imageAttachmentsContainer {
    margin-bottom: 5px;

    .imgContainer {
      display: flex;
      align-items: center;

      .image {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .imgInfoContainer {
        flex-grow: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .fileNameContainer {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          max-width: 200px;
        }

        .fileSizeContainer {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .userNameOnImageAttachmentGradient {
    display: block;

    .userName {
      display: block;
    }
  }
}

.ChatMainTopMenu {
  background: $grey-blue-light-color;
  border-radius: 53px 0 0;

  .topContent {
    padding: 24px 0 24px 60px;
    display: flex;
    align-items: center;

    .isMoreBackBut,
    .imageContainer {
      height: 50px;
      width: 50px;
      margin-right: 10px;
      cursor: pointer;
      flex-shrink: 0;
    }

    .isMoreBackBut {
      background: $blue-grey-light-color-6;
      border-radius: 50%;
      padding: 17px;
    }

    .textContainer {
      flex-grow: 1;
      cursor: pointer;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }

      .description {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $grey-color-name;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      .buttonItem {
        background: $white-color;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 14px;
        margin-left: 20px;
        cursor: pointer;
        flex-shrink: 0;
      }

      .buttonItemActive {
        background: none;
        border: 1px solid $blue-grey-light-color-7;

        svg {
          fill: $blue-grey-light-color-7;

          path {
            fill: $blue-grey-light-color-7;
          }
        }
      }

      .buttonCalendar {
        padding: 13px;
      }

      .buttonFolder {
        padding: 15px;
      }
    }
  }

  .buttContentLine {
    border-top: 1px solid $blue-grey-light-color-8;
    border-bottom: 1px solid $blue-grey-light-color-8;

    .buttContent {
      padding: 8px 0 6px 60px;

      .pinnedContainer {
        display: grid;
        grid-template-columns: 3px auto;
        gap: 10px;
        align-items: center;

        .pinnedContainerFirstLine {
          width: 3px;
          height: 100%;
          background: $purple-color;
          border-radius: 3px;
          transition: all 0.3s ease;
        }
      }

      .pinnedTopPageTitle {
        display: flex;
        align-items: center;
        cursor: pointer;

        .backInChat {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 50%;
          margin-right: 20px;
          background: $grey-light-color;
          flex-shrink: 0;
        }

        .pinnedTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

.areYouSureModalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
  width: 422.5px;
  background: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 15%);
  border-radius: 20px;

  @media (max-width: 480px) {
    width: 90vw;
    padding: 20px;
  }

  .areYouSureModalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    font-family: "GT Walsheim Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #1f313c;
  }

  .areYouSureModalButtonsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
    margin-top: 24px;

    .confirmButton,
    .cancelButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px 0;
      height: 40px;
      background: #79838a;
      border-radius: 45px;
      font-family: "GT Walsheim Pro", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.24px;
      color: #fff;
      cursor: pointer;
    }

    .confirmButton {
      background: #1f313c;
    }

    .cancelButton {
      background: #79838a;
    }
  }
}

.ChannelPinnedMessage {
  &.ChannelPinnedMessageClickable {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    align-items: center;

    .container {
      flex-grow: 1;

      .contentContainer {
        .headerText {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $purple-color;
        }

        .text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    .button {
      color: $white-color;
      background: $purple-color;
      border-radius: 8px;
      padding: 3px 10px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

.library {
  margin: 30px 0;
  padding-left: 60px;
}

.AttachmentsForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  .AttachmentsElement {
    height: 60px;
    width: 60px;
    position: relative;
    margin: 15px;

    .closeButtonContainer {
      width: 20px;
      height: 20px;
      position: absolute;
      right: -10px;
      top: -10px;

      .closeBut {
        padding: initial;
        margin: initial;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid $grey-color;
        cursor: pointer;
      }
    }

    .imageContainer {
      border-radius: 6px;
      border: 1px solid $grey-color;
      width: 100%;
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        a {
          width: 100%;
          height: 100%;
          display: block;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .ico {
        svg {
          fill: $grey-color-4;
        }
      }
    }
  }
}

.LoaderCentered {
  margin-top: 30%;
  transform: translateY(-50%);
}

.DataLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DataLoaded {
  height: fit-content;
}
