@import "@src/styles/vars.css";

.header,
.body {
  display: grid;
  grid-template-columns: 50% 50%;
}

.body {
  margin-top: 50px;
}

.coachContainer {
  display: flex;
  margin-bottom: 20px;

  .coachAvatar {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }

  .nameContainer {
    .coachName {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.4675px;
      color: $black-text-color;
    }

    .coachFollowingCount {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.4675px;
      color: $grey-color-dark;
    }
  }
}
