:root {
  --color-v2-background1: #f5f5f5;
  --color-v2-background1-rgb: 245, 245, 245;
  --color-v2-background2: #f1f5f6;
  --color-v2-background2-rgb: 241, 245, 246;
  --color-v2-background3: #c9f7ea;
  --color-v2-background3-rgb: 201, 247, 234;
  --color-v2-background4: #eee;
  --color-v2-background4-rgb: 238, 238, 238;
  --color-v2-background5: #dceafa;
  --color-v2-background5-rgb: 220, 234, 250;
  --color-v2-background6: #eee;
  --color-v2-background6-rgb: 238, 238, 238;
  --color-v2-background7: #f1f5f6;
  --color-v2-background7-rgb: 241, 245, 246;
  --color-v2-background8: #f24c00;
  --color-v2-background8-rgb: 242, 76, 0;
  --color-v2-background9: #e5ecf0;
  --color-v2-background9-rgb: 229, 236, 240;
  --color-v2-background10: #819aa9;
  --color-v2-background10-rgb: 129, 154, 169;
  --color-v2-background12: #e5ecf0;
  --color-v2-background12-rgb: 229, 236, 240;
  --color-v2-border1: #f1f5f6;
  --color-v2-border1-rgb: 241, 245, 246;
  --color-v2-border2: #ececec;
  --color-v2-border2-rgb: 236, 236, 236;
  --color-v2-border3: #f24c00;
  --color-v2-border3-rgb: 242, 76, 0;
  --color-v2-border4: #fa5353;
  --color-v2-border4-rgb: 250, 83, 83;
  --color-v2-text1: #000;
  --color-v2-text1-rgb: 0, 0, 0;
  --color-v2-text2: #fff;
  --color-v2-text2-rgb: 255, 255, 255;
  --color-v2-text3: #819aa9;
  --color-v2-text3-rgb: 129, 154, 169;
  --color-v2-text4: #f24c00;
  --color-v2-text4-rgb: 242, 76, 0;
  --color-v2-text5: #559dee;
  --color-v2-text5-rgb: 85, 157, 238;
  --color-v2-text6: #295384;
  --color-v2-text6-rgb: 41, 83, 132;
  --color-v2-icon1: #000;
  --color-v2-icon1-rgb: 0, 0, 0;
  --color-v2-icon2: #fff;
  --color-v2-icon2-rgb: 255, 255, 255;
  --color-v2-icon3: #819aa9;
  --color-v2-icon3-rgb: 129, 154, 169;
  --color-v2-icon4: #f24c00;
  --color-v2-icon4-rgb: 242, 76, 0;
  --color-v2-icon5: #559dee;
  --color-v2-icon5-rgb: 85, 157, 238;
  --color-v2-icon6: #ffa06a;
  --color-v2-icon6-rgb: 255, 160, 106;
  --color-v2-icon7: #886dde;
  --color-v2-icon7-rgb: 136, 109, 222;
  --color-v2-error: #fa5353;
  --color-v2-error-rgb: 250, 83, 83;
  --color-v2-global1: #f24c00;
  --color-v2-global1-rgb: 242, 76, 0;
  --color-v2-global2: #f24c00;
  --color-v2-global2-rgb: 242, 76, 0;
  --color-v2-global3: #000;
  --color-v2-global3-rgb: 0, 0, 0;
  --color-v2-global4: #fff;
  --color-v2-global4-rgb: 255, 255, 255;
  --color-v2-global5: #295384;
  --color-v2-global5-rgb: 41, 83, 132;
  --color-v2-button-fill1: #20313c;
  --color-v2-button-fill1-rgb: 32, 49, 60;
  --color-v2-button-fill2: #f24c00;
  --color-v2-button-fill2-rgb: 242, 76, 0;
  --color-v2-button-fill3: #f1f5f6;
  --color-v2-button-fill3-rgb: 241, 245, 246;
  --color-v2-button-fill4: #c9f7ea;
  --color-v2-button-fill4-rgb: 201, 247, 234;
  --color-v2-button-fill5: #dceafa;
  --color-v2-button-fill5-rgb: 220, 234, 250;
  --color-v2-button-fill6: #fcece4;
  --color-v2-button-fill6-rgb: 252, 236, 228;
  --color-v2-button-fill7: #eeeafb;
  --color-v2-button-fill7-rgb: 238, 234, 251;
  --color-v2-button-border1: #f24c00;
  --color-v2-button-border1-rgb: 242, 76, 0;
  --color-v2-button-border2: #559dee;
  --color-v2-button-border2-rgb: 85, 157, 238;
  --color-v2-button-border3: #819aa9;
  --color-v2-button-border3-rgb: 129, 154, 169;
  --color-v2-button-hover1: #2e4350;
  --color-v2-button-hover1-rgb: 46, 67, 80;
  --color-v2-button-hover2: #e94900;
  --color-v2-button-hover2-rgb: 233, 73, 0;
  --color-v2-button-pressed1: #1a2a34;
  --color-v2-button-pressed1-rgb: 26, 42, 52;
  --color-v2-button-pressed2: #e94b03;
  --color-v2-button-pressed2-rgb: 233, 75, 3;
  --color-v2-note-note1: #fff;
  --color-v2-note-note1-rgb: 255, 255, 255;
  --color-v2-note-note2: #c9f7ea;
  --color-v2-note-note2-rgb: 201, 247, 234;
  --color-v2-note-note3: #dceafa;
  --color-v2-note-note3-rgb: 220, 234, 250;
  --color-v2-note-note4: #fcece4;
  --color-v2-note-note4-rgb: 252, 236, 228;
  --color-v2-note-note5: #eeeafb;
  --color-v2-note-note5-rgb: 238, 234, 251;
  --color-v2-goal-goal1: #dfb998;
  --color-v2-goal-goal1-rgb: 223, 185, 152;
  --color-v2-goal-goal2: #f8c081;
  --color-v2-goal-goal2-rgb: 248, 192, 129;
  --color-v2-goal-goal3: #ffa06a;
  --color-v2-goal-goal3-rgb: 255, 160, 106;
  --color-v2-goal-goal4: #ed7d7d;
  --color-v2-goal-goal4-rgb: 237, 125, 125;
  --color-v2-goal-goal5: #72ddb7;
  --color-v2-goal-goal5-rgb: 114, 221, 183;
  --color-v2-goal-goal6: #9bb6b5;
  --color-v2-goal-goal6-rgb: 155, 182, 181;
  --color-v2-goal-goal7: #75b4b4;
  --color-v2-goal-goal7-rgb: 117, 180, 180;
  --color-v2-goal-goal8: #adc3d4;
  --color-v2-goal-goal8-rgb: 173, 195, 212;
  --color-v2-goal-goal9: #87b9e8;
  --color-v2-goal-goal9-rgb: 135, 185, 232;
  --color-v2-goal-goal10: #57bad3;
  --color-v2-goal-goal10-rgb: 87, 186, 211;
  --color-v2-goal-goal11: #659cb9;
  --color-v2-goal-goal11-rgb: 101, 156, 185;
  --color-v2-onboarding-onboarding1: #edc829;
  --color-v2-onboarding-onboarding1-rgb: 237, 200, 41;
  --color-v2-lost-colors-blue-shrimp: #4a8dda;
  --color-v2-lost-colors-blue-shrimp-rgb: 74, 141, 218;
  --color-v2-lost-colors-green-elephant: #fb6e2e;
  --color-v2-lost-colors-green-elephant-rgb: 251, 110, 46;
  --color-v2-lost-colors-error-light: #f2e5e6;
  --color-v2-lost-colors-error-light-rgb: 242, 229, 230;
  --color-v2-lost-colors-page-bg: #f6f9fa;
  --color-v2-lost-colors-page-bg-rgb: 246, 249, 250;
  --color-v2-lost-colors-sasha-gray: #c0ccd4;
  --color-v2-lost-colors-sasha-gray-rgb: 192, 204, 212;
  --color-v2-gray10: #f1f5f6;
  --color-v2-gray10-rgb: 241, 245, 246;
  --color-v2-blue10: #cee5ff;
  --color-v2-blue10-rgb: 206, 229, 255;
  --color-note-note1: #fff;
  --color-note-note1-rgb: 255, 255, 255;
  --color-note-note2: #c9f7ea;
  --color-note-note2-rgb: 201, 247, 234;
  --color-note-note3: #dceafa;
  --color-note-note3-rgb: 220, 234, 250;
  --color-note-note4: #fcece4;
  --color-note-note4-rgb: 252, 236, 228;
  --color-note-note5: #eeeafb;
  --color-note-note5-rgb: 238, 234, 251;
  --color-goal-goal1: #dfb998;
  --color-goal-goal1-rgb: 223, 185, 152;
  --color-goal-goal2: #f8c081;
  --color-goal-goal2-rgb: 248, 192, 129;
  --color-goal-goal3: #ffa06a;
  --color-goal-goal3-rgb: 255, 160, 106;
  --color-goal-goal4: #ed7d7d;
  --color-goal-goal4-rgb: 237, 125, 125;
  --color-goal-goal5: #72ddb7;
  --color-goal-goal5-rgb: 114, 221, 183;
  --color-goal-goal6: #9bb6b5;
  --color-goal-goal6-rgb: 155, 182, 181;
  --color-goal-goal7: #75b4b4;
  --color-goal-goal7-rgb: 117, 180, 180;
  --color-goal-goal8: #adc3d4;
  --color-goal-goal8-rgb: 173, 195, 212;
  --color-goal-goal9: #87b9e8;
  --color-goal-goal9-rgb: 135, 185, 232;
  --color-goal-goal10: #57bad3;
  --color-goal-goal10-rgb: 87, 186, 211;
  --color-goal-goal11: #659cb9;
  --color-goal-goal11-rgb: 101, 156, 185;
  --color-onboarding1: #edc829;
  --color-onboarding1-rgb: 237, 200, 41;
  --color-primary1: #559dee;
  --color-primary1-rgb: 85, 157, 238;
  --color-primary2: #4989d2;
  --color-primary2-rgb: 73, 137, 210;
  --color-primary3: #cee5ff;
  --color-primary3-rgb: 206, 229, 255;
  --color-primary4: #295384;
  --color-primary4-rgb: 41, 83, 132;
  --color-secondary1: #f24c00;
  --color-secondary1-rgb: 242, 76, 0;
  --color-secondary2: #33deae;
  --color-secondary2-rgb: 51, 222, 174;
  --color-secondary3: #b5efdf;
  --color-secondary3-rgb: 181, 239, 223;
  --color-secondary4: #d1efe6;
  --color-secondary4-rgb: 209, 239, 230;
  --color-secondary5: #dff4ee;
  --color-secondary5-rgb: 223, 244, 238;
  --color-secondary6: #d1efe6;
  --color-secondary6-rgb: 209, 239, 230;
  --color-secondary7: #dff4ee;
  --color-secondary7-rgb: 223, 244, 238;
  --color-background1: #fff;
  --color-background1-rgb: 255, 255, 255;
  --color-background2: #f1f5f6;
  --color-background2-rgb: 241, 245, 246;
  --color-background3: #5e7685;
  --color-background3-rgb: 94, 118, 133;
  --color-background4: #d2dce8;
  --color-background4-rgb: 210, 220, 232;
  --color-background5: #f1f5f6;
  --color-background5-rgb: 241, 245, 246;
  --color-background6: #f1f5f6;
  --color-background6-rgb: 241, 245, 246;
  --color-background7: #adc1cd;
  --color-background7-rgb: 173, 193, 205;
  --color-background8: #294251;
  --color-background8-rgb: 41, 66, 81;
  --color-background9: #5e7685;
  --color-background9-rgb: 94, 118, 133;
  --color-background10: #1c3c61;
  --color-background10-rgb: 28, 60, 97;
  --color-background11: #819aa9;
  --color-background11-rgb: 129, 154, 169;
  --color-background12: #e5ecf0;
  --color-background12-rgb: 229, 236, 240;
  --color-text1: #000;
  --color-text1-rgb: 0, 0, 0;
  --color-text2: #fff;
  --color-text2-rgb: 255, 255, 255;
  --color-text3: #819aa9;
  --color-text3-rgb: 129, 154, 169;
  --color-text4: #adc1cd;
  --color-text4-rgb: 173, 193, 205;
  --color-text5: #819aa9;
  --color-text5-rgb: 129, 154, 169;
  --color-text6: #c2d0d9;
  --color-text6-rgb: 194, 208, 217;
  --color-text7: #a4cdfa;
  --color-text7-rgb: 164, 205, 250;
  --color-text8: #7bb5f6;
  --color-text8-rgb: 123, 181, 246;
  --color-icon1: #000;
  --color-icon1-rgb: 0, 0, 0;
  --color-icon2: #fff;
  --color-icon2-rgb: 255, 255, 255;
  --color-icon3: #819aa9;
  --color-icon3-rgb: 129, 154, 169;
  --color-icon4: #bacade;
  --color-icon4-rgb: 186, 202, 222;
  --color-icon5: #819aa9;
  --color-icon5-rgb: 129, 154, 169;
  --color-icon6: #819aa9;
  --color-icon6-rgb: 129, 154, 169;
  --color-icon7: #c2d0d9;
  --color-icon7-rgb: 194, 208, 217;
  --color-icon8: #adc1cd;
  --color-icon8-rgb: 173, 193, 205;
  --color-icon9: #7bb5f6;
  --color-icon9-rgb: 123, 181, 246;
  --color-stroke1: #e5ecf0;
  --color-stroke1-rgb: 229, 236, 240;
  --color-stroke2: #c2d0d9;
  --color-stroke2-rgb: 194, 208, 217;
  --color-stroke3: #d2dce8;
  --color-stroke3-rgb: 210, 220, 232;
  --color-stroke4: #fff;
  --color-stroke4-rgb: 255, 255, 255;
  --color-stroke5: #adc1cd;
  --color-stroke5-rgb: 173, 193, 205;
  --color-info1: #559dee;
  --color-info1-rgb: 85, 157, 238;
  --color-warning1: #ffa06a;
  --color-warning1-rgb: 255, 160, 106;
  --color-danger1: #fa5353;
  --color-danger1-rgb: 250, 83, 83;
  --color-danger2: #fa5353;
  --color-danger2-rgb: 250, 83, 83;
  --font-regular: open-sans-regular;
  --font-semibold: open-sans-semibold;
  --font-bold: open-sans-bold;
  --light-color-v2-background1: #f5f5f5;
  --light-color-v2-background1-rgb: 245, 245, 245;
  --light-color-v2-background2: #f1f5f6;
  --light-color-v2-background2-rgb: 241, 245, 246;
  --light-color-v2-background3: #c9f7ea;
  --light-color-v2-background3-rgb: 201, 247, 234;
  --light-color-v2-background4: #eee;
  --light-color-v2-background4-rgb: 238, 238, 238;
  --light-color-v2-background5: #dceafa;
  --light-color-v2-background5-rgb: 220, 234, 250;
  --light-color-v2-background6: #eee;
  --light-color-v2-background6-rgb: 238, 238, 238;
  --light-color-v2-background7: #f1f5f6;
  --light-color-v2-background7-rgb: 241, 245, 246;
  --light-color-v2-background8: #f24c00;
  --light-color-v2-background8-rgb: 242, 76, 0;
  --light-color-v2-background9: #e5ecf0;
  --light-color-v2-background9-rgb: 229, 236, 240;
  --light-color-v2-background10: #819aa9;
  --light-color-v2-background10-rgb: 129, 154, 169;
  --light-color-v2-background12: #e5ecf0;
  --light-color-v2-background12-rgb: 229, 236, 240;
  --light-color-v2-border1: #f1f5f6;
  --light-color-v2-border1-rgb: 241, 245, 246;
  --light-color-v2-border2: #ececec;
  --light-color-v2-border2-rgb: 236, 236, 236;
  --light-color-v2-border3: #f24c00;
  --light-color-v2-border3-rgb: 242, 76, 0;
  --light-color-v2-border4: #fa5353;
  --light-color-v2-border4-rgb: 250, 83, 83;
  --light-color-v2-text1: #000;
  --light-color-v2-text1-rgb: 0, 0, 0;
  --light-color-v2-text2: #fff;
  --light-color-v2-text2-rgb: 255, 255, 255;
  --light-color-v2-text3: #819aa9;
  --light-color-v2-text3-rgb: 129, 154, 169;
  --light-color-v2-text4: #f24c00;
  --light-color-v2-text4-rgb: 242, 76, 0;
  --light-color-v2-text5: #559dee;
  --light-color-v2-text5-rgb: 85, 157, 238;
  --light-color-v2-text6: #295384;
  --light-color-v2-text6-rgb: 41, 83, 132;
  --light-color-v2-icon1: #000;
  --light-color-v2-icon1-rgb: 0, 0, 0;
  --light-color-v2-icon2: #fff;
  --light-color-v2-icon2-rgb: 255, 255, 255;
  --light-color-v2-icon3: #819aa9;
  --light-color-v2-icon3-rgb: 129, 154, 169;
  --light-color-v2-icon4: #f24c00;
  --light-color-v2-icon4-rgb: 242, 76, 0;
  --light-color-v2-icon5: #559dee;
  --light-color-v2-icon5-rgb: 85, 157, 238;
  --light-color-v2-icon6: #ffa06a;
  --light-color-v2-icon6-rgb: 255, 160, 106;
  --light-color-v2-icon7: #886dde;
  --light-color-v2-icon7-rgb: 136, 109, 222;
  --light-color-v2-error: #fa5353;
  --light-color-v2-error-rgb: 250, 83, 83;
  --light-color-v2-global1: #f24c00;
  --light-color-v2-global1-rgb: 242, 76, 0;
  --light-color-v2-global2: #f24c00;
  --light-color-v2-global2-rgb: 242, 76, 0;
  --light-color-v2-global3: #000;
  --light-color-v2-global3-rgb: 0, 0, 0;
  --light-color-v2-global4: #fff;
  --light-color-v2-global4-rgb: 255, 255, 255;
  --light-color-v2-global5: #295384;
  --light-color-v2-global5-rgb: 41, 83, 132;
  --light-color-v2-button-fill1: #20313c;
  --light-color-v2-button-fill1-rgb: 32, 49, 60;
  --light-color-v2-button-fill2: #f24c00;
  --light-color-v2-button-fill2-rgb: 242, 76, 0;
  --light-color-v2-button-fill3: #f1f5f6;
  --light-color-v2-button-fill3-rgb: 241, 245, 246;
  --light-color-v2-button-fill4: #c9f7ea;
  --light-color-v2-button-fill4-rgb: 201, 247, 234;
  --light-color-v2-button-fill5: #dceafa;
  --light-color-v2-button-fill5-rgb: 220, 234, 250;
  --light-color-v2-button-fill6: #fcece4;
  --light-color-v2-button-fill6-rgb: 252, 236, 228;
  --light-color-v2-button-fill7: #eeeafb;
  --light-color-v2-button-fill7-rgb: 238, 234, 251;
  --light-color-v2-button-border1: #f24c00;
  --light-color-v2-button-border1-rgb: 242, 76, 0;
  --light-color-v2-button-border2: #559dee;
  --light-color-v2-button-border2-rgb: 85, 157, 238;
  --light-color-v2-button-border3: #819aa9;
  --light-color-v2-button-border3-rgb: 129, 154, 169;
  --light-color-v2-button-hover1: #2e4350;
  --light-color-v2-button-hover1-rgb: 46, 67, 80;
  --light-color-v2-button-hover2: #e94900;
  --light-color-v2-button-hover2-rgb: 233, 73, 0;
  --light-color-v2-button-pressed1: #1a2a34;
  --light-color-v2-button-pressed1-rgb: 26, 42, 52;
  --light-color-v2-button-pressed2: #e94b03;
  --light-color-v2-button-pressed2-rgb: 233, 75, 3;
  --light-color-v2-note-note1: #fff;
  --light-color-v2-note-note1-rgb: 255, 255, 255;
  --light-color-v2-note-note2: #c9f7ea;
  --light-color-v2-note-note2-rgb: 201, 247, 234;
  --light-color-v2-note-note3: #dceafa;
  --light-color-v2-note-note3-rgb: 220, 234, 250;
  --light-color-v2-note-note4: #fcece4;
  --light-color-v2-note-note4-rgb: 252, 236, 228;
  --light-color-v2-note-note5: #eeeafb;
  --light-color-v2-note-note5-rgb: 238, 234, 251;
  --light-color-v2-goal-goal1: #dfb998;
  --light-color-v2-goal-goal1-rgb: 223, 185, 152;
  --light-color-v2-goal-goal2: #f8c081;
  --light-color-v2-goal-goal2-rgb: 248, 192, 129;
  --light-color-v2-goal-goal3: #ffa06a;
  --light-color-v2-goal-goal3-rgb: 255, 160, 106;
  --light-color-v2-goal-goal4: #ed7d7d;
  --light-color-v2-goal-goal4-rgb: 237, 125, 125;
  --light-color-v2-goal-goal5: #72ddb7;
  --light-color-v2-goal-goal5-rgb: 114, 221, 183;
  --light-color-v2-goal-goal6: #9bb6b5;
  --light-color-v2-goal-goal6-rgb: 155, 182, 181;
  --light-color-v2-goal-goal7: #75b4b4;
  --light-color-v2-goal-goal7-rgb: 117, 180, 180;
  --light-color-v2-goal-goal8: #adc3d4;
  --light-color-v2-goal-goal8-rgb: 173, 195, 212;
  --light-color-v2-goal-goal9: #87b9e8;
  --light-color-v2-goal-goal9-rgb: 135, 185, 232;
  --light-color-v2-goal-goal10: #57bad3;
  --light-color-v2-goal-goal10-rgb: 87, 186, 211;
  --light-color-v2-goal-goal11: #659cb9;
  --light-color-v2-goal-goal11-rgb: 101, 156, 185;
  --light-color-v2-onboarding-onboarding1: #edc829;
  --light-color-v2-onboarding-onboarding1-rgb: 237, 200, 41;
  --light-color-v2-lost-colors-blue-shrimp: #4a8dda;
  --light-color-v2-lost-colors-blue-shrimp-rgb: 74, 141, 218;
  --light-color-v2-lost-colors-green-elephant: #fb6e2e;
  --light-color-v2-lost-colors-green-elephant-rgb: 251, 110, 46;
  --light-color-v2-lost-colors-error-light: #f2e5e6;
  --light-color-v2-lost-colors-error-light-rgb: 242, 229, 230;
  --light-color-v2-lost-colors-page-bg: #f6f9fa;
  --light-color-v2-lost-colors-page-bg-rgb: 246, 249, 250;
  --light-color-v2-lost-colors-sasha-gray: #c0ccd4;
  --light-color-v2-lost-colors-sasha-gray-rgb: 192, 204, 212;
  --light-color-v2-gray10: #f1f5f6;
  --light-color-v2-gray10-rgb: 241, 245, 246;
  --light-color-v2-blue10: #cee5ff;
  --light-color-v2-blue10-rgb: 206, 229, 255;
  --light-color-note-note1: #fff;
  --light-color-note-note1-rgb: 255, 255, 255;
  --light-color-note-note2: #c9f7ea;
  --light-color-note-note2-rgb: 201, 247, 234;
  --light-color-note-note3: #dceafa;
  --light-color-note-note3-rgb: 220, 234, 250;
  --light-color-note-note4: #fcece4;
  --light-color-note-note4-rgb: 252, 236, 228;
  --light-color-note-note5: #eeeafb;
  --light-color-note-note5-rgb: 238, 234, 251;
  --light-color-goal-goal1: #dfb998;
  --light-color-goal-goal1-rgb: 223, 185, 152;
  --light-color-goal-goal2: #f8c081;
  --light-color-goal-goal2-rgb: 248, 192, 129;
  --light-color-goal-goal3: #ffa06a;
  --light-color-goal-goal3-rgb: 255, 160, 106;
  --light-color-goal-goal4: #ed7d7d;
  --light-color-goal-goal4-rgb: 237, 125, 125;
  --light-color-goal-goal5: #72ddb7;
  --light-color-goal-goal5-rgb: 114, 221, 183;
  --light-color-goal-goal6: #9bb6b5;
  --light-color-goal-goal6-rgb: 155, 182, 181;
  --light-color-goal-goal7: #75b4b4;
  --light-color-goal-goal7-rgb: 117, 180, 180;
  --light-color-goal-goal8: #adc3d4;
  --light-color-goal-goal8-rgb: 173, 195, 212;
  --light-color-goal-goal9: #87b9e8;
  --light-color-goal-goal9-rgb: 135, 185, 232;
  --light-color-goal-goal10: #57bad3;
  --light-color-goal-goal10-rgb: 87, 186, 211;
  --light-color-goal-goal11: #659cb9;
  --light-color-goal-goal11-rgb: 101, 156, 185;
  --light-color-onboarding1: #edc829;
  --light-color-onboarding1-rgb: 237, 200, 41;
  --light-color-primary1: #559dee;
  --light-color-primary1-rgb: 85, 157, 238;
  --light-color-primary2: #4989d2;
  --light-color-primary2-rgb: 73, 137, 210;
  --light-color-primary3: #cee5ff;
  --light-color-primary3-rgb: 206, 229, 255;
  --light-color-primary4: #295384;
  --light-color-primary4-rgb: 41, 83, 132;
  --light-color-secondary1: #f24c00;
  --light-color-secondary1-rgb: 242, 76, 0;
  --light-color-secondary2: #33deae;
  --light-color-secondary2-rgb: 51, 222, 174;
  --light-color-secondary3: #b5efdf;
  --light-color-secondary3-rgb: 181, 239, 223;
  --light-color-secondary4: #d1efe6;
  --light-color-secondary4-rgb: 209, 239, 230;
  --light-color-secondary5: #dff4ee;
  --light-color-secondary5-rgb: 223, 244, 238;
  --light-color-secondary6: #d1efe6;
  --light-color-secondary6-rgb: 209, 239, 230;
  --light-color-secondary7: #dff4ee;
  --light-color-secondary7-rgb: 223, 244, 238;
  --light-color-background1: #fff;
  --light-color-background1-rgb: 255, 255, 255;
  --light-color-background2: #f1f5f6;
  --light-color-background2-rgb: 241, 245, 246;
  --light-color-background3: #5e7685;
  --light-color-background3-rgb: 94, 118, 133;
  --light-color-background4: #d2dce8;
  --light-color-background4-rgb: 210, 220, 232;
  --light-color-background5: #f1f5f6;
  --light-color-background5-rgb: 241, 245, 246;
  --light-color-background6: #f1f5f6;
  --light-color-background6-rgb: 241, 245, 246;
  --light-color-background7: #adc1cd;
  --light-color-background7-rgb: 173, 193, 205;
  --light-color-background8: #294251;
  --light-color-background8-rgb: 41, 66, 81;
  --light-color-background9: #5e7685;
  --light-color-background9-rgb: 94, 118, 133;
  --light-color-background10: #1c3c61;
  --light-color-background10-rgb: 28, 60, 97;
  --light-color-background11: #819aa9;
  --light-color-background11-rgb: 129, 154, 169;
  --light-color-background12: #e5ecf0;
  --light-color-background12-rgb: 229, 236, 240;
  --light-color-text1: #000;
  --light-color-text1-rgb: 0, 0, 0;
  --light-color-text2: #fff;
  --light-color-text2-rgb: 255, 255, 255;
  --light-color-text3: #819aa9;
  --light-color-text3-rgb: 129, 154, 169;
  --light-color-text4: #adc1cd;
  --light-color-text4-rgb: 173, 193, 205;
  --light-color-text5: #819aa9;
  --light-color-text5-rgb: 129, 154, 169;
  --light-color-text6: #c2d0d9;
  --light-color-text6-rgb: 194, 208, 217;
  --light-color-text7: #a4cdfa;
  --light-color-text7-rgb: 164, 205, 250;
  --light-color-text8: #7bb5f6;
  --light-color-text8-rgb: 123, 181, 246;
  --light-color-icon1: #000;
  --light-color-icon1-rgb: 0, 0, 0;
  --light-color-icon2: #fff;
  --light-color-icon2-rgb: 255, 255, 255;
  --light-color-icon3: #819aa9;
  --light-color-icon3-rgb: 129, 154, 169;
  --light-color-icon4: #bacade;
  --light-color-icon4-rgb: 186, 202, 222;
  --light-color-icon5: #819aa9;
  --light-color-icon5-rgb: 129, 154, 169;
  --light-color-icon6: #819aa9;
  --light-color-icon6-rgb: 129, 154, 169;
  --light-color-icon7: #c2d0d9;
  --light-color-icon7-rgb: 194, 208, 217;
  --light-color-icon8: #adc1cd;
  --light-color-icon8-rgb: 173, 193, 205;
  --light-color-icon9: #7bb5f6;
  --light-color-icon9-rgb: 123, 181, 246;
  --light-color-stroke1: #e5ecf0;
  --light-color-stroke1-rgb: 229, 236, 240;
  --light-color-stroke2: #c2d0d9;
  --light-color-stroke2-rgb: 194, 208, 217;
  --light-color-stroke3: #d2dce8;
  --light-color-stroke3-rgb: 210, 220, 232;
  --light-color-stroke4: #fff;
  --light-color-stroke4-rgb: 255, 255, 255;
  --light-color-stroke5: #adc1cd;
  --light-color-stroke5-rgb: 173, 193, 205;
  --light-color-info1: #559dee;
  --light-color-info1-rgb: 85, 157, 238;
  --light-color-warning1: #ffa06a;
  --light-color-warning1-rgb: 255, 160, 106;
  --light-color-danger1: #fa5353;
  --light-color-danger1-rgb: 250, 83, 83;
  --light-color-danger2: #fa5353;
  --light-color-danger2-rgb: 250, 83, 83;
  --light-font-regular: open-sans-regular;
  --light-font-semibold: open-sans-semibold;
  --light-font-bold: open-sans-bold;
  --dark-color-v2-background1: #14171b;
  --dark-color-v2-background1-rgb: 20, 23, 27;
  --dark-color-v2-background2: #202326;
  --dark-color-v2-background2-rgb: 32, 35, 38;
  --dark-color-v2-background3: #162f2c;
  --dark-color-v2-background3-rgb: 22, 47, 44;
  --dark-color-v2-background4: #173c35;
  --dark-color-v2-background4-rgb: 23, 60, 53;
  --dark-color-v2-background5: #1c2837;
  --dark-color-v2-background5-rgb: 28, 40, 55;
  --dark-color-v2-background6: #203145;
  --dark-color-v2-background6-rgb: 32, 49, 69;
  --dark-color-v2-background7: #202326;
  --dark-color-v2-background7-rgb: 32, 35, 38;
  --dark-color-v2-background8: #1fb088;
  --dark-color-v2-background8-rgb: 31, 176, 136;
  --dark-color-v2-background9: #33383e;
  --dark-color-v2-background9-rgb: 51, 56, 62;
  --dark-color-v2-background10: #819aa9;
  --dark-color-v2-background10-rgb: 129, 154, 169;
  --dark-color-v2-background12: #e5ecf0;
  --dark-color-v2-background12-rgb: 229, 236, 240;
  --dark-color-v2-border1: #202326;
  --dark-color-v2-border1-rgb: 32, 35, 38;
  --dark-color-v2-border2: #33383e;
  --dark-color-v2-border2-rgb: 51, 56, 62;
  --dark-color-v2-border3: #1fb088;
  --dark-color-v2-border3-rgb: 31, 176, 136;
  --dark-color-v2-border4: #d24646;
  --dark-color-v2-border4-rgb: 210, 70, 70;
  --dark-color-v2-text1: #f5f9fa;
  --dark-color-v2-text1-rgb: 245, 249, 250;
  --dark-color-v2-text2: #000;
  --dark-color-v2-text2-rgb: 0, 0, 0;
  --dark-color-v2-text3: #7a8086;
  --dark-color-v2-text3-rgb: 122, 128, 134;
  --dark-color-v2-text4: #1fb088;
  --dark-color-v2-text4-rgb: 31, 176, 136;
  --dark-color-v2-text5: #4784c8;
  --dark-color-v2-text5-rgb: 71, 132, 200;
  --dark-color-v2-text6: #295384;
  --dark-color-v2-text6-rgb: 41, 83, 132;
  --dark-color-v2-icon1: #f5f9fa;
  --dark-color-v2-icon1-rgb: 245, 249, 250;
  --dark-color-v2-icon2: #000;
  --dark-color-v2-icon2-rgb: 0, 0, 0;
  --dark-color-v2-icon3: #7a8086;
  --dark-color-v2-icon3-rgb: 122, 128, 134;
  --dark-color-v2-icon4: #1fb088;
  --dark-color-v2-icon4-rgb: 31, 176, 136;
  --dark-color-v2-icon5: #4784c8;
  --dark-color-v2-icon5-rgb: 71, 132, 200;
  --dark-color-v2-icon6: #cc8055;
  --dark-color-v2-icon6-rgb: 204, 128, 85;
  --dark-color-v2-icon7: #7861c4;
  --dark-color-v2-icon7-rgb: 120, 97, 196;
  --dark-color-v2-error: #d24646;
  --dark-color-v2-error-rgb: 210, 70, 70;
  --dark-color-v2-global1: #1fb088;
  --dark-color-v2-global1-rgb: 31, 176, 136;
  --dark-color-v2-global2: #4784c8;
  --dark-color-v2-global2-rgb: 71, 132, 200;
  --dark-color-v2-global3: #fff;
  --dark-color-v2-global3-rgb: 255, 255, 255;
  --dark-color-v2-global4: #000;
  --dark-color-v2-global4-rgb: 0, 0, 0;
  --dark-color-v2-global5: #295384;
  --dark-color-v2-global5-rgb: 41, 83, 132;
  --dark-color-v2-button-fill1: #1fb088;
  --dark-color-v2-button-fill1-rgb: 31, 176, 136;
  --dark-color-v2-button-fill2: #4784c8;
  --dark-color-v2-button-fill2-rgb: 71, 132, 200;
  --dark-color-v2-button-fill3: #202326;
  --dark-color-v2-button-fill3-rgb: 32, 35, 38;
  --dark-color-v2-button-fill4: #162f2c;
  --dark-color-v2-button-fill4-rgb: 22, 47, 44;
  --dark-color-v2-button-fill5: #1c2837;
  --dark-color-v2-button-fill5-rgb: 28, 40, 55;
  --dark-color-v2-button-fill6: #322d28;
  --dark-color-v2-button-fill6-rgb: 50, 45, 40;
  --dark-color-v2-button-fill7: #27253a;
  --dark-color-v2-button-fill7-rgb: 39, 37, 58;
  --dark-color-v2-button-border1: #1fb088;
  --dark-color-v2-button-border1-rgb: 31, 176, 136;
  --dark-color-v2-button-border2: #4989d2;
  --dark-color-v2-button-border2-rgb: 73, 137, 210;
  --dark-color-v2-button-border3: #7a8086;
  --dark-color-v2-button-border3-rgb: 122, 128, 134;
  --dark-color-v2-button-hover1: #2e4350;
  --dark-color-v2-button-hover1-rgb: 46, 67, 80;
  --dark-color-v2-button-hover2: #e94900;
  --dark-color-v2-button-hover2-rgb: 233, 73, 0;
  --dark-color-v2-button-pressed1: #1a2a34;
  --dark-color-v2-button-pressed1-rgb: 26, 42, 52;
  --dark-color-v2-button-pressed2: #e94b03;
  --dark-color-v2-button-pressed2-rgb: 233, 75, 3;
  --dark-color-v2-note-note1: #fff;
  --dark-color-v2-note-note1-rgb: 255, 255, 255;
  --dark-color-v2-note-note2: #c9f7ea;
  --dark-color-v2-note-note2-rgb: 201, 247, 234;
  --dark-color-v2-note-note3: #dceafa;
  --dark-color-v2-note-note3-rgb: 220, 234, 250;
  --dark-color-v2-note-note4: #fcece4;
  --dark-color-v2-note-note4-rgb: 252, 236, 228;
  --dark-color-v2-note-note5: #eeeafb;
  --dark-color-v2-note-note5-rgb: 238, 234, 251;
  --dark-color-v2-goal-goal1: #dfb998;
  --dark-color-v2-goal-goal1-rgb: 223, 185, 152;
  --dark-color-v2-goal-goal2: #f8c081;
  --dark-color-v2-goal-goal2-rgb: 248, 192, 129;
  --dark-color-v2-goal-goal3: #ffa06a;
  --dark-color-v2-goal-goal3-rgb: 255, 160, 106;
  --dark-color-v2-goal-goal4: #ed7d7d;
  --dark-color-v2-goal-goal4-rgb: 237, 125, 125;
  --dark-color-v2-goal-goal5: #72ddb7;
  --dark-color-v2-goal-goal5-rgb: 114, 221, 183;
  --dark-color-v2-goal-goal6: #9bb6b5;
  --dark-color-v2-goal-goal6-rgb: 155, 182, 181;
  --dark-color-v2-goal-goal7: #75b4b4;
  --dark-color-v2-goal-goal7-rgb: 117, 180, 180;
  --dark-color-v2-goal-goal8: #adc3d4;
  --dark-color-v2-goal-goal8-rgb: 173, 195, 212;
  --dark-color-v2-goal-goal9: #87b9e8;
  --dark-color-v2-goal-goal9-rgb: 135, 185, 232;
  --dark-color-v2-goal-goal10: #57bad3;
  --dark-color-v2-goal-goal10-rgb: 87, 186, 211;
  --dark-color-v2-goal-goal11: #659cb9;
  --dark-color-v2-goal-goal11-rgb: 101, 156, 185;
  --dark-color-v2-onboarding-onboarding1: #edc829;
  --dark-color-v2-onboarding-onboarding1-rgb: 237, 200, 41;
  --dark-color-v2-lost-colors-blue-shrimp: #4a8dda;
  --dark-color-v2-lost-colors-blue-shrimp-rgb: 74, 141, 218;
  --dark-color-v2-lost-colors-green-elephant: #fb6e2e;
  --dark-color-v2-lost-colors-green-elephant-rgb: 251, 110, 46;
  --dark-color-v2-lost-colors-error-light: #f2e5e6;
  --dark-color-v2-lost-colors-error-light-rgb: 242, 229, 230;
  --dark-color-v2-lost-colors-page-bg: #f6f9fa;
  --dark-color-v2-lost-colors-page-bg-rgb: 246, 249, 250;
  --dark-color-v2-lost-colors-sasha-gray: #c0ccd4;
  --dark-color-v2-lost-colors-sasha-gray-rgb: 192, 204, 212;
  --dark-color-v2-gray10: #f1f5f6;
  --dark-color-v2-gray10-rgb: 241, 245, 246;
  --dark-color-v2-blue10: #cee5ff;
  --dark-color-v2-blue10-rgb: 206, 229, 255;
  --dark-color-note-note1: #202326;
  --dark-color-note-note1-rgb: 32, 35, 38;
  --dark-color-note-note2: #162f2c;
  --dark-color-note-note2-rgb: 22, 47, 44;
  --dark-color-note-note3: #1c2837;
  --dark-color-note-note3-rgb: 28, 40, 55;
  --dark-color-note-note4: #322d28;
  --dark-color-note-note4-rgb: 50, 45, 40;
  --dark-color-note-note5: #27253a;
  --dark-color-note-note5-rgb: 39, 37, 58;
  --dark-color-goal-goal1: #bb9b80;
  --dark-color-goal-goal1-rgb: 187, 155, 128;
  --dark-color-goal-goal2: #d0a16c;
  --dark-color-goal-goal2-rgb: 208, 161, 108;
  --dark-color-goal-goal3: #d68659;
  --dark-color-goal-goal3-rgb: 214, 134, 89;
  --dark-color-goal-goal4: #c76969;
  --dark-color-goal-goal4-rgb: 199, 105, 105;
  --dark-color-goal-goal5: #60ba9a;
  --dark-color-goal-goal5-rgb: 96, 186, 154;
  --dark-color-goal-goal6: #829998;
  --dark-color-goal-goal6-rgb: 130, 153, 152;
  --dark-color-goal-goal7: #629797;
  --dark-color-goal-goal7-rgb: 98, 151, 151;
  --dark-color-goal-goal8: #91a4b2;
  --dark-color-goal-goal8-rgb: 145, 164, 178;
  --dark-color-goal-goal9: #719bc3;
  --dark-color-goal-goal9-rgb: 113, 155, 195;
  --dark-color-goal-goal10: #499cb1;
  --dark-color-goal-goal10-rgb: 73, 156, 177;
  --dark-color-goal-goal11: #55839b;
  --dark-color-goal-goal11-rgb: 85, 131, 155;
  --dark-color-onboarding1: #202326;
  --dark-color-onboarding1-rgb: 32, 35, 38;
  --dark-color-primary1: #559dee;
  --dark-color-primary1-rgb: 85, 157, 238;
  --dark-color-primary2: #4989d2;
  --dark-color-primary2-rgb: 73, 137, 210;
  --dark-color-primary3: #1f4571;
  --dark-color-primary3-rgb: 31, 69, 113;
  --dark-color-primary4: #295384;
  --dark-color-primary4-rgb: 41, 83, 132;
  --dark-color-secondary1: #f24c00;
  --dark-color-secondary1-rgb: 242, 76, 0;
  --dark-color-secondary2: #33deae;
  --dark-color-secondary2-rgb: 51, 222, 174;
  --dark-color-secondary3: #b5efdf;
  --dark-color-secondary3-rgb: 181, 239, 223;
  --dark-color-secondary4: #d1efe6;
  --dark-color-secondary4-rgb: 209, 239, 230;
  --dark-color-secondary5: #10191e;
  --dark-color-secondary5-rgb: 16, 25, 30;
  --dark-color-secondary6: #324440;
  --dark-color-secondary6-rgb: 50, 68, 64;
  --dark-color-secondary7: #324440;
  --dark-color-secondary7-rgb: 50, 68, 64;
  --dark-color-background1: #10191e;
  --dark-color-background1-rgb: 16, 25, 30;
  --dark-color-background2: #19252d;
  --dark-color-background2-rgb: 25, 37, 45;
  --dark-color-background3: #5e7685;
  --dark-color-background3-rgb: 94, 118, 133;
  --dark-color-background4: #3e5867;
  --dark-color-background4-rgb: 62, 88, 103;
  --dark-color-background5: #19252d;
  --dark-color-background5-rgb: 25, 37, 45;
  --dark-color-background6: #19252d;
  --dark-color-background6-rgb: 25, 37, 45;
  --dark-color-background7: #3e5867;
  --dark-color-background7-rgb: 62, 88, 103;
  --dark-color-background8: #294251;
  --dark-color-background8-rgb: 41, 66, 81;
  --dark-color-background9: #5e7685;
  --dark-color-background9-rgb: 94, 118, 133;
  --dark-color-background10: #1c3c61;
  --dark-color-background10-rgb: 28, 60, 97;
  --dark-color-background11: #819aa9;
  --dark-color-background11-rgb: 129, 154, 169;
  --dark-color-background12: #19252d;
  --dark-color-background12-rgb: 25, 37, 45;
  --dark-color-text1: #fff;
  --dark-color-text1-rgb: 255, 255, 255;
  --dark-color-text2: #000;
  --dark-color-text2-rgb: 0, 0, 0;
  --dark-color-text3: #819aa9;
  --dark-color-text3-rgb: 129, 154, 169;
  --dark-color-text4: #3e5867;
  --dark-color-text4-rgb: 62, 88, 103;
  --dark-color-text5: #819aa9;
  --dark-color-text5-rgb: 129, 154, 169;
  --dark-color-text6: #3e5867;
  --dark-color-text6-rgb: 62, 88, 103;
  --dark-color-text7: #a4cdfa;
  --dark-color-text7-rgb: 164, 205, 250;
  --dark-color-text8: #7bb5f6;
  --dark-color-text8-rgb: 123, 181, 246;
  --dark-color-icon1: #fff;
  --dark-color-icon1-rgb: 255, 255, 255;
  --dark-color-icon2: #000;
  --dark-color-icon2-rgb: 0, 0, 0;
  --dark-color-icon3: #819aa9;
  --dark-color-icon3-rgb: 129, 154, 169;
  --dark-color-icon4: #bacade;
  --dark-color-icon4-rgb: 186, 202, 222;
  --dark-color-icon5: #819aa9;
  --dark-color-icon5-rgb: 129, 154, 169;
  --dark-color-icon6: #819aa9;
  --dark-color-icon6-rgb: 129, 154, 169;
  --dark-color-icon7: #c2d0d9;
  --dark-color-icon7-rgb: 194, 208, 217;
  --dark-color-icon8: #3e5867;
  --dark-color-icon8-rgb: 62, 88, 103;
  --dark-color-icon9: #7bb5f6;
  --dark-color-icon9-rgb: 123, 181, 246;
  --dark-color-stroke1: #5e7685;
  --dark-color-stroke1-rgb: 94, 118, 133;
  --dark-color-stroke2: #5e7685;
  --dark-color-stroke2-rgb: 94, 118, 133;
  --dark-color-stroke3: #294251;
  --dark-color-stroke3-rgb: 41, 66, 81;
  --dark-color-stroke4: #fff;
  --dark-color-stroke4-rgb: 255, 255, 255;
  --dark-color-stroke5: #adc1cd;
  --dark-color-stroke5-rgb: 173, 193, 205;
  --dark-color-info1: #559dee;
  --dark-color-info1-rgb: 85, 157, 238;
  --dark-color-warning1: #ff8c00;
  --dark-color-warning1-rgb: 255, 140, 0;
  --dark-color-danger1: #fa5353;
  --dark-color-danger1-rgb: 250, 83, 83;
  --dark-color-danger2: #fa5353;
  --dark-color-danger2-rgb: 250, 83, 83;
  --dark-font-regular: open-sans-regular;
  --dark-font-semibold: open-sans-semibold;
  --dark-font-bold: open-sans-bold;
}

html[data-theme="light"] {
  --color-v2-background1: #f5f5f5;
  --color-v2-background1-rgb: 245, 245, 245;
  --color-v2-background2: #f1f5f6;
  --color-v2-background2-rgb: 241, 245, 246;
  --color-v2-background3: #c9f7ea;
  --color-v2-background3-rgb: 201, 247, 234;
  --color-v2-background4: #eee;
  --color-v2-background4-rgb: 238, 238, 238;
  --color-v2-background5: #dceafa;
  --color-v2-background5-rgb: 220, 234, 250;
  --color-v2-background6: #eee;
  --color-v2-background6-rgb: 238, 238, 238;
  --color-v2-background7: #f1f5f6;
  --color-v2-background7-rgb: 241, 245, 246;
  --color-v2-background8: #f24c00;
  --color-v2-background8-rgb: 242, 76, 0;
  --color-v2-background9: #e5ecf0;
  --color-v2-background9-rgb: 229, 236, 240;
  --color-v2-background10: #819aa9;
  --color-v2-background10-rgb: 129, 154, 169;
  --color-v2-background12: #e5ecf0;
  --color-v2-background12-rgb: 229, 236, 240;
  --color-v2-border1: #f1f5f6;
  --color-v2-border1-rgb: 241, 245, 246;
  --color-v2-border2: #ececec;
  --color-v2-border2-rgb: 236, 236, 236;
  --color-v2-border3: #f24c00;
  --color-v2-border3-rgb: 242, 76, 0;
  --color-v2-border4: #fa5353;
  --color-v2-border4-rgb: 250, 83, 83;
  --color-v2-text1: #000;
  --color-v2-text1-rgb: 0, 0, 0;
  --color-v2-text2: #fff;
  --color-v2-text2-rgb: 255, 255, 255;
  --color-v2-text3: #819aa9;
  --color-v2-text3-rgb: 129, 154, 169;
  --color-v2-text4: #f24c00;
  --color-v2-text4-rgb: 242, 76, 0;
  --color-v2-text5: #559dee;
  --color-v2-text5-rgb: 85, 157, 238;
  --color-v2-text6: #295384;
  --color-v2-text6-rgb: 41, 83, 132;
  --color-v2-icon1: #000;
  --color-v2-icon1-rgb: 0, 0, 0;
  --color-v2-icon2: #fff;
  --color-v2-icon2-rgb: 255, 255, 255;
  --color-v2-icon3: #819aa9;
  --color-v2-icon3-rgb: 129, 154, 169;
  --color-v2-icon4: #f24c00;
  --color-v2-icon4-rgb: 242, 76, 0;
  --color-v2-icon5: #559dee;
  --color-v2-icon5-rgb: 85, 157, 238;
  --color-v2-icon6: #ffa06a;
  --color-v2-icon6-rgb: 255, 160, 106;
  --color-v2-icon7: #886dde;
  --color-v2-icon7-rgb: 136, 109, 222;
  --color-v2-error: #fa5353;
  --color-v2-error-rgb: 250, 83, 83;
  --color-v2-global1: #f24c00;
  --color-v2-global1-rgb: 242, 76, 0;
  --color-v2-global2: #f24c00;
  --color-v2-global2-rgb: 242, 76, 0;
  --color-v2-global3: #000;
  --color-v2-global3-rgb: 0, 0, 0;
  --color-v2-global4: #fff;
  --color-v2-global4-rgb: 255, 255, 255;
  --color-v2-global5: #295384;
  --color-v2-global5-rgb: 41, 83, 132;
  --color-v2-button-fill1: #20313c;
  --color-v2-button-fill1-rgb: 32, 49, 60;
  --color-v2-button-fill2: #f24c00;
  --color-v2-button-fill2-rgb: 242, 76, 0;
  --color-v2-button-fill3: #f1f5f6;
  --color-v2-button-fill3-rgb: 241, 245, 246;
  --color-v2-button-fill4: #c9f7ea;
  --color-v2-button-fill4-rgb: 201, 247, 234;
  --color-v2-button-fill5: #dceafa;
  --color-v2-button-fill5-rgb: 220, 234, 250;
  --color-v2-button-fill6: #fcece4;
  --color-v2-button-fill6-rgb: 252, 236, 228;
  --color-v2-button-fill7: #eeeafb;
  --color-v2-button-fill7-rgb: 238, 234, 251;
  --color-v2-button-border1: #f24c00;
  --color-v2-button-border1-rgb: 242, 76, 0;
  --color-v2-button-border2: #559dee;
  --color-v2-button-border2-rgb: 85, 157, 238;
  --color-v2-button-border3: #819aa9;
  --color-v2-button-border3-rgb: 129, 154, 169;
  --color-v2-button-hover1: #2e4350;
  --color-v2-button-hover1-rgb: 46, 67, 80;
  --color-v2-button-hover2: #e94900;
  --color-v2-button-hover2-rgb: 233, 73, 0;
  --color-v2-button-pressed1: #1a2a34;
  --color-v2-button-pressed1-rgb: 26, 42, 52;
  --color-v2-button-pressed2: #e94b03;
  --color-v2-button-pressed2-rgb: 233, 75, 3;
  --color-v2-note-note1: #fff;
  --color-v2-note-note1-rgb: 255, 255, 255;
  --color-v2-note-note2: #c9f7ea;
  --color-v2-note-note2-rgb: 201, 247, 234;
  --color-v2-note-note3: #dceafa;
  --color-v2-note-note3-rgb: 220, 234, 250;
  --color-v2-note-note4: #fcece4;
  --color-v2-note-note4-rgb: 252, 236, 228;
  --color-v2-note-note5: #eeeafb;
  --color-v2-note-note5-rgb: 238, 234, 251;
  --color-v2-goal-goal1: #dfb998;
  --color-v2-goal-goal1-rgb: 223, 185, 152;
  --color-v2-goal-goal2: #f8c081;
  --color-v2-goal-goal2-rgb: 248, 192, 129;
  --color-v2-goal-goal3: #ffa06a;
  --color-v2-goal-goal3-rgb: 255, 160, 106;
  --color-v2-goal-goal4: #ed7d7d;
  --color-v2-goal-goal4-rgb: 237, 125, 125;
  --color-v2-goal-goal5: #72ddb7;
  --color-v2-goal-goal5-rgb: 114, 221, 183;
  --color-v2-goal-goal6: #9bb6b5;
  --color-v2-goal-goal6-rgb: 155, 182, 181;
  --color-v2-goal-goal7: #75b4b4;
  --color-v2-goal-goal7-rgb: 117, 180, 180;
  --color-v2-goal-goal8: #adc3d4;
  --color-v2-goal-goal8-rgb: 173, 195, 212;
  --color-v2-goal-goal9: #87b9e8;
  --color-v2-goal-goal9-rgb: 135, 185, 232;
  --color-v2-goal-goal10: #57bad3;
  --color-v2-goal-goal10-rgb: 87, 186, 211;
  --color-v2-goal-goal11: #659cb9;
  --color-v2-goal-goal11-rgb: 101, 156, 185;
  --color-v2-onboarding-onboarding1: #edc829;
  --color-v2-onboarding-onboarding1-rgb: 237, 200, 41;
  --color-v2-lost-colors-blue-shrimp: #4a8dda;
  --color-v2-lost-colors-blue-shrimp-rgb: 74, 141, 218;
  --color-v2-lost-colors-green-elephant: #fb6e2e;
  --color-v2-lost-colors-green-elephant-rgb: 251, 110, 46;
  --color-v2-lost-colors-error-light: #f2e5e6;
  --color-v2-lost-colors-error-light-rgb: 242, 229, 230;
  --color-v2-lost-colors-page-bg: #f6f9fa;
  --color-v2-lost-colors-page-bg-rgb: 246, 249, 250;
  --color-v2-lost-colors-sasha-gray: #c0ccd4;
  --color-v2-lost-colors-sasha-gray-rgb: 192, 204, 212;
  --color-v2-gray10: #f1f5f6;
  --color-v2-gray10-rgb: 241, 245, 246;
  --color-v2-blue10: #cee5ff;
  --color-v2-blue10-rgb: 206, 229, 255;
  --color-note-note1: #fff;
  --color-note-note1-rgb: 255, 255, 255;
  --color-note-note2: #c9f7ea;
  --color-note-note2-rgb: 201, 247, 234;
  --color-note-note3: #dceafa;
  --color-note-note3-rgb: 220, 234, 250;
  --color-note-note4: #fcece4;
  --color-note-note4-rgb: 252, 236, 228;
  --color-note-note5: #eeeafb;
  --color-note-note5-rgb: 238, 234, 251;
  --color-goal-goal1: #dfb998;
  --color-goal-goal1-rgb: 223, 185, 152;
  --color-goal-goal2: #f8c081;
  --color-goal-goal2-rgb: 248, 192, 129;
  --color-goal-goal3: #ffa06a;
  --color-goal-goal3-rgb: 255, 160, 106;
  --color-goal-goal4: #ed7d7d;
  --color-goal-goal4-rgb: 237, 125, 125;
  --color-goal-goal5: #72ddb7;
  --color-goal-goal5-rgb: 114, 221, 183;
  --color-goal-goal6: #9bb6b5;
  --color-goal-goal6-rgb: 155, 182, 181;
  --color-goal-goal7: #75b4b4;
  --color-goal-goal7-rgb: 117, 180, 180;
  --color-goal-goal8: #adc3d4;
  --color-goal-goal8-rgb: 173, 195, 212;
  --color-goal-goal9: #87b9e8;
  --color-goal-goal9-rgb: 135, 185, 232;
  --color-goal-goal10: #57bad3;
  --color-goal-goal10-rgb: 87, 186, 211;
  --color-goal-goal11: #659cb9;
  --color-goal-goal11-rgb: 101, 156, 185;
  --color-onboarding1: #edc829;
  --color-onboarding1-rgb: 237, 200, 41;
  --color-primary1: #559dee;
  --color-primary1-rgb: 85, 157, 238;
  --color-primary2: #4989d2;
  --color-primary2-rgb: 73, 137, 210;
  --color-primary3: #cee5ff;
  --color-primary3-rgb: 206, 229, 255;
  --color-primary4: #295384;
  --color-primary4-rgb: 41, 83, 132;
  --color-secondary1: #f24c00;
  --color-secondary1-rgb: 242, 76, 0;
  --color-secondary2: #33deae;
  --color-secondary2-rgb: 51, 222, 174;
  --color-secondary3: #b5efdf;
  --color-secondary3-rgb: 181, 239, 223;
  --color-secondary4: #d1efe6;
  --color-secondary4-rgb: 209, 239, 230;
  --color-secondary5: #dff4ee;
  --color-secondary5-rgb: 223, 244, 238;
  --color-secondary6: #d1efe6;
  --color-secondary6-rgb: 209, 239, 230;
  --color-secondary7: #dff4ee;
  --color-secondary7-rgb: 223, 244, 238;
  --color-background1: #fff;
  --color-background1-rgb: 255, 255, 255;
  --color-background2: #f1f5f6;
  --color-background2-rgb: 241, 245, 246;
  --color-background3: #5e7685;
  --color-background3-rgb: 94, 118, 133;
  --color-background4: #d2dce8;
  --color-background4-rgb: 210, 220, 232;
  --color-background5: #f1f5f6;
  --color-background5-rgb: 241, 245, 246;
  --color-background6: #f1f5f6;
  --color-background6-rgb: 241, 245, 246;
  --color-background7: #adc1cd;
  --color-background7-rgb: 173, 193, 205;
  --color-background8: #294251;
  --color-background8-rgb: 41, 66, 81;
  --color-background9: #5e7685;
  --color-background9-rgb: 94, 118, 133;
  --color-background10: #1c3c61;
  --color-background10-rgb: 28, 60, 97;
  --color-background11: #819aa9;
  --color-background11-rgb: 129, 154, 169;
  --color-background12: #e5ecf0;
  --color-background12-rgb: 229, 236, 240;
  --color-text1: #000;
  --color-text1-rgb: 0, 0, 0;
  --color-text2: #fff;
  --color-text2-rgb: 255, 255, 255;
  --color-text3: #819aa9;
  --color-text3-rgb: 129, 154, 169;
  --color-text4: #adc1cd;
  --color-text4-rgb: 173, 193, 205;
  --color-text5: #819aa9;
  --color-text5-rgb: 129, 154, 169;
  --color-text6: #c2d0d9;
  --color-text6-rgb: 194, 208, 217;
  --color-text7: #a4cdfa;
  --color-text7-rgb: 164, 205, 250;
  --color-text8: #7bb5f6;
  --color-text8-rgb: 123, 181, 246;
  --color-icon1: #000;
  --color-icon1-rgb: 0, 0, 0;
  --color-icon2: #fff;
  --color-icon2-rgb: 255, 255, 255;
  --color-icon3: #819aa9;
  --color-icon3-rgb: 129, 154, 169;
  --color-icon4: #bacade;
  --color-icon4-rgb: 186, 202, 222;
  --color-icon5: #819aa9;
  --color-icon5-rgb: 129, 154, 169;
  --color-icon6: #819aa9;
  --color-icon6-rgb: 129, 154, 169;
  --color-icon7: #c2d0d9;
  --color-icon7-rgb: 194, 208, 217;
  --color-icon8: #adc1cd;
  --color-icon8-rgb: 173, 193, 205;
  --color-icon9: #7bb5f6;
  --color-icon9-rgb: 123, 181, 246;
  --color-stroke1: #e5ecf0;
  --color-stroke1-rgb: 229, 236, 240;
  --color-stroke2: #c2d0d9;
  --color-stroke2-rgb: 194, 208, 217;
  --color-stroke3: #d2dce8;
  --color-stroke3-rgb: 210, 220, 232;
  --color-stroke4: #fff;
  --color-stroke4-rgb: 255, 255, 255;
  --color-stroke5: #adc1cd;
  --color-stroke5-rgb: 173, 193, 205;
  --color-info1: #559dee;
  --color-info1-rgb: 85, 157, 238;
  --color-warning1: #ffa06a;
  --color-warning1-rgb: 255, 160, 106;
  --color-danger1: #fa5353;
  --color-danger1-rgb: 250, 83, 83;
  --color-danger2: #fa5353;
  --color-danger2-rgb: 250, 83, 83;
  --font-regular: open-sans-regular;
  --font-semibold: open-sans-semibold;
  --font-bold: open-sans-bold;
}

html[data-theme="dark"] {
  --color-v2-background1: #14171b;
  --color-v2-background1-rgb: 20, 23, 27;
  --color-v2-background2: #202326;
  --color-v2-background2-rgb: 32, 35, 38;
  --color-v2-background3: #162f2c;
  --color-v2-background3-rgb: 22, 47, 44;
  --color-v2-background4: #173c35;
  --color-v2-background4-rgb: 23, 60, 53;
  --color-v2-background5: #1c2837;
  --color-v2-background5-rgb: 28, 40, 55;
  --color-v2-background6: #203145;
  --color-v2-background6-rgb: 32, 49, 69;
  --color-v2-background7: #202326;
  --color-v2-background7-rgb: 32, 35, 38;
  --color-v2-background8: #1fb088;
  --color-v2-background8-rgb: 31, 176, 136;
  --color-v2-background9: #33383e;
  --color-v2-background9-rgb: 51, 56, 62;
  --color-v2-background10: #819aa9;
  --color-v2-background10-rgb: 129, 154, 169;
  --color-v2-background12: #e5ecf0;
  --color-v2-background12-rgb: 229, 236, 240;
  --color-v2-border1: #202326;
  --color-v2-border1-rgb: 32, 35, 38;
  --color-v2-border2: #33383e;
  --color-v2-border2-rgb: 51, 56, 62;
  --color-v2-border3: #1fb088;
  --color-v2-border3-rgb: 31, 176, 136;
  --color-v2-border4: #d24646;
  --color-v2-border4-rgb: 210, 70, 70;
  --color-v2-text1: #f5f9fa;
  --color-v2-text1-rgb: 245, 249, 250;
  --color-v2-text2: #000;
  --color-v2-text2-rgb: 0, 0, 0;
  --color-v2-text3: #7a8086;
  --color-v2-text3-rgb: 122, 128, 134;
  --color-v2-text4: #1fb088;
  --color-v2-text4-rgb: 31, 176, 136;
  --color-v2-text5: #4784c8;
  --color-v2-text5-rgb: 71, 132, 200;
  --color-v2-text6: #295384;
  --color-v2-text6-rgb: 41, 83, 132;
  --color-v2-icon1: #f5f9fa;
  --color-v2-icon1-rgb: 245, 249, 250;
  --color-v2-icon2: #000;
  --color-v2-icon2-rgb: 0, 0, 0;
  --color-v2-icon3: #7a8086;
  --color-v2-icon3-rgb: 122, 128, 134;
  --color-v2-icon4: #1fb088;
  --color-v2-icon4-rgb: 31, 176, 136;
  --color-v2-icon5: #4784c8;
  --color-v2-icon5-rgb: 71, 132, 200;
  --color-v2-icon6: #cc8055;
  --color-v2-icon6-rgb: 204, 128, 85;
  --color-v2-icon7: #7861c4;
  --color-v2-icon7-rgb: 120, 97, 196;
  --color-v2-error: #d24646;
  --color-v2-error-rgb: 210, 70, 70;
  --color-v2-global1: #1fb088;
  --color-v2-global1-rgb: 31, 176, 136;
  --color-v2-global2: #4784c8;
  --color-v2-global2-rgb: 71, 132, 200;
  --color-v2-global3: #fff;
  --color-v2-global3-rgb: 255, 255, 255;
  --color-v2-global4: #000;
  --color-v2-global4-rgb: 0, 0, 0;
  --color-v2-global5: #295384;
  --color-v2-global5-rgb: 41, 83, 132;
  --color-v2-button-fill1: #1fb088;
  --color-v2-button-fill1-rgb: 31, 176, 136;
  --color-v2-button-fill2: #4784c8;
  --color-v2-button-fill2-rgb: 71, 132, 200;
  --color-v2-button-fill3: #202326;
  --color-v2-button-fill3-rgb: 32, 35, 38;
  --color-v2-button-fill4: #162f2c;
  --color-v2-button-fill4-rgb: 22, 47, 44;
  --color-v2-button-fill5: #1c2837;
  --color-v2-button-fill5-rgb: 28, 40, 55;
  --color-v2-button-fill6: #322d28;
  --color-v2-button-fill6-rgb: 50, 45, 40;
  --color-v2-button-fill7: #27253a;
  --color-v2-button-fill7-rgb: 39, 37, 58;
  --color-v2-button-border1: #1fb088;
  --color-v2-button-border1-rgb: 31, 176, 136;
  --color-v2-button-border2: #4989d2;
  --color-v2-button-border2-rgb: 73, 137, 210;
  --color-v2-button-border3: #7a8086;
  --color-v2-button-border3-rgb: 122, 128, 134;
  --color-v2-button-hover1: #2e4350;
  --color-v2-button-hover1-rgb: 46, 67, 80;
  --color-v2-button-hover2: #e94900;
  --color-v2-button-hover2-rgb: 233, 73, 0;
  --color-v2-button-pressed1: #1a2a34;
  --color-v2-button-pressed1-rgb: 26, 42, 52;
  --color-v2-button-pressed2: #e94b03;
  --color-v2-button-pressed2-rgb: 233, 75, 3;
  --color-v2-note-note1: #fff;
  --color-v2-note-note1-rgb: 255, 255, 255;
  --color-v2-note-note2: #c9f7ea;
  --color-v2-note-note2-rgb: 201, 247, 234;
  --color-v2-note-note3: #dceafa;
  --color-v2-note-note3-rgb: 220, 234, 250;
  --color-v2-note-note4: #fcece4;
  --color-v2-note-note4-rgb: 252, 236, 228;
  --color-v2-note-note5: #eeeafb;
  --color-v2-note-note5-rgb: 238, 234, 251;
  --color-v2-goal-goal1: #dfb998;
  --color-v2-goal-goal1-rgb: 223, 185, 152;
  --color-v2-goal-goal2: #f8c081;
  --color-v2-goal-goal2-rgb: 248, 192, 129;
  --color-v2-goal-goal3: #ffa06a;
  --color-v2-goal-goal3-rgb: 255, 160, 106;
  --color-v2-goal-goal4: #ed7d7d;
  --color-v2-goal-goal4-rgb: 237, 125, 125;
  --color-v2-goal-goal5: #72ddb7;
  --color-v2-goal-goal5-rgb: 114, 221, 183;
  --color-v2-goal-goal6: #9bb6b5;
  --color-v2-goal-goal6-rgb: 155, 182, 181;
  --color-v2-goal-goal7: #75b4b4;
  --color-v2-goal-goal7-rgb: 117, 180, 180;
  --color-v2-goal-goal8: #adc3d4;
  --color-v2-goal-goal8-rgb: 173, 195, 212;
  --color-v2-goal-goal9: #87b9e8;
  --color-v2-goal-goal9-rgb: 135, 185, 232;
  --color-v2-goal-goal10: #57bad3;
  --color-v2-goal-goal10-rgb: 87, 186, 211;
  --color-v2-goal-goal11: #659cb9;
  --color-v2-goal-goal11-rgb: 101, 156, 185;
  --color-v2-onboarding-onboarding1: #edc829;
  --color-v2-onboarding-onboarding1-rgb: 237, 200, 41;
  --color-v2-lost-colors-blue-shrimp: #4a8dda;
  --color-v2-lost-colors-blue-shrimp-rgb: 74, 141, 218;
  --color-v2-lost-colors-green-elephant: #fb6e2e;
  --color-v2-lost-colors-green-elephant-rgb: 251, 110, 46;
  --color-v2-lost-colors-error-light: #f2e5e6;
  --color-v2-lost-colors-error-light-rgb: 242, 229, 230;
  --color-v2-lost-colors-page-bg: #f6f9fa;
  --color-v2-lost-colors-page-bg-rgb: 246, 249, 250;
  --color-v2-lost-colors-sasha-gray: #c0ccd4;
  --color-v2-lost-colors-sasha-gray-rgb: 192, 204, 212;
  --color-v2-gray10: #f1f5f6;
  --color-v2-gray10-rgb: 241, 245, 246;
  --color-v2-blue10: #cee5ff;
  --color-v2-blue10-rgb: 206, 229, 255;
  --color-note-note1: #202326;
  --color-note-note1-rgb: 32, 35, 38;
  --color-note-note2: #162f2c;
  --color-note-note2-rgb: 22, 47, 44;
  --color-note-note3: #1c2837;
  --color-note-note3-rgb: 28, 40, 55;
  --color-note-note4: #322d28;
  --color-note-note4-rgb: 50, 45, 40;
  --color-note-note5: #27253a;
  --color-note-note5-rgb: 39, 37, 58;
  --color-goal-goal1: #bb9b80;
  --color-goal-goal1-rgb: 187, 155, 128;
  --color-goal-goal2: #d0a16c;
  --color-goal-goal2-rgb: 208, 161, 108;
  --color-goal-goal3: #d68659;
  --color-goal-goal3-rgb: 214, 134, 89;
  --color-goal-goal4: #c76969;
  --color-goal-goal4-rgb: 199, 105, 105;
  --color-goal-goal5: #60ba9a;
  --color-goal-goal5-rgb: 96, 186, 154;
  --color-goal-goal6: #829998;
  --color-goal-goal6-rgb: 130, 153, 152;
  --color-goal-goal7: #629797;
  --color-goal-goal7-rgb: 98, 151, 151;
  --color-goal-goal8: #91a4b2;
  --color-goal-goal8-rgb: 145, 164, 178;
  --color-goal-goal9: #719bc3;
  --color-goal-goal9-rgb: 113, 155, 195;
  --color-goal-goal10: #499cb1;
  --color-goal-goal10-rgb: 73, 156, 177;
  --color-goal-goal11: #55839b;
  --color-goal-goal11-rgb: 85, 131, 155;
  --color-onboarding1: #202326;
  --color-onboarding1-rgb: 32, 35, 38;
  --color-primary1: #559dee;
  --color-primary1-rgb: 85, 157, 238;
  --color-primary2: #4989d2;
  --color-primary2-rgb: 73, 137, 210;
  --color-primary3: #1f4571;
  --color-primary3-rgb: 31, 69, 113;
  --color-primary4: #295384;
  --color-primary4-rgb: 41, 83, 132;
  --color-secondary1: #f24c00;
  --color-secondary1-rgb: 242, 76, 0;
  --color-secondary2: #33deae;
  --color-secondary2-rgb: 51, 222, 174;
  --color-secondary3: #b5efdf;
  --color-secondary3-rgb: 181, 239, 223;
  --color-secondary4: #d1efe6;
  --color-secondary4-rgb: 209, 239, 230;
  --color-secondary5: #10191e;
  --color-secondary5-rgb: 16, 25, 30;
  --color-secondary6: #324440;
  --color-secondary6-rgb: 50, 68, 64;
  --color-secondary7: #324440;
  --color-secondary7-rgb: 50, 68, 64;
  --color-background1: #10191e;
  --color-background1-rgb: 16, 25, 30;
  --color-background2: #19252d;
  --color-background2-rgb: 25, 37, 45;
  --color-background3: #5e7685;
  --color-background3-rgb: 94, 118, 133;
  --color-background4: #3e5867;
  --color-background4-rgb: 62, 88, 103;
  --color-background5: #19252d;
  --color-background5-rgb: 25, 37, 45;
  --color-background6: #19252d;
  --color-background6-rgb: 25, 37, 45;
  --color-background7: #3e5867;
  --color-background7-rgb: 62, 88, 103;
  --color-background8: #294251;
  --color-background8-rgb: 41, 66, 81;
  --color-background9: #5e7685;
  --color-background9-rgb: 94, 118, 133;
  --color-background10: #1c3c61;
  --color-background10-rgb: 28, 60, 97;
  --color-background11: #819aa9;
  --color-background11-rgb: 129, 154, 169;
  --color-background12: #19252d;
  --color-background12-rgb: 25, 37, 45;
  --color-text1: #fff;
  --color-text1-rgb: 255, 255, 255;
  --color-text2: #000;
  --color-text2-rgb: 0, 0, 0;
  --color-text3: #819aa9;
  --color-text3-rgb: 129, 154, 169;
  --color-text4: #3e5867;
  --color-text4-rgb: 62, 88, 103;
  --color-text5: #819aa9;
  --color-text5-rgb: 129, 154, 169;
  --color-text6: #3e5867;
  --color-text6-rgb: 62, 88, 103;
  --color-text7: #a4cdfa;
  --color-text7-rgb: 164, 205, 250;
  --color-text8: #7bb5f6;
  --color-text8-rgb: 123, 181, 246;
  --color-icon1: #fff;
  --color-icon1-rgb: 255, 255, 255;
  --color-icon2: #000;
  --color-icon2-rgb: 0, 0, 0;
  --color-icon3: #819aa9;
  --color-icon3-rgb: 129, 154, 169;
  --color-icon4: #bacade;
  --color-icon4-rgb: 186, 202, 222;
  --color-icon5: #819aa9;
  --color-icon5-rgb: 129, 154, 169;
  --color-icon6: #819aa9;
  --color-icon6-rgb: 129, 154, 169;
  --color-icon7: #c2d0d9;
  --color-icon7-rgb: 194, 208, 217;
  --color-icon8: #3e5867;
  --color-icon8-rgb: 62, 88, 103;
  --color-icon9: #7bb5f6;
  --color-icon9-rgb: 123, 181, 246;
  --color-stroke1: #5e7685;
  --color-stroke1-rgb: 94, 118, 133;
  --color-stroke2: #5e7685;
  --color-stroke2-rgb: 94, 118, 133;
  --color-stroke3: #294251;
  --color-stroke3-rgb: 41, 66, 81;
  --color-stroke4: #fff;
  --color-stroke4-rgb: 255, 255, 255;
  --color-stroke5: #adc1cd;
  --color-stroke5-rgb: 173, 193, 205;
  --color-info1: #559dee;
  --color-info1-rgb: 85, 157, 238;
  --color-warning1: #ff8c00;
  --color-warning1-rgb: 255, 140, 0;
  --color-danger1: #fa5353;
  --color-danger1-rgb: 250, 83, 83;
  --color-danger2: #fa5353;
  --color-danger2-rgb: 250, 83, 83;
  --font-regular: open-sans-regular;
  --font-semibold: open-sans-semibold;
  --font-bold: open-sans-bold;
}
