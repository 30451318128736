@import '@src/styles/vars.css';

.progress {
  height: 4px;

  .bar {
    display: inline-block;
    width: 28px;
    height: 4px;
    margin-right: 8px;
    border-radius: 4px;
    background: #d4dadc;

    &.active {
      background: var(--color-v2-button-fill2);
    }
  }
}

.background {
  width: 100%;
  height: 100vh;
}

.leftBox {
  position: absolute;
  left: 0;
  top: 0;
  right: calc(50% + 210px);
  bottom: 0;
  background: var(--color-v2-background2);

  .logo {
    width: 199px;
    height: 44px;
    position: absolute;
    right: 81px;
    top: 63px;
    display: flex;
    align-items: center;

    #logoId {
      width: 44px;
      height: 44px;
    }

    #textId {
      height: 16px;
      fill: #295384;
    }
  }
}

.content {
  min-width: 980px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 12px;
  min-height: 64px;
  background: var(--color-v2-background1);
  border: 1px solid var(--color-v2-border2);
  border-radius: 12px;
  cursor: pointer;

  &.selected {
    background: var(--color-v2-button-fill2);
  }

  &.selected .title {
    color: var(--color-v2-text2);
  }

  &.small {
    min-height: 48px;
  }

  &.editable {
    cursor: auto;

    &.selected {
      background: var(--color-v2-background1);
    }
  }

  .title {
    flex-grow: 1;
    padding-right: 20px;
    color: var(--color-v2-text6);
    font-size: 16px;
    font-weight: 600;

    .small & {
      font-weight: normal;
    }

    .selected & {
      color: var(--color-v2-text2);
    }

    & input {
      color: var(--color-v2-text6);
    }
  }

  .icon {
    margin-right: 16px;
    fill: var(--color-v2-text5);
    background: var(--color-v2-background1);
  }

  &.selected .icon {
    fill: var(--color-v2-background1);
    background: var(--color-v2-text5);
  }

  .radioMark {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    border: 1px solid var(--color-v2-border2);
    border-radius: 10px;

    &.selected {
      background: var(--color-v2-button-fill2);
      border: 5px solid var(--color-v2-background1);
    }
  }

  .checkMark {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border: 1px solid var(--color-v2-border2);
    border-radius: 4px;
    background: var(--color-v2-background1);

    & svg {
      width: 24px;
      height: 24px;
      margin-left: -2px;
      fill: var(--color-v2-button-fill2);
    }
  }
}
