.TasksWrapper {
  display: flex;
  flex-direction: column;
  width: 442px;
}

.LeftArrow {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  cursor: pointer;
}

.RightArrow {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
}

.PlusIcon {
  margin-right: 8px;
}

.StartConferenceButton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  width: 89px;
  height: 32px;
  background: #559dee;
  border-radius: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: #ffffff;
}

.DateSwitcherWrapper {
  display: flex;
}

.DateWrapper {
  display: flex;
  align-items: center;
}

.Date {
  margin-right: 12px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #000;
}

.Timezone {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: #819aa9;
}

.MainWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f5f6;
}

.CalendarPortalWrapper {
  position: absolute;
  top: 196px;
  right: 416px;
}

.Calendar {
  .Calendar button.Mui-selected {
    background: green;
  }
}

.TaskIcon {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.ScrollableArea {
  height: calc(68vh - 24px);
  padding-right: 6px;
  margin: 12px 0;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f5f6;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25d2a2;
    border-radius: 2px;
  }
}

.TasksContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Task {
  padding: 10px 12px;
  display: flex;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(1, 1, 1, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TaskIconAndDataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.TitleAndTimeTaskContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.41px;
  color: #000000;

  .TaskTitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.467561px;
    color: #000000;
    text-align: start;
  }
}

.ControlsWrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TasksMenu {
  width: 100%;
}
