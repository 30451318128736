@import "../../styles/vars.css";

.AddFile {
  display: grid;
  height: 100%;
  grid-template-rows: 200px auto 70px;
  row-gap: 10px;

  .customButtonContainer {
    position: absolute;
    bottom: 47px;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
  }

  .fileDrag {
    margin: 45px 0 20px;
    cursor: default;
    background-color: $grey-yellow-color;
    color: $grey-color-text;
    border-radius: 10px;

    .inputWrapper {
      position: relative;
      width: 100%;
      overflow: hidden;
      color: $black-light-text-color;

      .input {
        position: absolute;
        top: 0;
        left: -10em;
        height: 0;
        width: 0;
        opacity: 0;
      }

      .inputCover {
        padding: 1em;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        align-content: center;
        border-radius: 2px;

        .button {
          font-size: 0.8em;
          margin-right: 0.5em;
          padding: 0.35em 1em;
          border: 1px solid #f24c00;
          color: #f24c00;
          font-weight: lighter;
          box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 45%);
        }

        .fileName {
          -webkit-box-flex: 0;
          flex: 0 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .fileExt {
          -webkit-box-flex: 1;
          flex: 1;
        }
      }
    }

    .helpText {
      padding: 1em;
      display: inline-block;
      color: $black-light-text-color;
    }
  }

  .previews {
    height: 100%;
  }

  .hiddenContainer {
    height: 70px;
  }
}
