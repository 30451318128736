@import "@src/styles/vars.css";
@import url("https://fonts.googleapis.com/css2?family=Mr+De+Haviland&family=Seaweed+Script&display=swap");

.container {
  padding-bottom: 20px;
}

.header {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.4675px;
  padding-bottom: 20px;
}

.signatureHolder {
  font-family: "Mr De Haviland", cursive;
  font-size: 50px;
  top: -10px;
  position: relative;
}

.signatureName {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  margin-bottom: 10px;
}

.signatureDate {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-v2-text3);
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  :global(.Button) {
    margin-left: 20px;
  }
}
