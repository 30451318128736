@import "@src/styles/vars.css";

.QDetail {
  hr {
    border: 1px solid rgba(178, 187, 198, 20%);
  }

  .questionsBody {
    margin-top: 30px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;

  .tittleContainer {
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
    }

    .user {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.4675px;
      color: $grey-color-name;
    }
  }
}

.About {
  padding-top: 30px;
}

.description {
  max-width: 480px;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.24px;
}
