.container {
  border-radius: 16px;
  background-color: var(--color-v2-background2);
  padding: 20px 30px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.titleButtonsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.4675px;
  color: var(--color-v2-text1);
  margin-bottom: 8px;
}

.addButton,
.resetButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  background-color: var(--color-v2-button-fill1);

  svg {
    width: 24px;
    height: 24px;
    fill: var(--light-color-v2-icon2);
    margin-right: 15px;
  }
}

.resetButton {
  background-color: initial;
  color: var(--color-v2-text4);
}

.message {
  border-radius: 10px;
  background-color: var(--color-v2-background2);
  font-size: 15px;
  line-height: 20px;
  color: var(--color-v2-text3);
}

.cloneContainer {
  margin-top: 18px;
}

.cloneButton {
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    fill: var(--light-color-v2-text2);
  }
}
