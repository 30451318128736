@import "@src/styles/vars.css";

.container {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 600;
  color: var(--color-v2-text5);
  text-align: center;

  .image {
    width: auto;
    height: 168px;
  }

  button {
    width: 141px;
    height: 38px;
    margin-top: 20px;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-v2-text5);
    background: var(--color-v2-background5);
    box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
    border-radius: 10px;
  }
}

.modal {
  min-width: 0 !important;
  overflow-y: auto !important;
}

.modalBody {
  width: 335px;
  height: auto;
  padding-bottom: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: var(--color-v2-text5);
  text-align: center;
  border-radius: 20px;

  div {
    margin: 20px 0;
  }

  button {
    width: 100%;

    &.skip {
      background: none;
      color: var(--color-v2-button-border2);
    }
  }
}

.mozillaClipPathWorkaround {
  svg {
    g {
      clip-path: none !important;
    }
  }
}
