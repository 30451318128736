@import "@src/styles/vars.css";

.Breadcrumbs {
  display: flex;
  align-items: center;

  .LinksContainer {
    display: flex;
    align-items: center;
  }

  .Links {
    a {
      color: $grey-color-dark;
      text-decoration: none;
    }
  }

  .Tales {
    color: $periwinkle-crayola-1;
  }
}

.Separator {
  height: 3px;
  width: 3px;
  background-color: $grey-color-dark;
  border-radius: 50%;
  margin: 11px 10px;
}

.ArrowSeparator {
  margin-top: 1px;
  margin-right: 16px;
}
