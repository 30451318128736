.dataRow {
  margin-bottom: 20px;
}

.header {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.4675px;
  color: var(--color-v2-text1);
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .sub {
    color: var(--color-v2-text3);
    font-weight: normal;
  }
}

.switchContainer {
  display: flex;
  max-width: 450px;
}

.switchContent {
  flex: 1;
  margin-right: 15px;
}

.switchDescription {
  color: var(--color-v2-text3);
  font-size: 16px;
  line-height: 22px;
}

.footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-v2-background1);
}

.descriptionTextArea {
  min-height: 100px;
  resize: vertical;
}
