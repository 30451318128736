.CategoriesListEditor {
  width: 100%;
  height: 100%;

  .error {
    color: var(--color-v2-error);
    font-size: 14px;
    text-align: left;
  }

  .categoryIcon {
    width: 40px;
    height: 40px;
  }
}
