.wrapper {
  max-width: 754px;
  max-height: fit-content;
  margin: 0 10px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  font-family: 'GT Walsheim Pro', sans-serif;
  color: #1f313c;
}

.modalContentWrapper {
  height: 100vh;
  padding: 0;
}

.confirmationWrapper {
  max-width: 600px;
  padding: 30px 40px;
}

.titleContainer {
  padding: 40px 40px 21px;
  display: flex;
  justify-content: space-between;
}

.title {
  padding: 0;
  margin-bottom: 13px;
  font-size: 24px;
  line-height: 28px;
}

.confirmationTitle {
  text-align: center;
  margin-bottom: 24px;
}

.subtitle {
  padding: 0;
  line-height: 18px;
}

.desc {
  max-height: 159px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  overflow-y: auto;
  background-color: #f5f5f5;
  color: rgba(31, 49, 60, 0.6);
  padding: 20px 40px 0;
}

.buttonsWrapper {
  display: flex;
  gap: 10px;
  padding: 28px 0 30px;
  justify-content: center;
}

.confirmationButtons {
  padding: 0;
  gap: 16px;

  .button {
    max-width: 150px;
    width: 30%;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  max-width: 216px;
  min-width: 120px;
  height: 40px;
  border: 0;
  border-radius: 45px;
  background: #1f313c;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 420px) {
  .titleContainer {
    padding: 18px;
  }

  .title {
    font-size: 20px;
    white-space: nowrap;
  }

  .confirmationTitle {
    white-space: break-spaces;
  }

  .logo {
    width: 73px;
  }

  .desc {
    max-height: 300px;
    padding: 20px 20px 0;
  }

  .buttonsWrapper {
    padding: 20px 0;
  }

  .confirmationWrapper {
    padding: 18px 15px;
  }

  .confirmationButtons {
    padding: 0;
    justify-content: space-between;
    gap: 6px;

    .button {
      width: 80%;
      font-size: 13px;
    }
  }
}
