@import '@src/styles/vars.css';

.CustomBurgerBut {
  position: relative;
  width: 50px;
  height: 34px;
  margin-top: 10px;
  margin-left: 16px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 6px 0 6px;
  font-size: 12px;
  line-height: 34px;
  border-radius: 4px;
  color: $white-color;
  background-size: cover;
  transition: 0.1s;

  .burgerLines {
    position: absolute;
    width: 20px;
    height: 2px;
    top: 45%;
    left: 14px;
    background-color: $grey-color-dark;

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      transform: translate3d(0, -5px, 0);
      top: 0;
      left: 0;
      background-color: $grey-color-dark;
      transform-origin: center;
      transition: transform 0.5s;
    }

    &::after {
      transform: translate3d(0, 5px, 0);
    }
  }

  &.open {
    .burgerLines {
      background-color: transparent;
      transform: none;

      &::before {
        transform: rotate(-45deg) translate3d(0, 0, 0);
      }

      &::after {
        transform: rotate(45deg) translate3d(0, 0, 0);
      }
    }
  }
}
