@import '@src/styles/vars.css';

.MyProfileImgContainer {
  position: relative;

  .MyProfileAnimate {
    position: relative;
    height: 100%;
    width: 100%;

    .containerCircleBlue {
      position: absolute;
      width: 86%;
      height: 86%;
      top: 7%;
      left: 7%;
      transition: 150s cubic-bezier(0.34, 0.17, 0.61, 0.8);
    }

    .containerCircleGreen {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: 150s cubic-bezier(0.34, 0.17, 0.61, 0.8);
    }

    .containerCircleGrey {
      position: absolute;
      width: 86%;
      height: 86%;
      top: 7%;
      left: 7%;
      transition: 150s cubic-bezier(0.34, 0.17, 0.61, 0.8);
    }
  }

  &:hover {
    .containerCircleBlue {
      transform: rotate(7200deg);
    }

    .containerCircleGrey {
      transform: rotate(7000deg);
    }

    .containerCircleGreen {
      transform: rotate(5200deg);
    }
  }

  .MyProfileImg {
    position: absolute;
    height: 70%;
    width: 70%;
    top: 15%;
    left: 15%;
  }
}
