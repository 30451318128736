@import "@src/styles/vars.css";

.QSectionTemplateList {
  margin-top: 15px;
  margin-bottom: 15px;

  .sectionsContainer {
    max-height: 300px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: -15px;

    .sections {
      background: $white-color;
      box-shadow: 0 4px 15px rgba(68, 48, 131, 13%);
      border-radius: 15px;
      padding: 15px 20px 18px;
      margin: 15px;
      display: flex;
      flex-direction: column;

      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 27px;
      }

      ul {
        padding: 7px 10px 10px;
        margin: 0;
        max-height: 150px;
        overflow: hidden;
        overflow-y: auto;
        overflow-x: auto;

        li {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 153.69%;
          color: $purple-color;

          span {
            color: $grey-color-name;
          }
        }
      }

      .buttContainer {
        display: grid;
        grid-template-columns: 1fr;
        flex-grow: 1;
        align-items: end;

        .sendBut {
          font-size: 12px;
        }
      }
    }
  }
}
