@import '@src/styles/vars.css';

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;

  .menuItem {
    cursor: pointer;
    margin-right: 45px;

    .menuItemContent {
      display: flex;
      align-items: center;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.467561px;
      color: #819aa9;

      .counter {
        margin-left: 8px;
        background: $alert-color;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-indent: -1px;
        color: $white-color;
      }
    }
  }
}
