@import '@src/styles/vars.css';

.buttonsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  :global(.Button) {
    border-radius: 6px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      fill: var(--light-color-v2-icon2);
    }

    span {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.noResults {
  min-height: 20vh;

  svg {
    fill: var(--color-v2-icon3);
  }
}
