.container {
  padding: 20px;
  background-color: var(--color-v2-background6);
  border-radius: 15px;
  display: flex;
  cursor: pointer;
}

.contentContainer {
  flex: 1;
}

.labelContainer {
  display: flex;
  margin-bottom: 4px;

  .label {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: var(--color-v2-text3);
  }

  svg {
    margin-right: 5px;
    width: 18px;
    height: 18px;
    fill: var(--color-v2-icon3);
  }
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.41px;
  color: var(--color-v2-text5);
}

.description {
  padding-top: 8px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: var(--color-v2-text1);
}

.moreButton {
  padding: 0;
  background-color: initial;
  position: relative;

  svg {
    width: 28px;
    height: 28px;
    fill: var(--color-v2-icon3);
  }
}
