.addButtonsContainer {
  display: flex;

  :global(.Button) {
    height: 44px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 28px;
      height: 28px;
      fill: var(--light-color-v2-icon2);
      margin-right: 10px;
    }
  }

  .clearButton {
    background-color: initial;
    color: var(--color-v2-text4);
  }
}

.noResultsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list {
  width: 60%;

  .sectionHeaderContainer {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .sectionHeader {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.24px;
      padding-right: 20px;
      color: var(--color-v2-text3);
      background-color: var(--color-v2-background1);
    }

    .line {
      flex: 1;
      height: 1px;
      background-color: var(--color-v2-border2);
    }
  }
}
