@import '@src/styles/vars.css';

input[type='range'] {
  height: 44px;
  -webkit-appearance: none;
  width: 100%;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animation-duration: 0.2s;
  box-shadow: 0 0 0 $black-text-color;
  background: $purple-color-light;
  border-radius: 10px;
  border: 0 solid $black-text-color;
}

input[type='range']::-webkit-slider-thumb {
  box-shadow: 2px 2px 4px $rose_quartz;
  border: 0 solid $black-text-color;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $white-color;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: $purple-color-light;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animation-duration: 0.2s;
  box-shadow: 0 0 0 $black-text-color;
  background: $purple-color-light;
  border-radius: 10px;
  border: 0 solid $black-text-color;
}

input[type='range']::-moz-range-thumb {
  box-shadow: 2px 2px 4px $rose_quartz;
  border: 0 solid $black-text-color;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $white-color;
  cursor: pointer;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animation-duration: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: $purple-color;
  border: 0 solid $black-text-color;
  border-radius: 20px;
  box-shadow: 0 0 0 $black-text-color;
}

input[type='range']::-ms-fill-upper {
  background: $purple-color-light;
  border: 0 solid $black-text-color;
  border-radius: 20px;
  box-shadow: 0 0 0 $black-text-color;
}

input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 2px 2px 4px $rose_quartz;
  border: 0 solid $black-text-color;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $white-color;
  cursor: pointer;
}

input[type='range']::-webkit-slider-container {
  background: $white-color-00;
}

input[type='range']:focus::-ms-fill-lower {
  background: $purple-color;
  border-radius: 7px;
  height: 7px;
}

input[type='range']::-moz-range-progress {
  background: $purple-color;
  border-radius: 7px;
  height: 7px;
}

.customSlider {
  .rangeslider-horizontal {
    height: 7px;
    border-radius: 7px;
  }

  .rangeslider,
  .rangeslider .rangeslider__fill {
    box-shadow: none;
  }

  .rangeslider {
    background: $purple-color-light;
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: $purple-color;
  }

  .rangeslider-horizontal .rangeslider__handle::after {
    background-color: $white-color;
    box-shadow: none;
  }

  .rangeslider-horizontal .rangeslider__handle {
    width: 36px;
    height: 36px;
  }

  .rangeslider .rangeslider__handle {
    background: $white-color;
    border: 0 solid $grey-color-label;
    box-shadow: 2px 2px 4px $rose_quartz;
    outline: none;
  }
}
