@import '@src/styles/vars.css';

.AddLink {
  .container {
    margin-top: 45px;
  }

  .LinkUrl {
    margin-top: 25px;
  }

  .customButtonContainer {
    position: absolute;
    bottom: 47px;
  }
}
