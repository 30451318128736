@import "@src/styles/vars.css";

.NotificationsTopContainer {
  $wid-not-container: 340px;

  display: grid;
  grid-template-columns: 640px auto;

  .NotificationsTopContainerRelative {
    position: relative;

    .NotificationsTopContainerAbsolute {
      position: absolute;
      width: 100%;
      top: 80px;
      z-index: 10;

      .NotificationsAnimationContainer {
        height: calc(100vh - 80px);
        width: 100%;
        background: $white-color;
        box-shadow: 0 0 15px rgba(14, 14, 19, 7%);
        border-radius: 50px 0 0;

        .NotificationsContainer {
          width: $wid-not-container;
          height: 100%;
          padding-left: 60px;
          padding-top: 30px;
          display: flex;
          flex-direction: column;

          .NotificationsContainerTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .title {
              font-style: normal;
              font-weight: bold;
              font-size: 24px;
              line-height: 33px;
            }

            .closeBut {
              height: 34px;
              width: 34px;
              background: $grey-light-color;
              border-radius: 10px;
              border: none;
              cursor: pointer;
              padding: 5px;

              svg {
                fill: $grey-color-name;
              }
            }
          }

          .NotificationsContainerContent {
            flex-grow: 1;
            position: relative;

            .NotificationsList {
              position: absolute;
              height: 100%;
              width: 100%;
              overflow: auto;
              scrollbar-width: none;

              &::-webkit-scrollbar {
                width: 0;
              }

              &.no-scroll {
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}
