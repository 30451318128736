@import "@src/styles/vars.css";

a.customTopMenu {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-decoration: none;
  color: $grey-color-dark;
  display: inline-block;
  position: relative;
  margin-right: 60px;
  padding: 10px 0;

  &.selected {
    color: $black-text-color;

    &::after {
      display: block;
      content: " ";
      height: 3px;
      width: 100%;
      background-color: $green-color;
      position: absolute;
      bottom: 0;
    }
  }

  &:not(.selected) {
    &::after {
      display: block;
      content: " ";
      height: 3px;
      width: 0%;
      background-color: $green-color;
      transition: width 0.4s ease-in-out;
      position: absolute;
      bottom: 0;
    }

    &:hover::after,
    &:focus::after {
      width: 100%;
    }
  }
}

.customTopMenuUnClickable {
  cursor: default;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-decoration: none;
  color: $grey-color-dark;
  display: inline-block;
  position: relative;
  margin-right: 65px;

  &.clickable {
    cursor: pointer;
  }

  &.selected {
    color: $black-text-color;

    &::after {
      display: block;
      content: "";
      height: 3px;
      width: 100%;
      background-color: $green-color;
      position: absolute;
      margin-top: 6px;
    }
  }

  &:not(.selected) {
    &::after {
      display: block;
      content: "";
      height: 3px;
      width: 0%;
      background-color: $green-color;
      transition: width 0.4s ease-in-out;
      position: absolute;
      margin-top: 6px;
    }
  }
}

.topMenuContainer {
  display: flex;
  justify-content: space-around;

  a.customTopMenu {
    margin-right: 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
}

.react-toast-notifications__container {
  margin-top: 38px;
  right: -30px !important;
  z-index: 900 !important;
}
