.Switch {
  display: flex;

  .label {
    flex-grow: 1;
    padding-right: 20px;
  }

  .container {
    display: flex;

    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
      margin: 0;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 43px;
      height: 27px;
      background: var(--color-v2-icon3);
      display: block;
      border-radius: 26px;
      position: relative;
    }

    label::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 23px;
      height: 23px;
      background: var(--color-v2-background1);
      transition: 0.3s;
      border: 0.5px solid rgba(0, 0, 0, 4%);
      box-shadow:
        0 3px 8px rgba(0, 0, 0, 15%),
        0 1px 1px rgba(0, 0, 0, 16%),
        0 3px 1px rgba(0, 0, 0, 10%);
      border-radius: 100px;
    }

    input:checked + label {
      background: var(--color-v2-global1);
    }

    input:checked + label::after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }

    label:active::after {
      width: 30px;
    }
  }

  &.disabled {
    opacity: 0.4;

    label {
      cursor: not-allowed;
    }
  }
}
