.ImageSelector {
  &.disabled {
    cursor: not-allowed;
  }

  .placeholder {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-v2-background2);

    svg {
      width: 24px;
      height: 24px;
      fill: var(--color-v2-icon3);
    }
  }
}
