@import '@src/styles/vars.css';

.HeaderLibrary {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin: 30px 0;
  height: 48px;

  .search {
    width: 330px;
    position: relative;

    .iconContainer {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 15px;
      right: 14px;
    }
  }

  .breadcrumbs {
    align-items: center;
    margin-left: initial;
  }

  .link {
    color: $grey-color-2;
  }

  .tales {
    color: $grey-color-2;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 220px;
    position: absolute;
    right: 0;
    z-index: 10;

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      background: #ffffff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
      border-radius: 16px;
      padding: 6px;

      .buttonItem {
        background: #ffffff;
        padding: 12px 16px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.4px;
        color: #000000;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: none;

        .buttonLabelContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 188px;

          .addItemButtonIcon {
            width: 20px;
            height: 20px;
          }
        }
      }

      .buttonDivider {
        border: 0.5px solid #f1f5f6;
        height: 1px;
        width: 100%;
      }
    }
  }
}
