.Component {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  margin-bottom: 10px;
}

.image {
  height: 40px !important;
  width: 40px !important;
  border-radius: 10px;
  margin-right: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  color: var(--color-v2-text3);
  min-width: 0; /* to make .title:text-overflow work with flex */
}

.title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  font-weight: 600;
  font-size: 20px;
  color: var(--color-v2-text1);
}
