@import "@src/styles/vars.css";

.MyCalendar {
  position: relative;
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;

  .particularDay {
    position: relative;

    .signals {
      position: absolute;
      bottom: -9px;
      width: 100%;
      display: flex;
      justify-content: center;

      .greenSig {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        margin: 0 2px;
        background-color: $green-color;
      }

      .purpleSig {
        height: 4px;
        width: 4px;
        border-radius: 50%;
        margin: 0 2px;
        background-color: $purple-color;
      }
    }

    &.blocked {
      color: $grey-blue-color !important;
    }
  }
}
