:root {
  --file-card-width: 180px;
  --file-card-height: 140px;
}

.container {
  width: var(--file-card-width);
  height: var(--file-card-height);
  border-radius: 10px;
  border: 1px solid var(--color-v2-border2);
  box-shadow: 0 4px 15px rgba(var(--color-v2-border2-rgb), 40%);
  padding: 15px;
  background-color: var(--color-v2-background1);
  position: relative;
  overflow: hidden;

  &.image {
    .previewContainer {
      width: auto;
      height: auto;
      margin: auto;
      border-radius: initial;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .dataContainer {
      padding: 0;
    }

    .name {
      color: var(--light-color-v2-text2);
    }

    .moreButton {
      svg {
        fill: var(--light-color-v2-icon2);
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.previewContainer {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 10px;
  background-color: var(--color-v2-background2);

  svg {
    width: 24px;
    height: 24px;
    fill: var(--color-v2-icon3);
  }
}

.previewPlaceholder {
  flex: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background:
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 20%) 0%,
      rgba(0, 0, 0, 50%) 100%
    );
}

.dataContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 15px;
  justify-content: flex-end;
}

.name {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--color-v2-text1);
  max-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: var(--color-v2-text3);
  max-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.moreButton {
  padding: 0;
  background-color: initial;
  position: absolute;
  right: 5px;
  top: 5px;

  svg {
    width: 28px;
    height: 28px;
    fill: var(--color-v2-icon3);
  }
}
