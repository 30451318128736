@import '@src/styles/vars.css';

.spacer {
  flex-grow: 1;
}

.intro {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 86px 200px 40px 200px;
  background: #444951;
  color: var(--color-v2-text2);
  border-radius: 20px;
  clip-path: inset(0 0 round 20px);

  .logo {
    position: absolute;
    top: 0;
    z-index: -1;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
  }

  .description {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }

  .playButton {
    width: 110px;
    height: 110px;
    margin-top: 31px;
    padding: 11px;
    border: none;
    border-radius: 55px;
    background: var(--color-onboarding-onboarding_2);
    cursor: pointer;

    .inner {
      width: 88px;
      height: 88px;
      border-radius: 44px;
      padding-left: 5px;
      background: var(--color-onboarding-onboarding_1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .skipButton {
    background: none;
    border: 1px solid var(--color-v2-background1);
  }
}

.player {
  width: 100%;
  height: 100%;
  background: #444951;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 0 8px 0;

  & > div,
  & > button {
    z-index: 1;
  }

  .videoPlayer {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
  }

  .label {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-v2-text2);
  }

  .nextButton {
    position: absolute;
    top: 28px;
    right: 28px;
    color: var(--color-onboarding-onboarding_1);
    background: none;
    cursor: pointer;
  }

  .spacer {
    flex-grow: 1;
  }

  .subtitle {
    width: 580px;
    color: var(--color-v2-text2);
    padding-bottom: 24px;
    text-align: center;
    pointer-events: none;
  }

  .message {
    background: #444951;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 0;
    padding: 0 300px;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    color: var(--color-v2-text2);
    font-weight: bold;
    font-size: 32px;

    &.animated {
      height: 100%;
      opacity: 1;
      animation: 1s ease-out 2s 1 forwards disappear;
    }

    z-index: 2;

    @keyframes disappear {
      0% {
        opacity: 1;
      }

      99% {
        height: 100%;
      }

      100% {
        opacity: 0;
        height: 0;
      }
    }
  }
}

.final {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 218px 200px 40px 200px;
  background: #444951;
  color: var(--color-v2-text2);
  border-radius: 20px;
  clip-path: inset(0 0 round 20px);

  .title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  .description {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }

  .buttons {
    & button {
      width: 280px;
      background: #666976;
      border: none;

      &.inverted {
        background: none;
        border: 1px solid var(--color-v2-background1);
        margin-right: 20px;
      }
    }
  }
}
