.Component {
  iframe {
    min-width: 70vw;
    min-height: 70vh;
    border: 0;
  }
}

.loadingSpinnerContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color-v2-global4-rgb), 80%);
}

.modalBody.modalBody {
  padding: 0;
}
