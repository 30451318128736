@import "@src/styles/vars.css";

.listFolderSelect {
  &.mb100 {
    margin-bottom: 100px;
  }

  .SystemFolder,
  .UserFolder,
  .UserFile {
    display: inline-block;
    width: 180px;
    height: 160px;
    position: relative;
    background: $grey-light-color;
    border-radius: 15px;
    margin: 10px;
    cursor: pointer;

    .SystemFolderContainer,
    .UserFolderContainer,
    .UserFileContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .checkItem {
        position: absolute;
        height: 17px;
        width: 17px;
        border-radius: 50%;
        border: 1.1594px solid $green-grey-dark-color;
        top: 14px;
        left: 14px;

        &.active {
          background: $green-blue-color-text;

          .checkedContainer {
            position: absolute;
            height: 17px;
            width: 17px;
            top: -1px;
            left: -1px;

            svg {
              fill: $white-color;
            }
          }
        }
      }
    }
  }

  .SystemFolder {
    .SystemFolderContainer {
      .iconFolderContainer {
        height: 48px;
        width: 48px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        .iconFolder {
          position: absolute;
        }
      }

      .folderName {
        position: absolute;
        bottom: 45px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        span {
          text-decoration: none;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 130px;
          display: block;
          margin: auto;
        }
      }
    }
  }

  .UserFolder {
    .UserFolderContainer {
      .iconFolderContainer {
        height: 48px;
        width: 48px;
        margin-bottom: 40px;
      }

      .folderName {
        position: absolute;
        bottom: 45px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        span {
          text-decoration: none;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 130px;
          display: block;
          margin: auto;
        }
      }
    }
  }

  .UserFile {
    .UserFileContainer {
      .imagePreviewContainer,
      .documentIcon,
      .documentIconUrl {
        height: 48px;
        width: 48px;
        margin-bottom: 40px;
      }

      .imagePreviewContainer {
        overflow: hidden;
        border-radius: 10px;

        img {
          width: 100%;
          display: block;
          height: 100%;
          object-fit: cover;
        }
      }

      .documentName {
        position: absolute;
        bottom: 45px;
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 130px;
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }

      .documentSize {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $grey-color-dark;
        position: absolute;
        bottom: 19px;
        text-align: center;
      }

      .dataOpenDocument {
        position: absolute;
        bottom: 19px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        padding: 3px 10px;
        background: $purple-color;
        border-radius: 16px;
        color: $white-color;
      }
    }
  }
}
