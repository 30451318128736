.Button {
  height: 48px;
  padding: 0 28px;
  background: var(--color-v2-button-fill2);
  color: var(--light-color-v2-text2);
  border: none;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }
}
