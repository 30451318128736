@import "@src/styles/vars.css";

.SeeProgram {
  display: grid;
  grid-template-columns: 280px auto;
  gap: 60px;

  .greyColumn {
    background: $grey-light-color;
    border-top-left-radius: 50px;
    padding: 30px 30px 30px 60px;
  }

  .greyInMobile {
    padding-top: 20px;
  }

  .headerProgram {
    display: grid;
    grid-template-columns: auto 180px;
    white-space: pre-line;

    .headerProgramLeft {
      min-height: 300px;

      .paymentPlan {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 43px;
        margin-top: 14px;
        color: $green-blue-color-text;
      }

      .paymentPlanOptionContainer {
        display: flex;
        flex-wrap: wrap;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;

        .paymentPlanOption {
          display: flex;
          margin-top: 19px;
          margin-right: 40px;
          margin-bottom: 15px;

          span {
            margin-left: 14px;
          }

          .coachStatus,
          .coachPrice {
            font-size: 15px;
            margin-left: 8px;
            color: var(--color-v2-text3);

            &.approved {
              color: var(--color-v2-text4);
            }

            &.rejected {
              color: var(--color-v2-error);
            }
          }

          .paymentPlanIcon {
            width: 22px;
            height: 22px;

            .calendarIcon {
              svg {
                fill: $green-blue-color-text;
              }
            }
          }

          .programTypeIcon {
            width: 22px;
            height: 22px;

            svg {
              fill: $green-blue-color-text;
            }
          }
        }

        .coachAvatarContainer {
          height: 28px;
          width: 28px;
        }
      }

      .programDescription {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
        margin-top: 30px;
      }
    }
  }

  .menu {
    .menuItem {
      cursor: pointer;
      margin-right: 45px;

      .menuItemContent {
        display: flex;
        align-items: center;

        .counter {
          margin-left: 8px;
          background: $alert-color;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-indent: -1px;
          color: $white-color;
        }
      }
    }
  }

  .ImageProgramContainer {
    height: 280px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;

    .ImageProgramAbsContainer {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .status {
        position: absolute;
        top: 20px;
        left: 19px;
        padding: 3px 6px;
        background: rgba(var(--light-color-v2-global3-rgb), 40%);
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: var(--light-color-v2-text2);
        cursor: pointer;

        &.active {
          background: var(--light-color-v2-global1);
        }
      }

      .OverlayImage {
        position: absolute;
        width: 100%;
        height: 100%;
        background:
          linear-gradient(
            0deg,
            rgba(0, 0, 0, 40%),
            rgba(0, 0, 0, 40%)
          );
        top: 0;
      }
    }
  }

  .CoachRightMenu {
    margin-top: 30px;

    .buttonContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;

      .sharedButton {
        width: 100%;
        margin-top: 12px;
        background: #f1f5f6;
        border-radius: 10px;
        margin-right: 30px;
        padding: 14px 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
      }
    }

    .publishProgramBut {
      background: $green-blue-color-text;
      border-radius: 10px;
      width: 100%;
      display: flex;
      padding: 14px 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-top: 12px;

      .publishProgramButTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $white-color;
      }

      .publishProgramButTitleBlack {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }

      .publishProgramButIco {
        height: 20px;
        width: 20px;
      }

      .editProgramButIco {
        height: 20px;
        width: 20px;

        svg {
          path {
            stroke: white;
            fill: white;
          }
        }
      }

      .editProgramButIcoBlack {
        height: 20px;
        width: 20px;
      }
    }

    .mb30 {
      margin-bottom: 30px;
    }

    .mt12 {
      margin-top: 12px;
    }

    .editProgramBut {
      background: $grey-light-color;
      border-radius: 10px;
      width: 100%;
      display: flex;
      padding: 14px 20px;
      justify-content: space-between;
      cursor: pointer;

      .editProgramButTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }

      .editProgramButIco {
        height: 16px;
        width: 16px;
      }
    }

    .switchBox {
      display: flex;
      align-items: center;

      .switchBoxText {
        margin-right: 50px;

        .switchBoxTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }

        .switchBoxDescription {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.24px;
          color: $grey-color-name;
        }
      }
    }

    .hr {
      border: 1px solid $blue-grey-light-color;
      margin: 20px 0;
    }
  }

  .joinNowMainBut {
    margin-top: 30px;
    background: $green-blue-color-text;
    border-radius: 10px;
    width: 100%;
    display: flex;
    padding: 14px 20px;
    justify-content: space-between;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $white-color;

    svg {
      width: 24px;
      height: 24px;
      fill: rgba(var(--light-color-v2-icon2-rgb), 80%);
    }
  }
}

.h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-left: 15px;
}

.greenBack {
  background: $green-blue-color-text !important;

  &:disabled {
    background: $grey-color-label !important;
  }
}

.createSquadIcon {
  display: flex;
  height: 17px;
  width: 17px;
  background: $white-color-opacity;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  color: $white-color;
  font-size: 14px;
  padding-bottom: 2px;
}

.prolongButton {
  width: 104px;
  height: 32px;
  margin-left: 10px;
  padding: 8px 30px;
  background-color: #25d2a2;
  border-color: #25d2a2;
  border-radius: 40px;
  font-size: 12px;
}

.SquadsTab {
  margin-top: 30px;

    .text {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      font-family: 'Open Sans', sans-serif;
    }
}

.archivedCourses {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin-bottom: 15px;
  margin-top: 20px;
}

.ProgramClientCoursesListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: $white-color;
  box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
  border-radius: 10px;
  padding: 25px 20px;
  margin-bottom: 10px;

  .iconCalendarContainer {
    height: 22px;
    width: 22px;
    margin-right: 20px;
  }

  .ProgramClientCoursesListItemContainer {
    display: flex;
    align-items: center;

    .iconCalendar {
      height: 100%;
      width: 100%;
    }
  }

  .contentContainer {
    display: flex;
    align-items: center;

    .content {
      display: flex;
      align-items: center;

      .date {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
      }

      .secondRow {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $green-blue-color-text;
      }
    }
  }
}

.ProgramCoursesListItem {
  background: $white-color;
  box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
  border-radius: 10px;
  padding: 15px 25px;
  margin-bottom: 10px;
  cursor: pointer;

  .programCoursesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .contentContainerLeft {
      display: flex;
      align-items: center;

      .iconCalendarContainer {
        height: 22px;
        width: 22px;
        margin-right: 20px;

        .iconCalendar {
          height: 100%;
          width: 100%;
        }
      }

      .durationContainer {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }

      .singleUserContainer {
        display: flex;
        align-items: center;
        margin-left: 40px;

        .singleUserAvatar {
          height: 24px;
          width: 24px;
          flex-shrink: 1;
        }

        .courseClient {
          margin-left: 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .contentContainerRight {
      display: flex;
      align-items: center;

      .buttContainer {
        margin-right: 10px;

        .startBut,
        .stopBut,
        .deleteBut,
        .oldConditionsContainer {
          border-radius: 10px;
          width: 130px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: $white-color;
          background: $grey-color-dark;
        }

        .startBut {
          background: $green-blue-color-text;
        }

        .stopBut {
          background: $periwinkle-crayola-4;
        }

        .deleteBut {
          background: $red-notify-color;
        }
      }

      .status {
        display: flex;
        flex-direction: row;
        align-items: center;

        .prolongButton {
          background-color: #25d2a2;
          border-color: #25d2a2;
        }

        .groupContainer {
          display: flex;

          .iconGroupSmall {
            height: 20px;
            width: 20px;
            margin-right: 10px;

            svg {
              path {
                stroke: $green-blue-color-text;
              }
            }
          }

          .courseSize {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $green-blue-color-text;
          }
        }

        .courseStatus {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $grey-color-dark;
          margin-right: 10px;
        }
      }

      .iconNextContainer {
        height: 20px;
        width: 14px;
        margin-left: 20px;
      }
    }
  }

  .oldConditionsContainer {
    margin-top: 10px;
    position: relative;
    padding-left: 15px;

    &::after {
      content: " ";
      position: absolute;
      left: 3px;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: var(--color-v2-global2);
      border-radius: 4px;
    }

    .oldConditionsDescription {
      font-size: 14px;
      line-height: 20px;
      color: var(--color-v2-text3);
      margin-bottom: 10px;
    }

    .upgradeBut {
      border-radius: 10px;
      width: 130px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: var(--light-color-v2-text2);
      background: var(--light-color-v2-global2);
    }
  }
}

.iconNoResult {
  fill: $grey-color-dark;
}

.SelectCourse {
  height: 100%;
  display: flex;
  flex-direction: column;

  .mainSectionContainer {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .practiceContainer {
      .practiceHeader {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        margin: 5px 5px 5px 0;
        cursor: pointer;

        .practiceImage {
          height: 40px;
          width: 50px;
          overflow: hidden;
          border-radius: 10px;
          margin-right: 10px;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .practiceName {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 80%;
        }
      }

      .coursesContainer {
        transition: max-height 0.5s;
        overflow: hidden;
        max-height: 0;

        &.coursesContainerOpen {
          max-height: 9999px;
        }

        .courseClickableContainer {
          display: grid;
          grid-template-columns: 30px auto;
          align-items: center;
          background: $grey-light-color;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid $grey-light-color;
          cursor: pointer;

          &.selected {
            border: 1px solid $green-blue-color-text;
          }

          .radioButContainer {
            height: 18px;
            width: 18px;
            margin: 15px 15px 15px 5px;
            cursor: pointer;

            .radioBut {
              height: 100%;
              width: 100%;
              border: 1px solid $grey-color-border;
              border-radius: 50%;
              position: relative;

              &.selected {
                &::before {
                  content: "";
                  display: block;
                  background: $green-blue-color-text;
                  height: 50%;
                  width: 50%;
                  position: absolute;
                  border-radius: 50%;
                  margin: 25%;
                  left: -0.2px;
                  top: 0.1px;
                }
              }
            }
          }

          .dataContainer {
            .firstContainer {
              display: flex;
              align-items: center;
              padding-bottom: 5px;

              .iconCalendarContainer {
                height: 20px;
                width: 20px;
                flex-shrink: 0;
              }

              .durationContainer {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin-left: 10px;
              }

              .singleUserContainer {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-left: 10px;

                .singleUserAvatar {
                  width: 40px;
                  height: 40px;
                  flex-shrink: 1;
                }

                .courseClient {
                  display: block;
                }
              }
            }

            .secondContainer {
              display: flex;
              align-items: center;

              .courseStatus {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: $grey-color-dark;
              }

              .groupContainer {
                display: flex;
                align-items: center;

                .iconGroupSmall {
                  height: 18px;
                  width: 18px;
                  margin-right: 10px;
                }

                .courseSize {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  color: $grey-color-dark;
                }
              }
            }
          }
        }
      }
    }
  }

  .buttonContainer {
    margin-top: 10px;
  }
}

.InvitesTab {
  display: block;
  position: relative;

  .invitesContainer {
    display: block;
    padding-top: 25px;

    .inviteContainer {
      padding: 15px 0;
      border-bottom: 1px solid $blue-grey-light-color;

      &:last-child {
        border-bottom: none;
      }

      .contentContainer {
        display: flex;

        .userAvatar {
          width: 40px;
          height: 40px;
          margin-right: 30px;

          .IconContainer {
            height: 100%;
            width: 100%;
            position: relative;

            .Icon {
              height: 18px;
              width: 18px;
              position: absolute;
              bottom: -2px;
              right: 2px;
            }
          }
        }

        .content {
          display: flex;
          align-items: center;
          flex-grow: 1;

          .userName {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.4675px;
            flex-grow: 1;
          }

          .date {
            display: flex;
            align-items: center;
            font-weight: normal;
            min-width: 230px;
            font-size: 13px;
            line-height: 18px;
            padding-left: 25px;
            height: 100%;
            border-left: 1px solid rgba(178, 187, 198, 20%);
            letter-spacing: -0.08px;
            color: $grey-color-text;

            .calendarContainerIcon {
              width: 16px;
              height: 16px;
              margin-right: 10px;
              font-style: normal;

              svg {
                path {
                  fill: $grey-color-text;
                }
              }
            }
          }
        }

        .moreButt {
          color: $grey-color-dark;
          font-size: 26px;
          cursor: pointer;
        }
      }

      .messageContainer {
        background: $white-color;
        box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
        border-radius: 10px;
        padding: 10px 25px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}

.inviteContainer {
  height: 100%;
}

.CreateCourse {
  min-height: 320px;
  display: flex;
  flex-direction: column;
  padding: 5px 20px 15px;

  .sectionContent {
    flex-grow: 1;

    .name {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4675px;
    }

    .container {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .skipGoalsContainer {
        display: flex;
        align-items: center;
        cursor: pointer;

        .checkbox {
          height: 18px;
          width: 18px;
          border: 1px solid $purple-color;
          box-sizing: border-box;
          border-radius: 5px;
          margin-right: 8px;

          &.isChecked {
            background: $purple-color;
          }
        }

        .skipGoalsText {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 18px;
          text-align: right;
          letter-spacing: -0.41px;
          color: $purple-color;
        }
      }
    }

    .noResult {
      min-height: inherit;
      padding: 30px;

      .iconNoNameImgContainer {
        width: initial;
        height: initial;

        .iconNoResultContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: $grey-color-dark;

          .iconNoResultContainerDescription {
            display: block;
          }

          .iconNoResult {
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    .goalsTypeContainer {
      padding-top: 20px;

      .goalsTypeLabelContainer {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding-bottom: 20px;

        .goalsSource {
          background:
            linear-gradient(
              0deg,
              $grey-light-color,
              $grey-light-color
            );
          border-radius: 10px;
          padding: 12px;
          border: 1px solid $grey-light-color;
          text-align: center;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 18px;
          cursor: pointer;
          letter-spacing: -0.41px;

          .goalsSourceText {
            display: block;
          }

          &.isSelected {
            cursor: default;
            border: 1px solid $purple-color;
            background: initial;
            color: $purple-color;

            .goalsSourceText {
              display: block;
            }
          }
        }
      }
    }
  }

  .actionButtonsContainer {
    display: block;

    .butBottContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding-top: 20px;

      .subActionButton {
        background: $green-blue-color-text;
      }

      .saveBut {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          padding-left: 3px;
        }
      }
    }

    .nextButContainer {
      display: flex;
      justify-content: flex-end;

      .saveButNext {
        width: 93px;
      }
    }
  }
}

.PreviousGoalsListItem {
  background: $white-color;
  border: 1px solid $grey-color-2;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;

  .previousGoalContentWrapper {
    display: flex;
    justify-content: space-between;

    .previousGoalContentContainer {
      display: block;

      .flex1 {
        display: block;

        .goalIndex {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.41px;
          color: $moderate-slate-blue-light;
        }

        .goalTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: -0.41px;
          color: $purple-color;
        }
      }
    }

    .menu {
      display: flex;
      align-items: center;

      .deleteGoalIconContainer {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }

      .moreBut {
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    color: $moderate-slate-blue-light;
    margin-top: 10px;
  }

  .shortTermGoals {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    padding-top: 18px;
    padding-bottom: 18px;
    letter-spacing: -0.4675px;
  }
}

.DescriptionTab {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  white-space: pre-line;
}

.declineButton {
  background-color: var(--color-v2-error) !important;
}

.messageContainer {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: var(--color-v2-text3);

  p {
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 4px;
      border-radius: 4px;
      background-color: var(--color-v2-global1);
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
}
