@import "../../../../styles/vars.css";

.MaterialsListItem {
  position: relative;
  max-width: 180px;
  min-width: 162px;
  flex-grow: 1;

  &::before {
    content: "";
    display: block;
    padding-top: 80%;
  }

  .container {
    position: absolute;
    border-radius: 15px;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    line-height: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .lockedOverlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background:
        linear-gradient(
          0deg,
          rgba(124, 122, 122, 75.3%),
          rgba(168, 167, 167, 73.3%)
        );
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lockedContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.192px;
        color: $white-color;

        .lockedImageContainer {
          height: 17px;
          width: 17px;

          svg {
            fill: $white-color;
          }
        }

        .date {
          display: block;
        }
      }

      .moreButtonContainer {
        color: $white-color;
        cursor: pointer;
        padding-top: 10px;
        font-size: x-large;
      }
    }
  }
}
