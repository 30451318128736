@import "../../styles/vars.css";

.NoResults {
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .imgContainer {
    width: 60px;
    height: 60px;
    margin: auto;
    margin-bottom: 10px;
  }

  .textContainer {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: $grey-color-dark;
  }
}
