@import "../../../styles/vars.css";

.prevLine {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  margin: 5px 0;
  background: $blue-dark-color;
  padding: 5px;
  border-radius: 6px;

  .imagePreview {
    display: inline-block;
    max-width: 60px;
    max-height: 60px;
    margin: 2px;
    padding: 5px;
    margin-left: 8px;
    border-radius: 10px;
  }

  .fileName {
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 10px;
  }

  .spacer {
    -webkit-box-flex: 1;
    flex: 1;
  }

  .button {
    margin-right: 15px;
    padding: 0.35em 1em;
    font-weight: lighter;
  }

  .butUpload {
    padding: 0.35em 0.5em !important;
    margin-right: 10px;
  }
}
