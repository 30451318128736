@import '@src/styles/vars.css';

.UserInfoContainer {
  min-height: 612px;
  display: flex;
  flex-direction: column;
}

.divider {
  border: 1px solid #f1f5f6;
  margin: 20px 0;
}

.InfoAbout {
  margin-top: 53px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.InfoColumnContainer {
  display: flex;
  flex-direction: column;
}

.InfoRowContainer {
  display: flex;
  margin-bottom: 16px;
}

.HeaderInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: #919ba8;
  margin-bottom: 4px;
}

.Info {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.41px;
  color: #000;
}

.MarginRight {
  width: 70px;
  margin-right: 182px;
}

.HeaderReason {
  color: #000;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 7px;
}

.ReasonInfo {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #919ba8;
  letter-spacing: -0.41px;
}

.customScroll::-webkit-scrollbar {
  width: 4px;
  height: 56px;
}

.customScroll::-webkit-scrollbar-thumb {
  background: #25d2a2;
  border-radius: 2px;
}

.customScroll::-webkit-scrollbar-track {
  background: #f1f5f6;
  border-radius: 2px;
}

.Question {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.Answer {
  color: #919ba8;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.41px;
  margin-bottom: 16px;
}

.Squad {
  display: grid;
  grid-template-columns: auto;

  .greyColumn {
    background: $grey-light-color;
    border-top-left-radius: 50px;
  }
}

.Course {
  margin-top: 40px;

  .pl60 {
    padding-left: 60px;
    margin-top: 25px;
  }

  .headerContainer {
    display: grid;
    grid-template-columns: auto 380px;
    align-items: center;

    .headerContent {
      display: grid;
      grid-template-columns: auto 300px;
      align-items: center;

      .headerTop {
        .headerTopTitle {
          display: flex;
          align-items: center;

          h2 {
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 49px;
            margin-right: 15px;
          }

          .courseStatus {
            .statusContainer {
              background: $green-blue-color-text;
              border-radius: 4px;
              padding: 4px 12px;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              letter-spacing: -0.41px;
              color: $white-color;
            }
            .statusInnerContainer {
              display: flex;
            }
          }

          .headerDescriptionPriceContainer {
            display: flex;
            align-items: center;

            .headerDescriptionPriceIcon {
              height: 20px;
              width: 20px;
              margin-left: 15px;
            }

            .headerDescriptionPriceText {
              margin-left: 5px;
            }
          }

          .singleUserContainer {
            display: flex;
            align-items: center;
            margin-left: 15px;

            .singleUserAvatar {
              height: 24px;
              width: 24px;
              flex-shrink: 1;
            }

            .courseClient {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              margin-left: 10px;
            }
          }
        }

        .headerDescription {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .descriptionProgramContainer {
            flex-grow: 1;
            display: flex;
            margin-right: 20px;
            align-items: center;

            .programImage {
              height: 38px;
              width: 38px;
              margin-right: 10px;
            }

            .programTitleContainer {
              .label {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.41px;
                color: $grey-color-name;
              }

              .programName {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                letter-spacing: -0.41px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .descriptionCourseContainer {
            display: flex;
            margin-right: 40px;
            align-items: center;
            width: 210px;

            .courseImage {
              height: 32px;
              width: 32px;
              background: linear-gradient(
                0deg,
                $grey-light-color,
                $grey-light-color
              );
              border-radius: 50%;
              padding: 5px;
              margin-right: 5px;

              .calendarButt {
                width: 100%;
                height: 100%;
              }
            }

            .courseTitleContainer {
              .label {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.41px;
                color: $grey-color-name;
              }

              .courseName {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                letter-spacing: -0.41px;
              }
            }
          }
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;

    .goToSquad {
      background: $green-blue-color-text;
      border-radius: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 8px 23px;
      cursor: pointer;
      text-align: center;
      color: $white-color;
    }
  }

  .headerDescription {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .headerDescriptionCalendarContainer {
      display: flex;
      align-items: center;

      .headerDescriptionCalendarIcon {
        height: 20px;
        width: 20px;
      }

      .headerDescriptionCalendarText {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        margin-left: 13px;
      }
    }

    .headerDescriptionPriceContainer {
      display: flex;
      align-items: center;

      .headerDescriptionPriceIcon {
        height: 20px;
        width: 20px;
        margin-left: 40px;
      }

      .headerDescriptionPriceText {
        margin-left: 18px;
      }
    }

    .singleUserContainer {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .singleUserAvatar {
        height: 24px;
        width: 24px;
        flex-shrink: 1;
      }

      .courseClient {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-left: 10px;
      }
    }
  }

  .buttonsContainer {
    margin-bottom: 20px;

    .startBut,
    .inviteBut,
    .stopBut,
    .deleteBut,
    .courseMessageActionButton,
    .oldConditionsContainer {
      border-radius: 10px;
      width: 130px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      cursor: pointer;
      color: $white-color;
      background: $grey-color-dark;
    }

    .inviteBut {
      margin-left: 15px;
      background: $grey-color-3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 10px;

      .iconContainer {
        height: 17px;
        width: 17px;
      }
    }

    .startBut {
      border-radius: 19px;
      background: $green-blue-color-text;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      width: 144px;
      height: 40px;
    }

    .stopBut {
      border-radius: 19px;
      background: $periwinkle-crayola-4;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      width: 144px;
      height: 40px;
    }

    .deleteBut {
      border-radius: 19px;
      background: $red-notify-color;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      width: 144px;
      height: 40px;
    }

    .bottomButtonsContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 144px;
      margin-top: 25px;

      .but {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        cursor: pointer;
        padding: 10px;
      }

      .channelButton {
        background: $periwinkle-crayola-2;

        svg {
          fill: $periwinkle-crayola-4;
        }
      }

      .calendarButton {
        background: $mint-green-color;

        svg {
          fill: $green-color-light;
        }
      }

      .libraryButton {
        background: $moderate-slate-blue-light-2;

        svg {
          fill: $moderate-slate-blue-light;
        }
      }
    }
  }

  .courseMessage {
    border: 1px solid $green-blue-color-text;
    border-radius: 10px;
    padding: 10px;
  }

  .courseMessageActionButton {
    background: $purple-color;
  }

  .menuItem {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    margin-right: 50px;
    cursor: pointer;
    color: $grey-color-dark;

    .menuItemContainer {
      display: flex;

      .iconContainer {
        height: 19px;
        width: 16px;
        position: relative;

        .iconContent {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 3px;
          left: -2px;

          svg {
            fill: $grey-color-dark;
          }
        }
      }
    }

    &.selected {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      color: $purple-color;

      .menuItemContainer {
        .iconContainer {
          .iconContent {
            svg {
              fill: $purple-color;
            }
          }
        }
      }
    }
  }

  .first {
    border: 0.7px solid rgba(178, 187, 198, 20%);
  }

  .iconNoResultContainer {
    width: 60px;
    height: 60px;

    .iconNoResult {
      fill: $grey-color-dark;
    }
  }

  .freezeUserIconContainer {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: linear-gradient(180deg, $purple-color 0%, $ghostly-white 100%);
    box-shadow: 0 1px 4px rgba(47, 36, 83, 32%);
    transform: matrix(1, 0, 0, -1, 0, 0);

    .freezeUserIcon {
      height: 15px;
      width: 15px;
      margin: auto;
    }
  }

  .userContainerLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    cursor: pointer;

    &:first-child {
      margin-top: 25px;
    }

    &:not(:first-child) {
      border-top: 1px solid $blue-grey-light-color;
    }

    .frozenBy {
      margin-left: 20px;
      font-size: 12px;
      line-height: 20px;
      color: $grey-color-dark;
    }

    .userContainer {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .userAvatar {
        height: 40px;
        width: 40px;
      }

      .userAvatarFreeze {
        opacity: 0.3;
      }

      .userName {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-left: 20px;

        &.userNameFreeze {
          opacity: 0.3;
        }
      }
    }

    .actionButtonsWrapper {
      display: flex;
      align-items: center;
    }

    .prolongButton {
      width: 104px;
      height: 32px;
      margin-left: 10px;
      padding: 8px 30px;
      background-color: #25d2a2;
      border-color: #25d2a2;
      border-radius: 40px;
      font-size: 12px;
    }

    .stopButton {
      width: 104px;
      height: 32px;
      margin: 0 10px;
      padding: 8px 30px;
      background-color: #fa5353;
      border-color: #fa5353;
      border-radius: 40px;
      font-size: 12px;
    }

    .dotMenu {
      color: $grey-color-dark;
      font-size: 30px;
    }
  }
}

.noClickable {
  cursor: default !important;
}

.coverageStatus {
  margin-left: 12px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffa06a;
  border-radius: 4px;
  color: #fff;
  font-family: 'SF UI Text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.41px;
}

.CoursePaymentsTab {
  margin-top: 30px;
  margin-bottom: 100px;

  .CoursePaymentsListItem {
    background: $white-color;
    box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 15px 30px 15px 20px;
    cursor: pointer;

    .userContainer {
      width: 38%;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(178, 187, 198, 20%);

      .userAvatar {
        height: 40px;
        width: 40px;
      }

      .userName {
        padding-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .rightContainer {
      flex-grow: 1;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .datePayment {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $blue-grey-light-color-2;
      }

      .amountPaymentContainer {
        text-align: right;

        .amountPayment {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $green-blue-color-text;
        }

        .cardPayment {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $blue-grey-light-color-2;
        }
      }
    }

    .buttonsContainer {
      padding-left: 15px;
      display: block;

      .butContainer {
        .butMessContainer {
          max-width: 220px;
          margin-left: 20px;
          font-size: 12px;
          line-height: 20px;
          color: $blue-grey-light-color-2;
          margin-bottom: 5px;
          margin-top: 5px;
        }

        .deleteBut,
        .startBut {
          margin: auto;
        }
      }
    }
  }
}

.CourseMembersListItem {
  .userContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: $white-color;
    box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 10px;

    .userAvatar {
      height: 40px;
      width: 40px;
      margin-right: 15px;
    }

    .userName {
      width: 40%;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    .date {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: $blue-grey-light-color-2;
      border-left: 1px solid rgba(178, 187, 198, 20%);
      padding-left: 20px;
    }
  }
}

.CourseGoalsTab {
  .noMainGoalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12vh;

    .imageContainer {
      height: 140px;
      width: 140px;
      padding: 40px;
      border-radius: 50%;
      background: $moderate-slate-blue-light-2;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      max-width: 165px;
      margin-top: 15px;
      margin-bottom: 25px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.41px;
      color: $moderate-slate-blue-light;
    }

    .butAddGoal {
      background: $purple-color;
      border-radius: 10px;
      padding: 18px 50px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      cursor: pointer;
      color: $white-color;
    }
  }

  .courseGoalsListContainer {
    .listGoalAddGoal {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 20px;

      .iconPlusBut {
        background: $grey-color-dark;
        opacity: 0.4;
        width: 17px;
        height: 17px;
        border-radius: 5px;
        margin-right: 12px;

        p {
          line-height: 14px;
          text-align: center;
        }
      }

      .iconPlusButLabel {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: $grey-color-dark;
      }
    }

    .courseGoalsListItemContainer {
      padding-top: 20px;
    }
  }
}

.courseGoalsListContainer {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .firstColumnContainerInChat {
    width: 100%;
  }

  .firstColumnContainer {
    width: 560px;
  }

  .selectRightGoalContainerRateGoal {
    width: 620px;
    margin-left: 20px;
  }
}

.CourseClientsTab {
  width: 570px;
}

.goalSection {
  background: $white-color;
  box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid $white-color;

  &.isActive {
    border: 1px solid $purple-color;
  }

  .goalTitleContainer {
    .goalTitleContainerHeader {
      display: flex;
      align-items: center;

      .titleSection {
        flex-grow: 1;

        .goalLabel {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.41px;
          color: $moderate-slate-blue-light;
        }

        .goalTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: -0.41px;
          color: $purple-color;
        }
      }

      .moreBut {
        font-size: 20px;
        padding-left: 5px;
        margin-left: 10px;
        color: $moderate-slate-blue-light;
        cursor: pointer;
      }
    }

    .goalDescription {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      padding-top: 9px;
      letter-spacing: -0.08px;
      color: $moderate-slate-blue-light;
    }

    .showResultsButton {
      -webkit-touch-callout: none;
      user-select: none;
      background: $moderate-slate-blue-light-2;
      border-radius: 6px;
      cursor: pointer;
      margin-top: 10px;
      padding: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: $purple-color;

      &.isActive {
        color: $moderate-slate-blue-light;
      }
    }
  }

  .subgoalsHeaderContainerStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    padding-top: 20px;

    .subgoalsHeaderLabel {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    .subgoalsHeaderButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      border: 2px #559dee dashed;
      border-radius: 12px;
      cursor: pointer;
    }

    .subgoalsHeaderButtonLabel {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $purple-color;
    }
  }

  .addSubGoalButtonStyle {
    margin-top: 10px;
    border: 1px dashed $moderate-slate-blue-light;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    padding: 20px;
    text-align: center;
    color: $purple-color;
    cursor: pointer;
  }
}

.NotesButtonContainer {
  .NotesButton {
    background: $grey-color-1;
    border-radius: 22.5px;
    padding: 9px 19px;
    border: none;
    color: $grey-color-text;
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
    display: flex;
    align-items: center;

    .EditIcon {
      width: 16px;
      fill: #98a0c0;
      vertical-align: middle;
      margin-right: 12px;
    }
  }
}

.ScrollableArea {
  height: calc(65vh - 24px);
  padding-right: 6px;
  margin: 12px 0;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f5f6;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25d2a2;
    border-radius: 2px;
  }
}
