@import "../../../../styles/vars.css";

.QuestionnairePostAttachment {
  width: 240px;
  background: var(--light-color-v2-background1);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 5px;
  cursor: pointer;

  .contentContainer {
    padding: 0 16px;
  }

  .descriptionContainer {
    flex-direction: row;
    margin-bottom: 16px;
    align-items: center;
  }

  .name {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--light-color-v2-text1);
  }

  .description {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.24px;
    color: var(--light-color-v2-text1);
  }

  .status {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.41px;
    padding-left: 10px;
  }

  .avatarContainer {
    flex: 1;
    height: 120px;
    margin-bottom: 15px;
  }

  .avatar {
    flex: 1;
    height: 120px;
    width: 240px;
  }
}
