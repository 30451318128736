@import '@src/styles/vars.css';

.List {
  width: 100%;
  list-style: none;

  .trackY {
    width: 4px;

    .thumbY {
      background: var(--color-v2-button-fill2);
    }
  }
}
