@import "@src/styles/vars.css";

.Toast {
  position: relative;
  margin-top: 32px;

  .container {
    background: $white-color;
    box-shadow:
      0 0 15px rgba(66, 56, 181, 7%),
      0 4px 10px rgba(53, 197, 163, 10%);
    border-radius: 25px 0 0 25px;
    padding: 23px 130px 23px 27px;
    display: flex;
    align-items: center;

    &.ToastRed {
      background: $rich-pink;
    }

    &.ToastGreen {
      background: $pale-blue;
    }

    &.ToastOrange {
      background: $goldenrod-crayola;
    }

    .contentContainer {
      min-width: 220px;
      max-width: 320px;
    }

    .closeButContainer {
      margin-left: 15px;
      height: 34px;
      width: 34px;
      background: $grey-light-color;
      border-radius: 10px;
      border: none;
      cursor: pointer;
      padding: 5px;
    }
  }
}
