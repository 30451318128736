@import "@src/styles/vars.css";

.scrollbar {
  &.trackYVisible {
    .wrapper {
      inset: 0 10px 0 0;
    }

    .trackY {
      height: 100% !important;
      top: 0 !important;
      background: $grideperlevy !important;
    }

    .thumbY {
      background: $light-turquoise !important;
      border-radius: 11px !important;
    }

    &.little {
      .wrapper {
        inset: 0 4px 0 0 !important;
      }

      .trackY {
        width: 4px !important;
      }
    }
  }
}
