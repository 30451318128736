@import '@src/styles/vars.css';

.Chats {
  display: grid;
  grid-template-columns: 440px auto;
  padding-left: 95px;
}

.ChatsListContainerMenu {
  margin: 20px 60px 20px 0;
  display: flex;
  align-items: center;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  .menuSearchContainer {
    flex-grow: 1;

    .searchContainer {
      padding: inherit;
      background: initial;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .menuButContainer {
    margin-left: 20px;

    .addChatBut {
      background: $purple-color;
      border-radius: 10px;
      padding: 11px;
      cursor: pointer;

      .IconAddChatContainer {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.CreateChannel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .createChannelSearchContainer {
    margin-bottom: 10px;

    .searchContainer {
      padding: inherit;
      background: initial;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .userListContainer {
    flex-grow: 1;
    max-height: calc(90vh - 73px - 66px - 66px);
    min-height: 100px;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .userListItemContainer {
      display: flex;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;

      .userCheckboxContainer {
        margin-right: 15px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid $blue-grey-light-color-1;
        flex-shrink: 0;

        &.selected {
          border: 1px solid $green-blue-color-text;
          background: $green-blue-color-text;
          position: relative;

          svg {
            fill: $white-color;
            position: absolute;
            left: -1px;
            top: 1px;
          }
        }
      }

      .userAvatarContainer {
        height: 50px;
        width: 50px;
        margin-right: 15px;
        flex-shrink: 0;
      }

      .userNameContainer {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.ChatsListContainer {
  display: flex;
  flex-direction: column;
  z-index: 1000;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  .toggleTypeListContainer {
    background: $grideperlevy;
    border-radius: 10px;
    margin-right: 60px;
    padding: 6px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-bottom: 40px;

    .toggleTypeListItem {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      padding: 7px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.4675px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: $grey-color-name;

      span {
        text-align: center;
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }

      .listItemNumMess {
        margin-left: 7px;
        background: $grey-color-dark;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        font-style: normal;
        font-weight: normal;
        font-size: 12.4667px;
        line-height: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.3006px;
        color: $white-color;
      }

      &:hover {
        background: $white-color;
      }

      &.activeListItem {
        background: $white-color;
        box-shadow: 0 8px 10px -9px rgba(29, 19, 60, 15%),
          0 4px 10px rgba(63, 41, 132, 10%);
        border-radius: 8px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.4675px;
        color: $black-text-color;
      }
    }
  }

  .toggleTypeListVerticalContainer {
    background: $grideperlevy;
    border-radius: 10px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 40px;
    margin-bottom: 40px;

    .toggleTypeListItem {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      padding: 7px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.4675px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: $grey-color-name;

      span {
        text-align: center;
      }

      .listItemNumMess {
        margin-left: 7px;
        background: $grey-color-dark;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        font-style: normal;
        font-weight: normal;
        font-size: 12.4667px;
        line-height: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.3006px;
        color: $white-color;
      }

      &:hover {
        background: $white-color;
      }

      &.activeListItem {
        background: $white-color;
        box-shadow: 0 8px 10px -9px rgba(29, 19, 60, 15%),
          0 4px 10px rgba(63, 41, 132, 10%);
        border-radius: 8px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.4675px;
        color: $black-text-color;
      }
    }
  }
}

.chatListContainer {
  flex-grow: 1;
  position: relative;

  .DirectMessage,
  .ChatsList {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #25d2a2;
      border-radius: 4px;
    }

    .archiveTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.4675px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: $grey-color-name;
      padding: 30px 0 10px 20px;

      &.archiveTitleActive {
        color: $black-text-color;
      }
    }

    .archiveEmptyContainer {
      height: 50px;
      width: 50%;
    }
  }
}

.CoverageDescriptionListItem {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #ffa06a;
}

.ChatsListItem {
  transition: all 0.3s ease;
  cursor: pointer;

  &.active,
  &:hover {
    border-radius: 21px 0 0 21px;
    background: $grey-light-color;
  }

  .channelContainer {
    display: flex;
    align-items: flex-start;
    padding: 16px 20px 12px 12px;

    .imageContainer {
      margin-right: 10px;
      flex-shrink: 0;

      .channelAvatar {
        height: 60px;
        width: 60px;
        margin-bottom: 7px;

        .usersAvatar {
          display: block;
        }

        .allUsersContainer {
          position: absolute;
          bottom: -7px;
          right: 0;

          &.coachUsersContainer {
            right: initial;
            left: 7px;
          }
        }

        .usersAvatarContainer {
          display: flex;
          flex-direction: row-reverse;

          .userAvatar {
            height: 22px;
            width: 22px;
            border: 1px solid $grey-color-dark;
            border-radius: 50%;
            background: linear-gradient(
              0deg,
              $grey-color-dark,
              $grey-color-dark
            );
            color: $white-color;
            float: right;
            margin-left: -7px;
            flex-shrink: 0;

            &.coachAvatar {
              border: 1px solid $green-blue-color-text;
            }
          }

          .userAvatarNum {
            height: 22px;
            width: 22px;
            border: 1px solid $grey-color-dark;
            border-radius: 50%;
            background: linear-gradient(
              0deg,
              $grey-color-dark,
              $grey-color-dark
            );
            color: $white-color;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;
            margin-left: -7px;
            flex-shrink: 0;

            .userAvatarMore {
              margin-top: -7px;
            }
          }
        }
      }
    }

    @keyframes fadeIn {
      0% {
        display: none;
        opacity: 0;
      }

      70% {
        display: none;
        opacity: 0;
      }

      100% {
        display: block;
        opacity: 1;
      }
    }

    .infoFadedOut {
      display: none;
    }

    .infoFadingOut {
      transition: all 0.5s ease;
      animation-name: fadeIn;
      animation-duration: 0.5s;
      animation-fill-mode: backwards;
    }

    .infoContainer {
      flex-grow: 1;
      transition: all 0.5s ease;
      animation-name: fadeIn;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;

      .infoTopContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .infoTitleContainer {
          display: flex;
          align-items: center;
          flex-grow: 1;

          .iconGroupContainer {
            height: 16px;
            width: 16px;
            margin-right: 4px;
            flex-shrink: 0;

            svg {
              path {
                fill: $black-text-color;
              }
            }
          }

          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 190px;
          }
        }

        .infoDateContainer {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $blue-grey-light-color-2;
        }
      }

      .infoButContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .infoDescriptionContainer {
          .courseName {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: $grey-color-name;
          }

          .lastMess {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.08px;
            color: $grey-color-name;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 250px;

            .newClient {
              color: #25d2a2;
            }

            .danger {
              color: $alert-color;
            }
          }
        }

        .infoCountContainerGreen,
        .infoCountContainerGreenNarrow,
        .infoCountContainerOrange,
        .infoCountContainerOrangeNarrow,
        .infoCountContainerRed,
        .infoCountContainerRedNarrow,
        .infoCountContainerYellow,
        .infoCountContainerYellowNarrow {
          min-width: 22px;
          padding: 3px 2px;
          border-radius: 50%;
          font-style: normal;
          font-weight: normal;
          font-size: 12.4667px;
          line-height: 16px;
          text-align: center;
          letter-spacing: -0.3006px;
          color: $white-color;
        }

        .infoCountContainerGreen,
        .infoCountContainerGreenNarrow {
          background: $green-blue-color-text;
        }

        .infoCountContainerYellow,
        .infoCountContainerYellowNarrow {
          background: #ffe976;
        }

        .infoCountContainerOrange,
        .infoCountContainerOrangeNarrow {
          background: #ffa06a;
        }

        .infoCountContainerRed,
        .infoCountContainerRedNarrow {
          background: #fa5353;
        }

        .infoCountContainerGreenNarrow,
        .infoCountContainerOrangeNarrow,
        .infoCountContainerRedNarrow,
        .infoCountContainerYellowNarrow {
          margin-top: 38px;
          margin-left: -32px;
        }
      }
    }
  }
}

.CoverageDescriptionListItem {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #ffa06a;
}

.ChatMainContent {
  padding-right: 20px;
  height: calc(100vh - 68px);

  .ChatMainContentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ChatMainTextContainer {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

      .ChatMain {
        flex-grow: 1;
        width: 100%;
        position: relative;

        .ChatMainOverFlowContainer {
          position: absolute;
          overflow-y: scroll;
          height: 100%;
          width: 100%;
          padding-left: 60px;

          .ChatPlacePostsAtTheBottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            min-height: 100%;
          }

          .ChatMainDaySectionContainer {
            padding-top: 10px 0;

            .ChatMainDaySection {
              display: flex;
              align-items: center;

              .ChatMainDayLine {
                border-top: 1px solid $blue-grey-light-color;
                flex-grow: 1;
              }

              .ChatMainDayTitle {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                padding: 0 10px;
                color: $blue-grey-light-color-3;
              }
            }
          }

          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }
  }
}

.CoverageDescription {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.08px;
  color: #ffa06a;
}

.PostsListItem {
  .systemPost {
    margin: 20px;
    background: $blue-grey-light-color-4;
    border-radius: 18px;
    padding: 20px;
    color: $purple-color;
    text-align: center;
  }

  .mainContainer {
    padding: 10px 0;
  }

  .container {
    display: flex;
    align-items: flex-end;

    .innerPostContainer {
      display: flex;
      flex-direction: column;
    }

    &.myContainer {
      flex-direction: row-reverse;
    }

    .rightPosition,
    .leftPosition,
    .olderCoachMessage {
      display: flex;
      flex-direction: column;
      max-width: 70%;
      background: $blue-grey-light-color-5;
      border-radius: 18px 18px 18px 9px;
      width: fit-content;
      padding: 15px;
      padding-bottom: 35px;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      position: relative;
      min-width: 100px;

      a {
        color: $black-text-color;
      }
    }

    .rightPosition {
      border-radius: 18px 18px 9px;
      background: $green-blue-color-text;
      color: $white-color;

      a {
        color: $white-color;
      }
    }

    .olderCoachMessage {
      border-radius: 18px 18px 9px;
      color: $white-color;
      background: #819aa9;
    }
  }

  .groupAvatarStub {
    width: 60px;
  }

  .avatarContainer {
    height: 40px;
    width: 40px;
    margin-right: 20px;
  }

  .postInProgress {
    display: block;
  }

  .postHighlighted {
    display: block;
  }

  .postTouchable {
    .timeStamp {
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      right: 15px;
      bottom: 8px;
    }
  }

  .pinnedUiPostTouchable {
    display: block;
  }

  .post {
    white-space: pre-line;
  }

  .adminBackground {
    display: block;
  }

  .myPost {
    display: block;
  }

  .pinnedUiPost {
    display: block;
  }

  .userName {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .myOrAdminTextColor {
    display: block;
  }

  .spice {
    height: 15px;
    width: 100%;
  }

  .PostAttachment {
    cursor: pointer;
    margin-bottom: 10px;

    .userAvatar {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      overflow: hidden;
    }

    .top {
      display: flex;
      align-items: center;

      .imageContainer {
        position: relative;
        height: 70px;
        width: 70px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;

        .image {
          position: absolute;
          height: 70px;
          width: 70px;
          top: 0;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .ico {
          height: 70px;
          width: 70px;
          background: $grey-green1-color;
          padding: 12px;

          svg {
            width: 100%;
            height: 100%;
            fill: $grey-color-dark;
          }
        }
      }

      .contentContainer {
        margin-left: 10px;
        flex-direction: column;
        justify-content: space-between;

        .name {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          max-width: 200px;
        }

        .description {
          color: var(--color-v2-text3);
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }

        .callToAction {
          border-radius: 20px;
          text-align: center;
          background-color: var(--color-v2-global2);
          color: var(--light-color-v2-text2);
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }
  }

  .imageAttachmentsContainer {
    margin-bottom: 5px;

    .imgContainer {
      display: flex;
      align-items: center;

      .image {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .imgInfoContainer {
        flex-grow: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .fileNameContainer {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          max-width: 200px;
        }

        .fileSizeContainer {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .userNameOnImageAttachmentGradient {
    display: block;

    .userName {
      display: block;
    }
  }
}

.CoverageDescription {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.08px;
  color: #ffa06a;
}

.ChatMainTopMenu {
  background: $grey-blue-light-color;
  border-radius: 53px 0 0;

  .topContent {
    padding: 24px 0 24px 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .isMoreBackBut,
    .imageContainer {
      height: 50px;
      width: 50px;
      margin-right: 10px;
      cursor: pointer;
      flex-shrink: 0;
    }

    .isMoreBackBut {
      background: $blue-grey-light-color-6;
      border-radius: 50%;
      padding: 17px;
    }

    .textContainer {
      flex-grow: 1;
      cursor: pointer;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }

      .description {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $grey-color-name;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      .buttonItem {
        background: $white-color;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 14px;
        margin-left: 20px;
        cursor: pointer;
        flex-shrink: 0;
      }

      .buttonItemActive {
        background: none;
        border: 1px solid $blue-grey-light-color-7;

        svg {
          fill: $blue-grey-light-color-7;

          path {
            fill: $blue-grey-light-color-7;
          }
        }
      }

      .buttonCalendar {
        padding: 13px;
      }

      .buttonFolder {
        padding: 15px;
      }
    }
  }

  .buttContentLine {
    border-top: 1px solid $blue-grey-light-color-8;
    border-bottom: 1px solid $blue-grey-light-color-8;

    .buttContent {
      padding: 8px 0 6px 60px;

      .pinnedContainer {
        display: grid;
        grid-template-columns: 3px auto;
        gap: 10px;
        align-items: center;

        .pinnedContainerFirstLine {
          width: 3px;
          height: 100%;
          background: $purple-color;
          border-radius: 3px;
          transition: all 0.3s ease;
        }
      }

      .pinnedTopPageTitle {
        display: flex;
        align-items: center;
        cursor: pointer;

        .backInChat {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 50%;
          margin-right: 20px;
          background: $grey-light-color;
          flex-shrink: 0;
        }

        .pinnedTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

.ChannelPinnedMessage {
  &.ChannelPinnedMessageClickable {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    align-items: center;

    .container {
      flex-grow: 1;

      .contentContainer {
        .headerText {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $purple-color;
        }

        .text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    .button {
      color: $white-color;
      background: $purple-color;
      border-radius: 8px;
      padding: 3px 10px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

.library {
  margin: 30px 0;
  padding-left: 60px;
}

.AttachmentsForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  .AttachmentsElement {
    height: 60px;
    width: 60px;
    position: relative;
    margin: 15px;

    .closeButtonContainer {
      width: 20px;
      height: 20px;
      position: absolute;
      right: -10px;
      top: -10px;

      .closeBut {
        padding: initial;
        margin: initial;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid $grey-color;
        cursor: pointer;
      }
    }

    .imageContainer {
      border-radius: 6px;
      border: 1px solid $grey-color;
      width: 100%;
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        a {
          width: 100%;
          height: 100%;
          display: block;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .ico {
        svg {
          fill: $grey-color-4;
        }
      }
    }
  }
}

.dataAndBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.prolongButton {
  width: 104px;
  height: 36px;
  margin-left: 10px;
  padding: 8px 30px;
  background-color: #25d2a2;
  border-color: #25d2a2;
  border-radius: 40px;
  font-size: 13px;
}
