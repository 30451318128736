@import "@src/styles/vars.css";

.profileContainer {
  .nameInput {
    input {
      &::placeholder {
         color: #c3d4d8;
       }
    }
  }
}

.EditProfileModal {
  min-height: 500px;

  .saveButContainer {
    position: absolute;
    top: 20px;
    right: 90px;

    .saveBut {
      height: 40px;
      padding: 12px 39px;
      background: $blue-color-eggs-wandering-thrush;
      border-radius: 10px;
    }
  }

  .profileContainer {
    display: grid;
    grid-template-columns: auto 220px;
    column-gap: 20px;
    height: 100%;

    .fields {
      overflow-x: hidden;
      height: 90%;
      padding-right: 5px;

      .error {
        color: $alert-color;
      }

      .inputFields {
        min-height: 80%;
      }

      .accountsContainer {
        margin-top: 30px;
        margin-bottom: 20px;
      }

      .nameInput {
        border: 1px solid $grey-border-input-color;
        border-radius: 6px;
        margin-top: 26px;
        height: 48px;

        input {
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          display: block;
          padding: 13px 20px;
          color: $black-text-color;

          &:focus + .nameLabel {
            top: -25px;
            left: 0;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
          }
        }

        .nameLabel {
          font-size: 16px;
          line-height: 22px;
          position: absolute;
          font-weight: 300;
          transition: all 0.5s ease 0s;
          top: 13px;
          left: 25px;
          padding: 0;
          color: $grey-color-label;
        }

        .activeLink {
          top: -25px;
          left: 0;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
        }
      }

      .customGeneratorCheckBox {
        background:
          linear-gradient(
            0deg,
            $grey-light-but-color,
            $grey-light-but-color
          );
        border-radius: 14.2215px;
        padding: 20px;

        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        .text {
          line-height: 21px;
          letter-spacing: -0.3887px;
          color: $grey-color-name;
        }
      }
    }

    .photo {
      width: 100%;
      padding-top: 30px;
      padding-bottom: 36px;
      background-color: $grey-light-color;
      border-radius: 10px;
      text-align: center;
      overflow-x: hidden;
      height: 90%;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
      }

      &.photoWhite {
        background-color: initial;
      }

      .decorPhotoContainer {
        height: 100px;
        width: 100px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }

      .editPhotoButton {
        background-color: $grey-blue-dark-color;
        padding: 10px 15px;
        margin-bottom: 2px;
        width: 150px;
      }
    }
  }

  .useCoach {
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    text-align: initial;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: initial;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      display: block;
      line-height: 16px;
      text-align: left;
      margin-bottom: 13px;
      color: $black-text-color;
      opacity: 0.7;
    }

    .greenButton {
      background: $green-color;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      padding: 10px 20px;
      color: $white-color;
    }

    &.greyBack {
      background: $grey-light-color;

      span {
        color: $grey-color-name;
        opacity: initial;
      }
    }
  }
}

.EditProfile {
  padding-top: 40px;
  padding-bottom: 60px;
  position: relative;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4675px;
    color: $black-text-color;
  }

  .saveButContainer {
    position: absolute;
    right: 0;
    top: -110px;

    .saveBut {
      background: $blue-color-eggs-wandering-thrush;
      border-radius: 10px;
      height: 40px;
      padding: 12px 39px;
    }
  }

  .profileContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12%;

    .fields {
      display: block;

      .inputFields {
        display: block;
      }

      .accountsContainer {
        display: block;
      }

      .nameInput {
        background: initial;
        padding-top: 15px;
        margin-bottom: 30px;

        input {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.41px;
          color: $grey-color-dark;

          &:focus + .nameLabel {
            top: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.4675px;
            padding: 0;
            color: $black-text-color;
          }
        }

        .nameLabel {
          top: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.4675px;
          padding: 0;
          color: $black-text-color;
        }

        .activeLink {
          top: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.4675px;
          padding: 0;
          color: $black-text-color;
        }
      }

      .customGeneratorCheckBox {
        display: block;

        .header {
          display: block;
        }

        .text {
          display: block;
        }
      }
    }

    .photo {
      display: block;

      &::-webkit-scrollbar {
        display: block;
      }

      &.photoWhite {
        display: block;
      }

      .avatarContainer {
        padding: 25px 0;
        display: flex;
        align-items: center;

        .decorPhotoContainer {
          height: 110px;
          width: 110px;

          .userProfileContainer {
            width: 100%;
            height: 100%;
            clip-path: circle(50%);
            cursor: pointer;
          }
        }

        .avatarActionButton {
          background-color: initial;
          color: var(--color-v2-text4);

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }

          &.danger {
            color: var(--color-v2-error);
          }
        }
      }
    }
  }

  .useCoach {
    background: white;
    border-radius: 15px;
    padding: 20px;

    &.noModal {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4675px;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.41px;
      padding-top: 8px;
      color: $black-text-color;
      opacity: 0.7;
    }

    .butContainer {
      display: block;
    }

    .greenButton {
      background: $blue-color-eggs-wandering-thrush;
      border-radius: 10px;
      padding: 10px 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: $white-color;
    }

    &.greyBack {
      display: block;

      span {
        display: block;
      }
    }
  }
}

.AddEmail,
.AddLocation {
  .nameInput {
    border: 1px solid $grey-border-input-color;
    border-radius: 6px;
    margin-top: 26px;
    height: 48px;

    input {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      display: block;
      padding: 13px 20px;
      color: $black-text-color;

      &:focus + .nameLabel {
        top: -25px;
        left: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }
    }

    .nameLabel {
      font-size: 16px;
      line-height: 22px;
      position: absolute;
      font-weight: 300;
      transition: all 0.5s ease 0s;
      top: 13px;
      left: 25px;
      padding: 0;
      color: $grey-color-label;
    }

    .activeLink {
      top: -25px;
      left: 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
}

.AddLocation {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.listContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .listContainerTitle {
    padding: 15px 0 5px;
  }

  .list {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    position: relative;

    .scrollListContainer {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 5px;

      .listItem {
        border: 1px solid $grey-light-color;
        padding: 10px 20px;
        background: $grey-light-color;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: transform, box-shadow 0.3s ease;

        &:hover {
          box-shadow: 6px 6px 12px 0 rgba(50, 50, 50, 25%);
          transform: scale(1.005);
        }
      }
    }
  }
}

.UserProfile {
  display: block;
}
