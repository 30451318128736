@import "@src/styles/vars.css";

.ChatMainTextEditor {
  background: $white-color;
  border-radius: 10px;
  margin: 0 0 30px 60px;
  position: relative;

  .toolbar {
    display: flex;
    align-items: center;

    .toolbarBut {
      height: 40px;
      width: 40px;
      padding: 13px;
      cursor: pointer;
    }
  }

  .textInputContainer {
    margin: 15px 70px 10px 15px;
  }

  .textInput {
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
  }

  .buttMenu {
    position: absolute;
    bottom: 12px;
    right: 10px;
    display: flex;
    align-items: center;

    .buttMenuItem {
      height: 20px;
      width: 20px;
      margin-left: 12px;
      cursor: pointer;
    }

    .attachmentsBut {
      &.butActive {
        svg {
          fill: $purple-color;

          path {
            fill: $purple-color;
          }
        }
      }
    }

    .sendBut {
      &.disabled {
        svg {
          fill: $grey-color-dark;

          path {
            fill: $grey-color-dark;
          }
        }
      }
    }
  }
}