.Component {
  display: grid;
  width: 100%;
  grid-template-columns: 250px 250px;
  gap: 40px;

  &.editMode {
    .repeatOptionsContainer {
      display: none;
    }

    .titleContainer {
      grid-column: span 2;
    }
  }

  .hiddenContainer {
    visibility: hidden;

    .repeatOptions {
      display: none;
    }
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    .sub {
      font-weight: normal;
      color: var(--color-v2-text3);
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.titleContainer {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  grid-column: span 2;

  textarea {
    min-height: 100px;
    resize: vertical;
  }
}

.repeatOptions {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;

  .repeatOption {
    background: var(--color-v2-background2);
    border-radius: 100px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 15px;
    display: inline-block;
    cursor: pointer;
    color: var(--color-v2-text3);
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      background: var(--color-v2-global1);
      color: var(--light-color-v2-text2);
    }
  }
}

.eventTypeOptions {
  display: flex;
  justify-content: space-between;

  .eventTypeOption {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-v2-background2);
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    span {
      color: var(--color-v2-text3);
      font-size: 14px;
      font-weight: 600;
    }

    svg {
      height: 30px;
      margin-right: 10px;
      fill: var(--color-v2-text3);
    }

    .eventTypeOptionBorder {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
    }

    &.selected {
      span {
        color: var(--color-v2-global1);
      }

      svg {
        fill: var(--color-v2-global1);
      }

      .eventTypeOptionBorder {
        border: 1px solid var(--color-v2-global1);
      }
    }
  }
}
