@import '@src/styles/vars.css';

.QTemplates {
  .header {
    display: flex;
    justify-content: space-between;

    .headerBut {
      background:
        linear-gradient(
          0deg,
          $green-liner-one-color-text 0%,
          $green-liner-two-color-text 100%
        );
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      padding: 12px 50px;
      text-transform: initial;
      cursor: pointer;
    }
  }

  .mt30 {
    margin-top: 30px;
  }
}
