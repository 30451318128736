@import "@src/styles/vars.css";

.colorContainer {
  &.mintGreen {
    background: $mint-green-color;
  }
}

.OtherUserProfile {
  position: relative;

  .OverlayImage {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 40%), rgba(0, 0, 0, 40%));
    top: 0;
  }
}

.pl50 {
  padding-left: 50px;
}

.ShowOnlyCoach {
  flex-grow: 1;
  height: 100%;
}

.QTemplates {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.colorAllHeight {
  flex-grow: 1;
  height: 100%;
  padding-top: 30px;
}

.percent100H {
  height: 100%;
}

.overflow {
  overflow-y: scroll;
}

.whiteColorArea {
  background-color: #fefefe;
}

.TwoColumnsLayouts {
  display: grid;
  grid-template-columns: auto auto auto;

  .secondCol {
    grid-column: span 2;
  }

  .greyColumnInLayout {
    background: $grey-light-color;
    border-top-left-radius: 50px;

    .greyColumnInLayoutContent {
      width: calc(1180px - 440px);
      transition: all 0.3s ease;
    }
  }
}

.ThreeColumnsLayouts {
  display: grid;
  grid-template-columns: auto auto auto;
  padding-left: 95px;
  width: 100%;

  .secondCol {
    hr {
      border: none;
      height: 1px;
      background-color: $grey-color;

      &.first {
        margin-left: -60px;
        margin-right: -60px;
      }
    }
  }

  .greyColumn {
    background-color: $grey-light-color;
  }

  .greyColumnLightGradient {
    background-color: #f5f8fb;
    border-top: 74px solid #fff;
  }

  .mergeTowLastColumn {
    grid-column: span 2;

    .contentContainer {
      width: calc(100% - 190px);
    }
  }

  .greyColumnInLayout {
    background: $grey-light-color;
    border-top-left-radius: 50px;
  }
}

.container {
  width: calc(100% - 180px);
  margin: 0 auto;
  position: relative;
  background: linear-gradient(0deg, #f2f3f3 0%, #f7f7f7 94.12%);

  .offsetMenuContainer {
    &.allHeight {
      height: 100vh;
      display: flex;
      flex-direction: column;

      &.allHeightMinusMenu {
        margin-top: -30px;
      }
    }

    hr.mainSeparator {
      border: 0.7px solid $grey-light-but-color;
    }

    a.linkAsBut {
      display: block;
      border-radius: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      padding: 10px 20px;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      background: $green-color;
      color: $white-color;
    }

    .OverlayImage {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 40%), rgba(0, 0, 0, 40%));
      top: 0;
    }

    a.roundGreyButton {
      display: block;
      border-radius: 20px;
      font-weight: bold;
      font-size: 14px;
      background-color: $grey-light-but-color;
      text-decoration: none;
      color: $grey-color-name;
      transition: all 1s;
      width: max-content;

      &:hover {
        background-color: $green-color;
        color: $black-text-color;
      }
    }

    a.squareGreenButton {
      display: block;
      color: $white-color;
      font-weight: 600;
      font-size: 12px;
      border-radius: 5px;
      background-color: $green-color;
      text-decoration: none;
      width: max-content;
      padding: 5px 10px;
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      color: $black-text-color;
    }

    h3 {
      font-weight: bold;
      font-size: 18px;
      color: $black-text-color;
    }

    .greyArea {
      background-color: $grey-light-color;
      border-top-right-radius: 50px;
      padding: 40px 60px 40px 40px;

      hr {
        border: none;
        height: 1px;
        background-color: $grey-color;

        &.first {
          margin-left: -40px;
          margin-right: -60px;
          margin-top: 6px;
        }
      }
    }

    .whiteArea {
      padding: 40px 0 40px 60px;

      .separator {
        height: 40px;
      }
    }
  }

  .columnsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .firstColumnContainer {
      &.allWidth {
        width: 100%;
      }
    }

    .otherColumnsContainer {
      padding-left: 20px;
      width: 640px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-top: 60px;
    }
  }

  .minHeight {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .minHeightGrow {
    display: flex;
    flex-grow: 1;
  }

  .allWidth {
    width: 100%;
  }
}
