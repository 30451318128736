@import '@src/styles/vars.css';

.AvatarLoader {
  height: 100%;
  width: 100%;
  background-color: $grey-color-border;

  .AvatarTitle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    color: $white-color;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    line-height: 25px;
    z-index: 1;
  }
}
