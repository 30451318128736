@import "@src/styles/vars.css";

.CourseCoachesTab {
  .avatarContainer {
    .coachAvatarContainer {
      margin-top: 25px;

      .programLink {
        text-decoration: none;

        .coachString {
          display: flex;
          align-items: center;
          cursor: pointer;
          background: $white-color;
          box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
          border-radius: 10px;
          padding: 15px 20px;
          margin-bottom: 10px;

          .coachAvatar {
            height: 40px;
            width: 40px;
            border: 1px solid $white-color;
            border-radius: 97px;
          }
        }
      }

      .coachName {
        height: 22px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.4675px;
        color: $black-text-color;
        margin-left: 21px;
      }
    }
  }
}
