@import "@src/styles/vars.css";

.Programs {
  .programsHeader {
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;

    .buttonContainer {
      display: flex;
      align-items: center;

      .button {
        background: $green-color;
        border-radius: 10px;
        padding: 14px 14px 14px 20px;
        width: 165px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 30px;
        cursor: pointer;

        &.sharedButton {
          background: $grey-light-but-color;
        }

        .label {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: $white-color;

          &.black {
            color: initial;
          }
        }

        .icon {
          height: 22px;
          width: 22px;

          svg {
            fill: $white-color;
          }

          &.black {
            svg {
              fill: $grey-color-dark;
            }
          }
        }
      }
    }

    h3.programsHeaderTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.4675px;
      color: $black-text-color;
    }
  }

  .programsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 100px;
    margin-top: 20px;

    .programContainer {
      height: 220px;

      .programLink {
        text-decoration: none;
      }
    }
  }
}
