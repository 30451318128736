.Checkbox {
  display: flex;

  .checkMark {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border: 1px solid var(--color-v2-border2);
    border-radius: 4px;
    background: var(--color-v2-background1);
    cursor: pointer;

    & svg {
      width: 24px;
      height: 24px;
      margin-left: -2px;
      fill: var(--color-v2-icon5);
    }
  }

  .label {
    font-size: 14px;
    color: var(--color-v2-text3);
    margin-left: 12px;
  }

  &.disabled {
    .checkMark {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &.round {
    .checkMark {
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        position: relative;
        left: 1px;
        width: 10px;
        height: 10px;
        fill: none;
        background-color: var(--color-v2-icon5);
        border-radius: 10px;
      }
    }
  }
}
