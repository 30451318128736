@import '@src/styles/vars.css';

.TopNotifications {
  .BellConteiner {
    height: 20px;
    width: 17px;
    position: relative;
    cursor: pointer;

    .numNotif {
      display: block;
      background: $red-notify-color;
      color: $white-color;
      border-radius: 19px;
      min-width: 19px;
      height: initial;
      font-weight: 600;
      font-size: 10px;
      padding: 1px 5px;
      text-align: center;
      position: absolute;
      top: -7px;
      left: 10px;
    }
  }
}
