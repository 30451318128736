.TextArea {
  display: block;

  textarea {
    border-width: 1px;
    border-style: none none solid none;
    border-color: var(--color-v2-border2);
    outline: none;
    width: 100%;
    padding: 3px 0;
    font-size: 16px;
    resize: none;

    &::placeholder {
      color: var(--color-v2-text3);
    }
  }

  .error {
    color: var(--color-v2-error);
    font-size: 14px;
  }

  &.disabled {
    textarea {
      background-color: rgba(0, 0, 0, 0);
      cursor: not-allowed;
    }
  }
}
