.avatarContainer {
  display: flex;
  align-items: center;
}

.avatar {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  overflow: hidden;

  &.disabled {
    cursor: not-allowed;
  }

  img {
    background-color: var(--color-v2-background1);
  }
}

.avatarPlaceholder {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-v2-background2);

  svg {
    width: 24px;
    height: 24px;
    fill: var(--color-v2-icon3);
  }
}

.avatarActionButton {
  background-color: initial;
  color: var(--color-v2-text4);

  &.danger {
    color: var(--color-v2-error);
  }
}

.descriptionTextArea {
  min-height: 100px;
  resize: vertical;
}
