@import '@src/styles/vars.css';

.CertificatesList {
  list-style: none;

  .CertificatesListItem {
    display: flex;
    margin-bottom: 20px;

    .imageContainer {
      width: 253px;
      height: 162px;
      padding-top: 20%;
      margin-right: 20px;
      position: relative;
      overflow: hidden;

      .imageDiv {
        border-radius: 16px;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .status {
        background: $purple-color;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 8px 16px;
        top: 12px;
        left: 12px;
        text-transform: capitalize;
        color: white;
        font-size: 14px;
      }
    }

    .content {
      flex-grow: 2;
      display: flex;
      flex-direction: column;

      .school {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 17px;
        color: $grey-color-dark;
        margin: 12px 0 12px 0;
      }

      .spacer {
        flex-grow: 1;
      }

      .buttons {
        display: flex;
        width: 334px;

        .buttonContainer {
          flex-grow: 1;
        }

        .buttonSpacer {
          width: 20px;
          height: 1px;
          flex-grow: 0;
        }

        .button {
          border: 1px solid $grey-color-border;
          border-radius: 12px;
          background: transparent;
          color: $grey-color-border;
          font-weight: 600;
          text-transform: none;
          padding: 12px 28px;
          font-size: 14px;
          width: 100%;
          margin-right: 20px;
        }

        .editButton {
          background: $grey-light-color;
          border: none;
        }
      }
    }
  }

  &.horizontal {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    .CertificatesListItem {
      flex-direction: column;

      h4 {
        margin-top: 20px;
      }

      .imageContainer {
        width: 100%;
      }

      .content {
        .buttons {
          width: auto;
        }
      }
    }
  }
}
