@import "@src/styles/vars.css";

:global(.ThreeColumnsLayouts) {
  grid-template-columns: auto 100% auto;
}

.SeeProgramPublic {
  min-height: calc(100vh - 94px);
  display: grid;
  grid-template-columns: 280px auto;
  gap: 60px;

  .greyColumn {
    background: $grey-light-color;
    border-top-left-radius: 50px;
    padding: 30px 60px;
  }

  .headerProgram {
    .headerProgramLeft {
      min-height: 250px;

      .paymentPlan {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 43px;
        margin-top: 14px;
        margin-bottom: 19px;
        color: $green-blue-color-text;
      }

      .paymentPlanOptionContainer {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;

        .paymentPlanOption {
          display: flex;
          margin-right: 40px;

          span {
            margin-left: 14px;
          }

          .paymentPlanIcon {
            width: 22px;
            height: 22px;

            .calendarIcon {
              svg {
                fill: $green-blue-color-text;
              }
            }
          }

          .programTypeIcon {
            width: 22px;
            height: 22px;

            svg {
              fill: $green-blue-color-text;
            }
          }
        }

        .coachAvatarContainer {
          height: 28px;
          width: 28px;
        }
      }

      .programDescription {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
        margin-top: 30px;
      }
    }
  }
}

.ImageProgramContainer {
  height: 280px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;

  .ImageProgramAbsContainer {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .joinNowBut {
      position: absolute;
      top: 20px;
      left: 19px;
      padding: 3px 6px;
      background: $green-blue-color-text;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $white-color;
      cursor: pointer;
    }

    .OverlayImage {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0 0 0, 40%), rgba(0 0 0, 40%));
      top: 0;
    }
  }
}

.backToPracticeButton {
  width: 100%;
  background: $grey-light-but-color;
  border-radius: 8px;
  padding: 10px 20px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .iconContainer {
    height: 18px;
    width: 18px;
  }

  .rightContainer {
    flex-grow: 1;
    padding-left: 20px;

    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $grey-color-dark;
    }

    .practiceName {
      padding-top: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.paymentPlanOptionContainer {
  padding-top: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  .paymentPlanOption {
    display: flex;
    margin-bottom: 20px;

    span {
      margin-left: 14px;
    }

    .paymentPlanIcon {
      width: 28px;
      height: 28px;

      .calendarIcon {
        svg {
          fill: $green-blue-color-text;
        }
      }
    }

    .programTypeIcon {
      width: 28px;
      height: 28px;

      svg {
        fill: $green-blue-color-text;
      }
    }
  }

  .coachAvatarContainer {
    height: 28px;
    width: 28px;
  }
}

.tabsContainer {
  margin-top: 25px;
  margin-bottom: 30px;

  .tabTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.4675px;
    border-bottom: 3px solid $green-color;
    padding-bottom: 7px;
    width: max-content;
  }
}

.course {
  background: $white-color;
  box-shadow: 0 4px 15px $shadow;
  border-radius: 10px;
  height: 70px;
  margin-top: 11px;
  display: flex;
  align-items: center;
  padding: 12px 10px 12px 25px;

  .programCoursesContainer {
    width: 100%;
    display: flex;
    align-items: center;

    .contentContainerLeft {
      display: flex;
      align-items: center;

      .iconCalendarContainer {
        height: 24px;
        width: 24px;
        margin-right: 20px;

        .iconCalendar {
          height: 100%;
          width: 100%;
        }
      }

      .durationContainer {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.24px;
        padding-right: 20px;
      }
    }

    .contentContainerRight {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .status {
        display: flex;
        align-items: center;
        flex-grow: 1;

        .courseStatus {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.24px;
          color: $grey-color-dark;
          flex-grow: 1;
        }

        .groupContainer {
          display: flex;
          align-items: center;
          color: $grey-color-dark;
          padding-right: 30px;

          .iconGroupSmall {
            height: 20px;
            width: 20px;
            margin-right: 7px;

            svg {
              path {
                stroke: $grey-color-dark;
              }
            }
          }

          .courseSize {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.24px;
          }

          &.green {
            color: $green-color;

            .iconGroupSmall {
              svg {
                path {
                  stroke: $green-color;
                }
              }
            }
          }
        }
      }

      .buttonsContainer {
        display: block;

        .button {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          width: 133px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          cursor: pointer;

          &.buttonGreen {
            background: $green-color;
            color: $white-color;
          }

          &.buttonGray {
            background: $grey-light-but-color;
            color: $grey-color-dark;
          }
        }
      }
    }
  }
}
