.Component {
  padding: 15px 0;
  border-bottom: 1px solid var(--color-v2-border2);

  &:last-child {
    border-bottom: none;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftPart {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.rightPart {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkbox > div svg {
  background-color: var(--color-v2-global1) !important;
}

.userContainer {
  display: flex;
  align-items: center;
  margin: 0 15px;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 15px;
  }

  .userName {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-v2-text1);
  }
}

.courseDatesContainer {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 20px;
  margin: 0 20px;
  border-left: 1px solid var(--color-v2-border2);
  border-right: 1px solid var(--color-v2-border2);

  .courseDates {
    font-size: 15px;
    line-height: 20px;
    color: var(--color-v2-text3);
    margin-left: 5px;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-v2-icon3);
    position: relative;
    top: 2px;
  }
}

.date {
  font-size: 15px;
  line-height: 20px;
  color: var(--color-v2-text3);
}

.message {
  position: relative;
  display: flex;
  margin: 10px 0 0 39px;
  padding-left: 14px;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-v2-text3);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    border-radius: 10px;
    background-color: var(--color-v2-global1);
  }
}
