@import '@src/styles/vars.css';

.CustomRadioButton {
  ul {
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: auto;

    li {
      list-style-type: none;
      display: inline-block;
      text-align: center;
      margin: 15px;
      cursor: pointer;

      span {
        font-style: normal;
        font-weight: bold;
        font-size: 13.2734px;
        line-height: 18px;
        vertical-align: text-bottom;
        display: inline-block;
        width: 76px;
        text-align: justify;
        min-height: 25px;
        position: relative;

        i {
          width: 25px;
          height: 100%;
          position: absolute;
          left: -25px;
          display: block;
          opacity: 0;
          transition: all 1s ease 0s;

          svg {
            fill: $white-color;
          }
        }
      }

      &.activeRadio {
        span {
          color: $green-color-text;

          i {
            opacity: 1;

            svg {
              fill: $green-color-text;
            }
          }
        }
      }
    }
  }
}
