@import "../styles/vars.css";

.NoResultsHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-v2-text3);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  max-width: 350px;

  svg {
    width: 50px;
    height: 50px;
    fill: var(--color-v2-icon3);
    margin-bottom: 20px;
  }

  .description {
    padding-top: 20px;
    font-size: 15px;
    line-height: 20px;
  }
}
