@import '@src/styles/vars.css';

.Coaches {
  .list {
    padding-bottom: 30px;

    .section {
      margin-top: 40px;

      .sectionTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
      }

      .sectionContent {
        .practiceContainer {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 28px;
          row-gap: 16px;
          margin-bottom: 20px;

          .practiceLink {
            text-decoration: none;
          }
        }
      }
    }
  }

  .noResultsContainer {
    padding: 30px 0;
    display: flex;
    justify-content: center;
  }

  .categories {
    display: flex;
    margin-top: 48px;
    flex-wrap: wrap;

    .category {
      border: 1px solid $grey-blue2-color;
      border-radius: 20px;
      padding: 12px 9px 12px 9px;
      font-weight: 600;
      font-size: 12px;
      color: #919ba8;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: all 0.2s ease;

      &.selected {
        color: $white-color;
        background-color: #b2bbc6;
      }

      &.all {
        min-width: 55px;
        align-items: center;
        justify-content: center;
        display: flex;
      }

      &.all.selected {
        cursor: initial;
      }
    }
  }

  .tabsPanel {
    display: flex;
    flex-direction: column;

    .headerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .tabsContainer {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 44px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 47px;
        cursor: pointer;
        color: $grey-color-text;

        .activeTab {
          color: $black-text-color;
          border-bottom: solid 4px $green-color;
        }

        .tabs::after {
          content: '';
          display: block;
          width: 0;
          height: 4px;
          background: $green-color-text;
          transition: width 0.3s;
        }

        .tabs:hover::after {
          width: 100%;
        }
      }

      .menuSearchContainer {
        .searchContainer {
          padding: inherit;
          background: initial;
          margin-top: 0;
          margin-bottom: 0;
          width: 330px;
          height: 45px;
        }
      }
    }
  }
}
