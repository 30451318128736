.Component {
  display: flex;

  &.checked .mark::after {
    display: block;
  }
}

.mark {
  width: 18px;
  height: 18px;
  padding: 4px;
  border: 1px solid var(--color-v2-border2);
  border-radius: 12px;
  background: var(--color-v2-background1);

  &::after {
    display: none;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background: var(--color-v2-global1);
    position: absolute;
  }
}

.label {
  font-size: 14px;
  color: var(--color-v2-text3);
  margin-left: 12px;
}
