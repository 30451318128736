@import "@src/styles/vars.css";

.Program {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  .imageContainer {
    height: 100%;
    width: 100%;
    position: relative;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .dataContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;

    .firstLine {
      display: flex;
      justify-content: space-between;
      min-height: 50px;

      .status {
        background: rgba(0, 0, 0, 60%);
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.41px;
        padding: 5px 10px;
        margin: 20px;
        color: $white-color;
      }

      .avatarContainer {
        position: absolute;
        top: 0;
        right: 0;

        .coachAvatarContainer {
          height: 24px;
          margin: 20px;
          display: flex;

          .coachAvatar {
            height: 24px;
            width: 24px;
            float: right;
            margin-left: -5px;
            border: 1px solid $white-color;
            border-radius: 97px;
          }

          .moreButt {
            margin-left: 11px;
            color: $white-color;
            font-size: 35px;
            line-height: 22px;
            width: 24px;
            text-align: center;
            margin-right: -6px;
          }
        }
      }
    }

    .hiddenCloseContainer {
      display: flex;
      margin-left: 20px;

      .iconHideContainer {
        height: 17px;
        width: 20px;
        margin-right: 15px;
        fill: hsl(0deg, 0%, 100%, 70%);
      }

      .iconCloseUserContainer {
        height: 20px;
        width: 20px;
      }
    }

    .bottomRow {
      position: absolute;
      bottom: 0;
      margin: 0 20px 12px;
      left: 0;
      right: 0;

      .line3 {
        display: flex;
        align-items: center;

        .fillGroup {
          width: 17px;
          display: inline-block;
          margin-right: 10px;
        }

        .fillIndividual {
          width: 14px;
          height: 14px;
          display: inline-block;
          margin-right: 5px;
          top: -1px;
          position: relative;
        }

        .duration {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: -0.24px;
          color: rgba(255, 255, 255, 70%);
        }
      }

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        height: 50px;
        margin-top: 10px;
        color: $white-color;
      }

      .price {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        min-height: 25px;
        letter-spacing: -0.24px;
        margin-top: 14px;
        color: rgba(255, 255, 255, 70%);
      }
    }
  }
}
