.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.coachContainer {
  display: flex;
  align-items: flex-start;
}

.avatarContainer {
  position: relative;
  margin-right: 15px;

  :global(.Image) {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }
}

.name {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4675px;
  color: var(--color-v2-text1);
  text-decoration: none;
}

.roleContainer {
  display: flex;
}

.role {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-v2-text3);
}

.status {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-v2-text3);
  margin-left: 10px;

  &.approved {
    color: var(--color-v2-text4);
  }

  &.rejected {
    color: var(--color-v2-error);
  }
}

.alert {
  position: relative;
  left: -2px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--color-v2-error);

  svg {
    margin-right: 3px;
    width: 20px;
    height: 20px;
    fill: var(--color-v2-error);
  }
}

.rightPart {
  position: relative;
  display: flex;
  align-items: center;

  &.withMoreButton {
    left: 10px;
  }
}

.portionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.portionInput {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4675px;
  color: var(--color-v2-text1);
  max-width: 85px;

  input[disabled] {
    border: none;
  }

  input {
    max-width: 100px;
    padding: 0;
    text-align: right;
    background-color: initial;
  }
}

.price {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.41px;
  color: var(--color-v2-text3);
}

.moreButton {
  padding: 0;
  background-color: initial;
  position: relative;
  top: 2px;
  margin-left: 5px;

  &.stub {
    svg {
      visibility: hidden;
    }
  }

  svg {
    width: 28px;
    height: 28px;
    fill: var(--color-v2-icon3);
  }
}
