@import '@src/styles/vars.css';

.errorContainer {
  font-size: 14px;
  padding: 10px 10px 10px $first-padding;
  color: $alert-color;
}

.noPadding {
  padding: initial;
}
