.videoPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: none;
    height: 100%;
  }

  video {
    object-fit: cover;
  }
}

.root {
  min-height: 540px;
}

.welcomeStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  .content {
    max-width: 475px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  color: var(--color-v2-text3);
  font-size: 24px;

  .topRow {
    font-size: 20px;
    margin-bottom: 40px;
  }

  .message {
    margin-top: 20px;
  }

  .buttons {
    display: grid;
    margin-top: 60px;
    grid-auto-flow: column;
    grid-gap: 20px;

    button {
      background: var(--color-v2-button-fill1);
      height: 53px;

      &.inverted {
        background: var(--color-v2-background1);
        color: var(--color-v2-button-fill1);
        border: 1px solid var(--color-v2-button-fill1);
      }

      &.wide {
        width: 220px;
      }

      &.middle {
        width: 180px;
      }
    }
  }
}

.leftLogo {
  margin: auto;
}

.form {
  width: 100%;
  height: 100%;
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    position: absolute;
    top: 50px;
  }

  .content {
    width: 480px;
    max-height: 520px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--color-v2-text3);

    .title {
      margin-bottom: 16px;
      font-size: 28px;
      font-weight: 600;
      color: var(--color-v2-text6);
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .description {
      margin-bottom: 40px;
      white-space: pre-line;
    }

    .ButtonsContainer {
      display: flex;
      flex-grow: 2;
      align-items: flex-end;
      justify-content: flex-end;

      button:first-child {
        margin-right: 20px;
      }
    }

    button {
      background: var(--color-v2-button-fill1);
      height: 53px;

      &.inverted {
        background: var(--color-v2-background1);
        color: var(--color-v2-button-fill1);
        border: 1px solid var(--color-v2-button-fill1);
      }

      &.middle {
        width: 180px;
      }

      &.grey {
        background: var(--color-v2-button-fill3);
        color: var(--color-v2-text3);
      }

      &.wide {
        width: 220px;
      }

      &.noBorder {
        border-color: transparent;
      }
    }

    .altNavRow {
      text-align: center;
      font-size: 14px;
      margin-top: 40px;
    }
  }

  .codeInputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    font-size: 14px;
  }

  input[type="text"] {
    height: 64px;
    padding-left: 20px;
    border: 1px solid var(--color-v2-border2);
    border-radius: 8px;
    background: var(--color-v2-background1);
    font-size: 16px;
  }

  a {
    color: var(--color-v2-button-fill1);
  }

  div[class*="mark"] svg {
    fill: var(--color-v2-button-fill1);
  }
}

.sendDelay {
  font-size: 14px;
  margin-top: 20px;

  &.alignLeft {
    text-align: left;
  }
}

.whatIsKey {
  text-align: right;
  margin-top: 20px;
}

.infoBox {
  width: 100%;
  padding: 20px 16px;
  color: var(--color-v2-text2);
  background: var(--color-v2-button-fill1);
  border-radius: 8px;
  text-align: left;
  font-size: 14px;

  .infoTitle {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
  }

  &.second {
    background: var(--color-v2-button-fill2);
  }
}

.marginTop20 {
  margin-top: 20px;
}

.roleBox {
  position: relative;
  width: 100%;
  height: 160px;
  padding: 28px 200px 0 24px;
  color: var(--color-v2-text3);
  background: var(--color-v2-background7);
  border-radius: 12px;
  text-align: left;
  font-size: 14px;
  clip-path: inset(0 0 round 12px);
  cursor: pointer;

  .border {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    border-radius: 12px;
  }

  &.selected .border {
    border: 2px solid var(--color-v2-button-border2);
  }

  .roleTitle {
    color: var(--color-v2-text1);
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    position: absolute;
    right: 1px;
    bottom: 0;
  }
}

.error {
  color: var(--color-v2-error);
  font-size: 14px;
  text-align: left;
}
