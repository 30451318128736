@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import './vars.css';
@import './theme.css';
@import './customIcon.css';
@import './grid.css';
@import './modalWindowContainer.css';
@import './listFolderLibrary.css';
@import './mainContainer.scss';
@import './customInput.css';
@import './customTextArea.css';
@import './customSelect.css';
@import './customRadioButton.css';
@import './generatorCustomCheckBox.css';
@import './customCheckBox.css';
@import './errorContainer.css';
@import './customSearch.css';
@import './customButton.css';
@import './customRange.css';
@import './mainMenu.css';
@import './topMenu.css';
@import './tabsMenu.css';
@import './questionnaireTemplates.css';
@import './animationsFile.css';
@import './customScrollBar.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  min-width: 1220px;
  width: 100%;
  margin: 0 auto;
}

ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

.hf-widget-wrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  padding-top: 100px;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: var(--color-v2-background1);
  font-family: 'Open Sans', sans-serif;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

.fadeBox {
  overflow: hidden;
  max-height: 700px;
  opacity: 1;
  transition: max-height 1000ms 0ms, opacity 200ms 200ms;
}

.fadeBox.hide {
  transition: max-height 1000ms 1000ms, opacity 200ms 0ms;
  opacity: 0;
  max-height: 0 !important;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@media (min-width: 320px) and (max-width: 1224px) {
  html,
  body {
    min-width: 0;
  }
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.borderRadius10 {
  border-radius: 10px;
}

.height100Percent {
  min-height: 100vh;
}

.height100Per {
  height: calc(100% - 30px);
}

.mt30 {
  margin-top: 30px;
}

.mr30 {
  margin-right: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

main {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.forgotLinkContainer {
  font-size: 14px;
  padding-top: 2px;

  a {
    text-decoration: none;
    color: $green-color;
  }
}

.customFormFooter {
  display: flex;
  justify-content: space-between;
}

.no-scroll {
  overflow: hidden;
}

.blur {
  filter: blur(3px);
}

button {
  outline: 0 !important;

  &::-moz-focus-outer,
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:active,
  &:hover,
  &:focus {
    outline: 0 !important;
  }
}

.contextMenu {
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  overflow: hidden;
  padding: 0 !important;

  .contextMenuItem {
    &:hover > div {
      background-color: var(--color-v2-global3) !important;
    }

    &.danger {
      & > div {
        color: var(--color-v2-error);
      }

      &:hover > div {
        background-color: var(--color-v2-error) !important;
      }
    }
  }
}

.greenLink {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $green-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: $green-color;
  }

  &:visited {
    color: $green-color;
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color-v2-global4-rgb), 0.8);
  z-index: 1;
}
