@import '@src/styles/vars.css';

.TaskEditor {
  flex-grow: 1;
  font-weight: normal;

  .timeButton {
    position: relative;
    background: var(--color-v2-background1);
    color: var(--color-v2-text5);
    outline: none;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
    border: none;
    padding: 4px 6px 4px 8px;
    height: auto;
    margin-top: 4px;

    &::-webkit-calendar-picker-indicator {
      background: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }

    display: none;
  }

  &.selected .timeButton {
    display: block;
  }

  &.editable .timeButton {
    background: var(--color-v2-button-fill2);
    color: var(--color-v2-text2) !important;
  }

  &.editable {
    input[type='text'] {
      padding-left: 0 !important;
      border: none !important;
    }
  }

  .styledListItem {
    min-height: 51px;

    .mark {
      align-self: flex-start;
    }
  }
}
