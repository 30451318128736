@import "@src/styles/vars.css";

.QTemplateList {
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 30px;
    padding-bottom: 45px;

    .titleContainer {
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
      }

      .description {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
        letter-spacing: -0.24px;
        margin-top: 13px;
      }
    }
  }

  .listContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-bottom: 80px;

    .listItem {
      display: flex;
      flex-direction: column;
      background: $white-color;
      box-shadow: 0 4px 25px rgba(18, 18, 19, 10%);
      border-radius: 15px;
      padding: 20px;

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: -0.4675px;
        color: $purple-color;
      }

      ul {
        padding: 14px 15px 20px;
        margin: 0;

        li {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 153.69%;
          color: $purple-color;

          span {
            color: $grey-color-name;
          }
        }
      }

      .buttContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        flex-grow: 1;
        align-items: end;
      }
    }
  }
}
