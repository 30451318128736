@import '@src/styles/vars.css';

.MyProfile {
  .ProfileContainer {
    margin-top: 18px;
    display: flex;

    .UserNameContainer {
      margin-left: 20px;
      margin-top: 15px;
      text-align: center;

      .UserName {
        font-weight: 600;
        font-size: 18px;
        color: $grey-color-name;
      }

      .ButRedactProfile {
        padding: 10px 30px;
        margin-top: 5px;
        background: $grey-light-but-color;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.41px;
        color: $grey-color-name;

        &:hover {
          background-color: $green-color;
          color: $black-text-color;
        }
      }
    }
  }
}
