@import '@src/styles/vars.css';

.SharedLink {
  .inputSharedContainer {
    display: grid;
    grid-template-columns: auto 134px;

    .inputSharedButton {
      background: $grey-light-but-color;
      border-radius: 0 12px 12px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 8px 16px;
      color: $green-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inputSharedButtonIcon {
        height: 28px;
        width: 28px;
      }

      .inputSharedButtonLabel {
        display: block;
      }
    }
  }

  .linkCopiedMessageContainer {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: max-content;
    margin: auto;

    .linkCopiedMessageHeaderContainer {
      display: flex;
      align-items: center;
      width: max-content;

      .linkCopiedMessageCheckIcon {
        height: 24px;
        width: 24px;

        svg {
          fill: $green-color;
        }
      }

      .linkCopiedMessageHeader {
        margin-left: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .linkCopiedMessage {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: $grey-color-dark;
      padding: 11px 0 0 36px;
    }
  }
}
