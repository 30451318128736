@import '@src/styles/vars.css';

.leftPane {
  width: 380px;
  height: 100%;
  display: flex;
  background: #f4eed5;
  border-radius: 10px;
  clip-path: inset(0 0 round 10px);

  .avatar {
    position: absolute;
    left: 146px;
    top: 170px;
    width: 90px;
    height: 116px;
    clip-path: path('M0,0 L91,4 L89,117 L3,116 Z');
  }

  &.wideLeft {
    width: 100%;
  }
}

.rightPane {
  position: absolute;
  right: 0;
  top: 0;
  width: 640px;
  height: 100%;
  padding-left: 120px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;

  &.forWideLeft {
    width: 480px;
    display: flex;
    align-items: center;
    padding: 130px 20px 40px 20px;
    text-align: center;
  }

  input[type='text'] {
    height: 64px;
    padding-left: 20px;
    border: 1px solid var(--color-v2-border2);
    border-radius: 8px;
    background: var(--color-v2-background1);
  }

  input {
    color: var(--color-v2-text3);
  }

  ul input[type='text'] {
    height: auto;
    padding-left: 0;
    border: none;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    color: var(--color-v2-text6);
    margin: 28px 0 12px 0;
  }

  .finalTitle {
    max-width: 330px;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.3em;
    color: var(--color-v2-text6);

    &.medium {
      font-size: 32px;
    }

    &.small {
      font-size: 24px;
    }
  }

  .description {
    font-size: 16px;
    margin: 0 0 20px 0;
    color: var(--color-v2-text3);
  }

  .largeDescription {
    max-width: 320px;
    font-size: 24px;
    margin: 34px 0 20px 0;
    color: var(--color-v2-text3);
  }

  .content {
    flex-grow: 1;
    position: relative;
    overflow-y: auto;
    margin-bottom: 48px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    & button:last-child {
      margin-left: 20px;
    }

    & .hidden {
      visibility: hidden;
    }
  }

  button {
    height: 53px;
  }
}

.centeredImage {
  margin: auto;
  align-self: center;
}

.nameDescription {
  font-size: 16px;
  margin: 16px 0 25px 0;
  color: var(--color-v2-text3);
}

.factContent {
  width: 100%;
  height: 240px;
  margin-top: 40px;
  position: relative;
  display: flex;
  justify-content: center;

  .factText {
    position: absolute;
    left: 0;
    top: 70px;
    width: 100%;
    height: 100%;
    padding: 0 40px;
    font-size: 28px;
    text-align: center;
    color: var(--color-v2-text3);

    &.smallText {
      font-size: 20px;
    }

    &.mediumText {
      font-size: 24px;
    }
  }
}

button.wide {
  width: 220px;
}

button.inverted {
  background: none;
  color: var(--color-v2-button-fill2);
  border: 1px solid var(--color-v2-button-fill2);
}

button.noBorder {
  border-color: transparent;
  white-space: pre-line;
}
