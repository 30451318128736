@import "@src/styles/vars.css";

.ToDos {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
    }
  }

  .columnContainer {
    display: flex;
    flex-direction: column;
  }

  .topMenu {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .topMenuFirst {
      display: flex;
    }

    .topMenuSecond {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .courseFilterContainerClear {
        color: $blue-grey-light-color-2;
        cursor: pointer;
        padding-left: 10px;
      }
    }

    .topMenuItem {
      background: $grey-color-1;
      border-radius: 10px;
      height: 40px;
      cursor: pointer;

      svg {
        fill: $grey-color-name;
      }
    }

    .calendarButContainer {
      position: relative;

      .calendarBut {
        padding: 10px;
        padding-top: 8px;
        margin-right: 10px;

        .calendarIcon {
          height: 22px;
          width: 22px;
          min-width: 22px;
        }

        &.calendarCloseBut {
          background: $grey-color-1;
          padding-top: 10px;
        }
      }

      .calendarContainer {
        position: absolute;
        width: 318px;
        overflow: hidden;
        box-shadow: 0 4px 15px $purple-color-opacity;
        border-radius: 10px;
        transition: all 0.3s ease;
        left: 0;
        top: 67px;
        z-index: 10;

        &.hide {
          height: 0;
        }
      }
    }

    .calendarDay {
      padding: 15px 0;
      margin-right: 10px;
      display: flex;
      align-items: center;
      cursor: default;

      .prevDayContainer,
      .nextDayContainer {
        cursor: pointer;
      }

      .prevDayContainer {
        padding-left: 20px;
      }

      .nextDayContainer {
        padding-right: 20px;
      }

      .prevDay,
      .nextDay {
        height: 15px;
        width: 15px;
        display: flex;
        align-items: center;
      }

      .day {
        padding: 0 5px;
        font-style: normal;
        font-weight: normal;
        min-width: 100px;
        font-size: 13px;
        line-height: 18px;
        flex-grow: 1;
        align-items: center;
        text-align: center;
        color: $grey-color-name;
      }
    }

    .calendarFilter {
      display: flex;
      align-items: center;
      margin-top: initial;
      max-width: 300px;
      cursor: pointer;
      border-radius: 10px;
      background: $grey-color-1;
      height: 40px;

      svg {
        right: 20px !important;
        width: 15px !important;
        fill: $grey-color-name;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $blue-grey-light-color-2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        margin-right: 24px;
      }

      ul {
        max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 6px;

        li {
          cursor: pointer;
        }
      }
    }
  }

  .eventsListContainer {
    display: block;
    flex-grow: 1;
    position: relative;
    min-height: 350px;

    .eventsList {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
      }

      &.no-scroll {
        overflow: hidden;
      }

      .dayEventsContainer {
        background: $white-color;
        box-shadow: 0 4px 15px $purple-color-opacity;
        border-radius: 15px;
        margin-bottom: 20px;
        overflow: hidden;

        .dayContainer {
          padding: 15px;
          display: flex;
          align-items: center;

          .day {
            display: flex;
            align-items: center;

            .isToday {
              background: $grey-color-dark;
              border-radius: 5px;
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 14px;
              text-align: center;
              margin-left: 10px;
              padding: 3px 10px;
              color: $white-color;
            }

            .dayNum {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 25px;
              margin-left: 10px;
            }

            .dayName {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 25px;
            }
          }
        }

        .eventsContainerInList {
          flex-grow: 1;

          .toDosEventContainer {
            background: $white-color;
            box-shadow: 0 4px 15px $purple-color-opacity;
            border-radius: 15px;
            padding: 15px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .eventIcon {
              height: 40px;
              width: 40px;
              padding: 7px;
              border-radius: 50%;
              position: relative;
              margin-right: 15px;
              flex-shrink: 0;

              svg {
                fill: $white-color;
              }

              &.eventIconTask {
                background:
                  linear-gradient(
                    180deg,
                    $green-blue-color-text 0%,
                    $light-turquoise-1 100%
                  );
              }

              &.eventIconConference {
                background:
                  linear-gradient(
                    180deg,
                    $purple-color 0%,
                    $medium-magenta 100%
                  );
              }

              .eventAvatar {
                height: 20px;
                width: 20px;
                position: absolute;
                bottom: -4px;
                right: -4px;
              }
            }

            .infoContainer {
              flex-grow: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .nameEventContainer {
                width: 60%;

                .nameEvent {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 20px;
                  width: 50%;
                  max-width: 400px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .durationEvent {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 18px;
                  color: $grey-color-name;
                }
              }

              .butEventContainer {
                .butEventTimeContainer {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  .butEventTimeIcon {
                    height: 15px;
                    width: 15px;
                    display: flex;
                    align-items: center;
                    margin-right: 7px;
                    flex-shrink: 0;
                  }

                  .butEventTime {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 18px;
                  }
                }

                .butEvent {
                  .butEdit {
                    background: $purple-color;
                    border-radius: 5px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-top: 5px;
                    color: $white-color;
                  }

                  .courseSize {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    text-align: right;
                    color: $grey-color-name;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ToDosMenu {
  min-width: 40%;

  .CreateEventBut {
    display: block;
  }
}

.eventsContainer {
  margin-top: 43px;

  .daysContainer {
    display: grid;
    grid-template-columns: 100px auto;
    max-width: 800px;
    column-gap: 20px;

    .dayTitle {
      background: $grey-light-color;
      border-radius: 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      padding: 14px 17px;
    }

    .dayEventsContainer {
      margin-bottom: 10px;

      .dayEvent {
        background: $grey-light-color;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 10px 20px 10px 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .dayEventsIcon {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          flex-shrink: 0;

          .dayEventsIcon {
            height: 15px;
            width: 17px;
            margin-top: 25%;
            margin-left: 24%;

            svg {
              fill: $white-color;
            }
          }

          &.dayEventsIconTask {
            background:
              linear-gradient(
                180deg,
                $green-blue-color-text 0%,
                $green-color 100%
              );
          }

          &.dayEventsIconConference {
            background:
              linear-gradient(
                180deg,
                $medium-magenta 0%,
                $purple-color 100%
              );
            transform: matrix(1, 0, 0, -1, 0, 0);

            .dayEventsIcon {
              margin-left: 30%;
            }
          }
        }

        .dayEventsTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          height: 20px;
          flex-grow: 1;
          margin-left: 15px;
        }

        .dayEventsTimeContainer {
          display: flex;

          .dayEventsTimeIcon {
            margin-right: 3px;
            height: 20px;
            padding-top: 2px;
            width: 20px;
          }

          .dayEventsTime {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 22px;
            height: 20px;
          }
        }
      }
    }
  }
}

.DatePublication h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.TasksListItem,
.ConferencesListItem {
  border-bottom: 1px solid $grideperlevy;
  padding: 20px 20px 20px 15px;
  cursor: pointer;

  &.isSelect {
    background: $grey-light-color;
  }

  &:hover {
    background: $grey-light-color;
  }

  .contentWrapper {
    display: flex;
    flex-grow: 1;

    .flex1 {
      flex-grow: 1;
    }

    .statusContainer {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      color: $grey-color-name;

      &.isOverdue {
        color: $red-notify-color;
      }
    }

    .iconContainer {
      background: $light-turquoise-2;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 15px;
      flex-shrink: 0;

      svg {
        fill: $green-blue-color-text;
      }

      .programAvatar {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -4px;
        right: -4px;
      }

      .liveLabelContainer {
        position: absolute;
        top: 3px;
        font-size: 8px;
        background-color: var(--color-v2-error);
        border-radius: 20px;
        padding: 2px 5px;
        color: var(--light-color-v2-text2);
      }

      &.iconContainerConference {
        background: $moderate-slate-blue-light-2;

        svg {
          fill: $purple-color;
        }
      }

      &.isForCoachTask {
        background: $light-turquoise-2;

        svg {
          fill: $green-blue-color-text;
        }
      }

      &.isDone {
        background: $grideperlevy;

        svg {
          fill: $grey-color-name;
        }
      }

      &.isOverdue {
        background: $periwinkle-crayola-3;

        svg {
          fill: $red-notify-color;
        }
      }
    }

    .contentContainer {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: $grey-color-name;
      }

      .courseDuration {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: $grey-color-name;
      }

      .time {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: $black-text-color;
      }

      .statusStyle {
        display: block;
      }
    }

    .moreButt {
      padding-left: 10px;
      cursor: pointer;
    }
  }

  .subContentContainer {
    display: block;
  }

  .button {
    background: $purple-color;
    border-radius: 5px;
    display: inline;
    margin-right: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 12px;
    color: $white-color;
    height: max-content;
    margin-left: 10px;
  }
}

.Task {
  background: $white-color;
  box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
  border-radius: 15px;
  overflow: hidden;

  .taskCardLoadingContainer {
    display: block;
  }

  .headerContainer {
    padding: 20px;

    .taskDueDateContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;

      .taskTag {
        background: $green-blue-color-text;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        align-items: center;
        padding: 2px 4px;
        border-radius: 4px;
        color: $white-color;

        &.overdueTaskTag {
          background: $red-notify-color;
        }
      }

      .taskDueDate {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: -0.08px;
        color: $grey-color-name;
      }
    }

    .taskTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
    }

    .taskDescriptionContainer {
      padding-top: 8px;

      .taskDescription {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.24px;
      }
    }
  }

  .sectionContainer {
    padding-bottom: 20px;

    .sectionHeaderContainer {
      padding: 15px 20px 10px;

      .sectionHeaderTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
      }
    }

    .subHeaderContainer {
      display: block;

      .subHeaderContentContainer {
        display: block;

        .subHeaderRowAvatar {
          display: block;
        }

        .subHeaderRowLabel {
          display: block;
        }

        .subHeaderRowTitle {
          display: block;
        }
      }

      .subHeaderRow {
        display: flex;
        align-items: center;
        padding: 0 20px 20px;

        .avatarContainer {
          height: 32px;
          width: 32px;
          margin-right: 10px;
          flex-shrink: 0;

          svg {
            fill: $grey-color-dark;
          }

          .subHeaderRowAvatar {
            height: 100%;
            width: 100%;
            background:
              linear-gradient(
                0deg,
                $grey-light-color,
                $grey-light-color
              );
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .subHeaderRowLabel {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.41px;
          color: $grey-color-name;
        }

        .subHeaderRowTitle {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: -0.41px;
        }
      }

      .subHeaderButtonsContainer {
        display: block;

        .subHeaderButton {
          display: block;
        }

        .subHeaderEditButton {
          display: block;
        }
      }
    }
  }

  .clientViewContainer {
    padding: 0 20px;
  }

  .commentInputContainer {
    padding-top: 20px;
    padding-bottom: 20px;

    textarea {
      background: $white-color;
      border: 1px solid $grey-blue2-color;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      &:placeholder, {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $grey-color-name;
      }
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }

  .attachmentsContainer {
    .attachmentsButContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      .addPhotoButton,
      .addMealButton {
        height: initial;
        padding: 15px;
        background: $grey-light-color;
        border-radius: 6px;
        display: flex;
        align-items: center;
      }

      .attachmentIcon {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .attachmentButtonText {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $black-text-color;
      }
    }

    .attachmentsImageContainer {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;

      .container {
        width: 100%;
        position: relative;

        &::after {
          content: "";
          display: block;
          padding-top: 100%;
        }

        .content {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;

          .contentImg {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .removeAttachmentButton {
            position: absolute;
            height: 16px;
            width: 16px;
            background-color: $black-text-color;
            border-radius: 50%;
            top: -5px;
            right: -5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            flex-shrink: 0;

            svg {
              fill: $white-color;

              path {
                stroke: $white-color;
              }
            }
          }
        }
      }
    }

    .attachmentsMealContainer {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;

      .containerEl {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin-right: initial;
        cursor: pointer;
        background: linear-gradient(180deg, $green-color 0%, $green-color 100%);
        border-radius: 10px;
        position: relative;

        &::after {
          content: "";
          padding-top: 100%;
          display: block;
        }

        .attachmentIconContainer {
          position: absolute;
          width: 100%;

          .attachmentIcon {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 13px;
            margin-bottom: 4px;
            flex-direction: column;
            align-items: center;
            fill: $white-color;
          }

          .attachmentText {
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 10px;
            text-align: center;
            letter-spacing: -0.08px;
            color: $white-color;
          }
        }
      }
    }
  }

  .paginationFooter {
    display: block;
  }

  .footerButtonContainer {
    padding: 0 20px 20px;

    .doneButton {
      display: block;
    }

    .doneButtonContent {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -10px;

      .doneButtonCheckMark {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: $white-color;
        }
      }

      .doneButtonText {
        display: block;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }
}

.ClientTasksListItem {
  padding: 5px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: $grey-light-color;
  }

  &.isSelect {
    background: $grey-light-color;
  }

  .userContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .avatarContainer {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      flex-shrink: 0;

      .userName {
        display: block;
      }

      .infoContainer {
        display: block;
      }
    }

    .infoContainer {
      svg {
        fill: $grey-color-dark;
      }
    }
  }

  .checkIconContainerStyle {
    background: $grideperlevy;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    svg {
      fill: $grey-color-text;
    }

    &.isDone {
      background: $light-turquoise-2;

      svg {
        fill: $green-blue-color-text;
      }
    }
  }
}

.ClientTask {
  background: $white-color;
  box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
  border-radius: 15px;
  padding: 35px 25px 20px;

  .ClientTaskHeader {
    padding-bottom: 30px;

    .clientRow {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template-columns: auto 100px auto;
      padding-bottom: 15px;

      .clientButtonInfoContainer {
        width: 100%;

        .clientButtonInfo {
          width: 40px;
          height: 40px;
          background: $grideperlevy;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $blue-grey-light-color-2;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .bigUserAvatar {
        width: 100px;
        height: 100px;
      }

      .clientButtonChatContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .clientButtonChat {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background:
            linear-gradient(
              180deg,
              $green-color 0%,
              $green-color 100%
            );
          transform: scale(-1, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          flex-shrink: 0;
        }
      }
    }

    .clientName {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
    }
  }

  .ClientTaskContainer {
    display: block;

    .taskRowContainer {
      display: flex;
      align-items: flex-start;
      padding-bottom: 10px;

      .taskRowAvatar {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background: $grideperlevy;
        border-radius: 50%;
        flex-shrink: 0;

        svg {
          fill: $grey-color-dark;
        }
      }

      .flex1 {
        flex-grow: 1;
        width: calc(100% - 32px);
      }

      .taskRowLabel {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.41px;
        color: $grey-color-name;
      }

      .taskDescription {
        font-style: normal;
        font-size: 13px;
        line-height: 18px;
      }

      .attachmentsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 9px;
        padding-top: 7px;

        .taskRowAvatar {
          width: 100%;
          height: auto;
          border-radius: 10px;
          overflow: hidden;
          margin-right: initial;
          cursor: pointer;

          &::after {
            content: "";
            padding-top: 100%;
            display: block;
          }

          .className {
            display: block;
          }

          .profileClassName {
            border-radius: initial;
          }
        }

        .mealAttachmentContainer {
          width: 100%;
          height: auto;
          overflow: hidden;
          margin-right: initial;
          cursor: pointer;
          background:
            linear-gradient(
              180deg,
              $green-color 0%,
              $green-color 100%
            );
          border-radius: 10px;
          position: relative;

          &::after {
            content: "";
            padding-top: 100%;
            display: block;
          }

          .mealAttachment {
            position: absolute;
            width: 100%;

            .attachmentIcon {
              width: 100%;
              display: flex;
              justify-content: center;
              margin-top: 13px;
              margin-bottom: 4px;
              fill: $white-color;
            }

            .attachmentText {
              font-style: normal;
              font-weight: 600;
              font-size: 9px;
              line-height: 10px;
              text-align: center;
              letter-spacing: -0.08px;
              color: $white-color;
            }
          }
        }
      }

      .taskRowTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: -0.41px;
      }
    }
  }

  .footerContainerTopGradient {
    display: block;
  }
}

.MealInput {
  display: block;

  .inputContainer {
    padding-bottom: 20px;

    textarea:focus {
      outline: none;
    }

    textarea {
      background: $white-color;
      border: 1px solid $grey-blue2-color;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      &:placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $grey-color-name;
      }
    }

    .mealProcessingLabel {
      display: block;
    }
  }

  .ingredientsContainer {
    padding-left: 20px;

    .ingredientRow {
      display: flex;
      align-items: center;

      .ingredientRowText {
        padding-left: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .waterSectionHeader {
    padding-top: 20px;

    .waterHeaderTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      padding-bottom: 5px;
    }

    .waterHeaderSum {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.08px;
      color: $grey-color-name;
      padding-bottom: 10px;
    }
  }

  .glassesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 575px;
    justify-items: center;
    margin: auto;
    height: 150px;

    .glass {
      position: relative;
      height: 70px;

      .glassBg {
        display: block;
      }

      .glassIcon {
        position: absolute;
        top: 0;
      }

      .plusIcon {
        position: absolute;
        top: 8px;
        left: 3px;
      }

      svg {
        display: block;
      }

      .glassFillContainer {
        position: absolute;
        top: 7px;
        left: 2px;

        .glassFill {
          display: block;
        }

        .dropIcon {
          position: absolute;
          top: 15px;
          left: 10px;
        }
      }

      .glassCheckContainer {
        position: absolute;
        bottom: 18px;
        left: 10px;

        .glassCheckIcon {
          display: block;
        }
      }
    }
  }
}

.mealDescriptionRow {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  .mealDescriptionLabel {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: end;
    letter-spacing: -0.24px;
    padding-right: 10px;
  }

  .mealDescription {
    display: block;
  }
}

.mealRow {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  .mealTimeText {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: end;
    letter-spacing: -0.24px;
    padding-right: 10px;
  }

  .mealQty {
    display: block;
  }
}

.mealTimeRowContainer {
  padding-top: 20px;

  .mealTimeRow {
    display: block;

    .mealTimeRowButton {
      display: flex;
      align-items: center;

      .mealText {
        padding-right: 10px;
      }
    }
  }
}
