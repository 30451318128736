@import "@src/styles/vars.css";

.HeaderPractice {
  display: block;

  .headerPracticeContainer {
    display: grid;
    grid-template-columns: 35% 65%;
    grid-column-gap: 20px;
    margin-bottom: 37px;

    .imgContainer {
      .HeaderImg {
        height: 290px;
        position: relative;
        border-radius: 15px;
        overflow: hidden;

        img {
          display: block;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }

        .OverlayImage {
          background:
            linear-gradient(
              0deg,
              rgba(0, 0, 0, 5%),
              rgba(0, 0, 0, 5%)
            );
        }
      }
    }

    .textContainer {
      margin: 30px;

      .category {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.4675px;
        margin-bottom: 18px;
        color: $grey-color-dark;
      }

      h2.title {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 49px;
        margin-bottom: 20px;
        color: $black-text-color;
      }

      .coachContainer {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .coachAvatar {
          height: 40px;
          width: 40px;
          margin-right: 10px;
          border-radius: 20px;
          overflow: hidden;
        }

        .nameContainer {
          .coachName {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.4675px;
            color: $black-text-color;
          }
        }
      }

      .description {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
        letter-spacing: -0.24px;
        white-space: pre-line;
        color: $black-text-color;
      }
    }
  }
}
