@import '@src/styles/vars.css';

.Invites {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tabsHeader {
    background: $grey-light-color;
    padding: 0 30px 9px 30px;

    .menuItem {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .tabsContainer {
    flex-grow: 1;
    padding: 10px 30px 30px 30px;

    .InviteByName,
    .InviteByEmail {
      max-height: 40vh;
      height: calc(80vh - 160px);

      .inviteView {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
      }

      .invitePreViewContainer {
        background: $grey-light-but-color;
        border-radius: 10px;
        padding: 20px;
        margin-top: 15px;
      }
    }
  }
}

.NoResults {
  min-height: 20vh;

  .iconNoResultContainer {
    svg {
      fill: $grey-color-dark;
    }
  }
}

.usersContainer {
  margin-top: 15px;

  .userRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;

    &:not(:first-child) {
      border-top: 1px solid $blue-grey-light-color;
    }

    .userContainer {
      display: flex;
      align-items: center;

      .userAvatar {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      .userName {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .addBut {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icoUserPlus {
        height: 22px;
        width: 22px;
        margin-right: 10px;
      }

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $grey-color-name;
      }
    }
  }
}

.textArea {
  width: 100%;
  border: 1px solid $blue-grey-light-color;
  border-radius: 6px;
  margin-top: 20px;
}

.userMessage {
  border-top: 1px solid $blue-grey-light-color;
  padding-top: 10px;
}

.searchContainer {
  padding: inherit;
  background: initial;
  margin-top: 20px;
  margin-bottom: 20px;
}

.containerInput {
  margin-top: 20px;
  margin-bottom: 20px;
}

.boldGreen {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: $green-blue-color-text;
}

.greenBut {
  margin-top: 10px;
  background: $green-blue-color-text;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  padding: 8px 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .createSquadIcon {
    display: flex;
    height: 17px;
    width: 17px;
    background: $white-color-opacity;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: $white-color;
    font-size: 14px;
    padding-bottom: 2px;
    margin-right: 12px;
  }

  .greenButTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $white-color;
  }
}

.InviteByEmail {
  .inputContainer {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

    .addBut {
      height: 22px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      width: 83px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $grey-color-name;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.SendsElements {
  background: $white-color;
  margin-bottom: 5px;
  height: 64px;
  overflow-y: auto;
  display: flex;

  .containerBubbles {
    display: flex;
    flex-wrap: wrap;
  }

  .allBut {
    height: 30px;
    display: flex;
    align-items: center;
    background: $grey-light-but-color;
    width: fit-content;
    border-radius: 15px;
    padding-left: 14px;
    padding-right: 10px;
    margin-right: 10px;
    margin-bottom: 2px;

    .allName {
      width: max-content;
    }
  }

  .userContainer,
  .emailContainer {
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    background: $grey-light-but-color;
    width: fit-content;
    border-radius: 15px;
    padding-left: 14px;
    padding-right: 10px;
    margin-right: 10px;
    margin-bottom: 2px;

    .userAvatar {
      height: 19px;
      width: 19px;
      margin-right: 9px;
    }

    .userName {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $black-text-color;
      margin-right: 11px;
    }

    .closeBut {
      height: 18px;
      width: 18px;
    }
  }
}

.buttonContainer {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttonSend {
    max-width: 180px;
  }

  .addButContainer {
    max-width: 100px;

    .addBut {
      margin: initial;

      span {
        margin-left: 0;
      }
    }
  }
}
