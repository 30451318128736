@import '@src/styles/vars.css';

.Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: $opacity-black;
  overflow-y: scroll;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

.GoalModal_content {
  padding: 30px 48px 33px 44px;
}
