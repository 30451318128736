@import '@src/styles/vars.css';

.DropdownMenu {
  position: absolute;
  right: 0;
  background-color: $grey-blue-light-color;
  padding: 10px;
  border-radius: 6px;
  z-index: 10;
}
