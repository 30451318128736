@import "@src/styles/vars.css";

.Status {
  .grid {
    display: grid;
    grid-template-columns: auto 200px 200px 99px;
    align-items: center;
    padding: 15px 0;

    &.row {
      border-bottom: 1px solid $blue-grey-light-color;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .header {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
    color: $grey-color-dark;
  }

  .qComplete {
    color: $green-blue-color-text;
  }

  .qInProgress {
    color: $purple-color;
  }

  .qSent {
    color: $grey-color-dark;
  }
}

.userContainer {
  display: flex;
  align-items: center;
}

.userAvatar {
  height: 48px;
  width: 48px;
  margin-right: 15px;
}

.Result {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  .header {
    padding: 30px 40px;

    .headerUserRow {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sent {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $grey-color-name;
      }
    }
  }

  .body {
    height: 100%;
    flex-grow: 1;
    padding: 30px 40px 10px;
    overflow-y: auto;
    background: $white-color;
    box-shadow: 0 6px 20px rgba(140, 147, 162, 10%);
    border-radius: 10px;

    .scrollContainer {
      height: 100%;

      .sectionContainer {
        margin-bottom: 30px;

        .sectionNum {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: $grey-color-dark;
        }

        .sectionName {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 27px;
          color: $purple-color;
        }

        .sectionDescription {
          margin-top: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
        }

        .questionsContainer {
          .questionContainer {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.null {
  display: none !important;
}
