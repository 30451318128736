@import '@src/styles/vars.css';

.QSendUsers {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  .noUserContainer {
    margin-top: 20%;

    img {
      margin: auto;
    }
  }

  .textNoContent {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    padding-top: 28px;
    width: 118px;
    margin: auto;
    color: $grey-color-dark;
  }

  .HeaderQSendUsers {
    padding: 23px 40px 0 40px;

    .menuContainer {
      margin-top: 30px;

      .menu {
        display: flex;

        .menuItem {
          margin-right: 40px;
          cursor: pointer;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 23px;
          padding-bottom: 9px;
          color: $grey-color-name;

          &.selected {
            color: $black-text-color;
          }
        }
      }
    }
  }

  .SendsElements {
    background: $white-color;
    display: flex;

    .containerBubbles {
      display: flex;
      flex-wrap: wrap;
    }

    .allBut {
      height: 30px;
      display: flex;
      align-items: center;
      background: $grey-light-but-color;
      width: fit-content;
      border-radius: 15px;
      padding-left: 14px;
      padding-right: 10px;
      margin-right: 10px;
      margin-bottom: 2px;

      .allName {
        width: max-content;
      }
    }

    .userContainer,
    .emailContainer {
      cursor: pointer;
      height: 30px;
      display: flex;
      align-items: center;
      background: $grey-light-but-color;
      width: fit-content;
      border-radius: 15px;
      padding-left: 14px;
      padding-right: 10px;
      margin-right: 10px;
      margin-bottom: 2px;

      .userAvatar {
        height: 19px;
        width: 19px;
        margin-right: 9px;
      }

      .userName {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $black-text-color;
        margin-right: 11px;
      }

      .closeBut {
        height: 18px;
        width: 18px;
      }
    }
  }

  .BodyQSendUsers {
    height: 55vh;
    min-height: 250px;
    flex-grow: 1;
    padding: 15px 40px 40px 40px;
    background: $white-color;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .mainContainer {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
    }

    .searchContainer {
      padding: 0;
      background: none;
      border-radius: 0;

      .searchContainerInput {
        border: 1px solid $blue-grey-color;
        border-radius: 10px;
      }
    }

    .resultContainer {
      margin-top: 22px;
      flex-grow: 1;
      overflow-y: auto;

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 10px;
      }
    }

    .footerButContainer {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;

      .footerAddAllBut {
        width: 107px;
      }

      .SendQBut {
        min-width: 187px;
      }
    }

    .filterContainer {
      display: flex;
      justify-content: space-between;

      .filterButContainer {
        display: flex;

        .filterBut {
          margin-right: 10px;
          background: $grey-light-but-color;
          border-radius: 20px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.192px;
          padding: 10px 15px;
          cursor: pointer;
          color: $grey-color-name;

          &.selected {
            background: $green-blue-color-text;
            color: $white-color;
          }
        }
      }
    }

    .listUserContainer {
      .userRow {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        border-bottom: 1px solid $grey-dark-blue2-color;

        &:last-child {
          border-bottom: none;
        }

        .userContainer {
          display: flex;
          align-items: center;

          .userAvatar {
            width: 40px;
            height: 40px;
            margin-right: 21px;
          }

          .userName {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $black-text-color;
          }
        }
      }
    }

    .addBut {
      height: 22px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      width: 83px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $grey-color-name;

      .icoUserPlus {
        fill: $grey-color-name;
      }
    }

    .inputContainer {
      position: relative;

      .addBut {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
