@import "@src/styles/vars.css";

.MailchimpUnsubscribe {
  background: $grey-light-color;
  min-height: 100vh;
  padding-top: 10vh;
  padding-left: 10px;
  padding-right: 10px;

  .highlight {
    font-weight: bold;
  }

  .unsubscribeContainer {
    background: $white-color;
    box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
    border-radius: 15px;
    padding: 40px;
    padding-bottom: 80px;
    max-width: 900px;
    margin: auto;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      padding: 20px 0 40px;
    }

    .message {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    .messageSad {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    .bentleyContainer {
      height: 200px;
      width: 200px;
      background: $grey-light-color;
      overflow: hidden;
      border: 4px solid transparent;
      border-radius: 50%;
      box-shadow:
        0 -5px 10px 2px rgba(34, 60, 80, 20%) inset,
        0 0 0 5px $grey-color-name-opacity,
        0 0 5px 5px $grey-color-dark;
      background-origin: border-box;
      background-clip: content-box, border-box;
      margin: auto;
      margin-top: 40px;

      img {
        object-fit: cover;
      }
    }
  }
}
