@import "@src/styles/vars.css";

.Questionnaire {
  h3 {
    font-size: 20px !important;
    margin-top: 40px;
  }

  .linksContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    row-gap: 30px;
    margin-top: 27px;

    .buttUserQ {
      border-radius: 15px;
      background-color: rgb(218, 218, 218);
      position: relative;
      height: 160px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .link {
        .overlay {
          height: 100%;
          width: 100%;
          border-radius: 15px;
          background:
            linear-gradient(
              360deg,
              rgba(0, 0, 0, 80%) 0%,
              rgba(0, 0, 0, 0%) 50%
            );
        }
      }

      .textContainer {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        padding: 0 0 15px 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: $white-color;

        .text {
          flex-grow: 1;

          a {
            color: $white-color;
            text-decoration: none;
          }

          span {
            /* stylelint-disable-next-line value-no-vendor-prefix */
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }

        .more {
          padding: 0 15px;
          font-size: 26px;
          cursor: pointer;
        }
      }
    }

    .dropdownMenu {
      background: $white-color;
      border: 0.5px solid $grey-blue2-color;
      box-sizing: border-box;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 15%);
      border-radius: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: $black-text-color;

      a {
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        cursor: pointer;
        color: $black-text-color;
        display: block;
        padding-right: 36px;
        padding-bottom: 15px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .butt {
      background: $mint-green-color;
      border: 2px dashed $green-color-text;
      border-radius: 15px;
      text-decoration: none;
      display: block;
      padding-top: 53px;
      padding-bottom: 46px;
      width: 100%;

      p {
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        color: $green-color-text;

        &.pluse {
          font-size: 26px;
          line-height: 1;
          font-weight: initial;
        }
      }
    }
  }
}
