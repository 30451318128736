@import "@src/styles/vars.css";

.Programs {
  .programsHeader {
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;

    h3.programsHeaderTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.4675px;
      color: $black-text-color;
    }
  }

  .programsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 100px;
    margin-top: 20px;

    .programContainer {
      height: 220px;

      .programLink {
        text-decoration: none;
      }
    }
  }
}
