@import '@src/styles/vars.css';

.mainContainer {
  width: 100%;
  height: 100vh;
}

.columnsLayout {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #fff;
}

.colLeft {
  width: 440px;
  padding-left: 40px;
  background-color: #fff;
  transition: width 0.5s ease-in-out;

  .chatsColumn {
    background: #fff;
    height: 100%;
  }
}

.colLeftNarrow {
  width: 180px;
}

.colCenter {
  display: flex;
  flex-grow: 1;
  min-width: 720px;
  background: #f1f5f6;
  border-radius: 50px 0 0;
}

.colCenterInner {
  width: 940px;
  height: 100%;
  transition: width 0.5s ease-in-out;
  background: #f1f5f6;
  border-radius: 50px 0 0;
}

.colCenterInnerExpanded {
  width: 820px;
}

.colRight {
  width: 0;
  transition: width 0.5s ease-in-out;
  background: #f1f5f6;
  overflow: hidden;
  padding-top: 40px;
  padding-left: 24px;

  div {
    visibility: hidden;
  }
}

.colRightWide {
  width: 640px;
  border-left: 2px solid #e5ecf0;

  div {
    visibility: visible;
  }
}

.expandButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  top: 40px;
  background-color: #fff;
  border: 1px solid #f1f5f6;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(1, 1, 1, 5%);
  cursor: pointer;
  width: 120px;
  height: 32px;
  font-size: 12px;
  color: #819aa9;
}

.arrowContainer {
  margin-right: 10px;

  .arrowIcon {
    width: 7px;
    height: 12px;
  }
}

.header {
  transition: font-size 0.5s ease-in-out;
}

.headerL {
  font-size: 36px;
  font-weight: 700;
}

.headerS {
  font-size: 24px;
  font-weight: 700;
}
