@import "@src/styles/vars.css";

.CustomSelect {
  width: 100%;
  background: $white-color;
  border: 1px solid $grey-border-input-color;
  border-radius: 6px;
  position: relative;
  margin-top: 26px;
  padding: 13px 20px;
  height: 48px;

  &.qListStyle {
    margin-top: initial;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $grey-green1-color;
    margin-bottom: 5px;
    padding-left: 0;
    cursor: pointer;

    label {
      left: 0;
    }
  }

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: block;
    color: $black-text-color;
  }

  .CustomSelectArrow {
    width: 12px;
    transform: rotate(-90deg);
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .CustomSelectCalendar {
    width: 24px;
    position: absolute;
    right: 0;
    top: 15px;
  }

  &.open {
    .CustomSelectArrow {
      transform: rotate(90deg);
      top: -5px;
    }
  }

  label {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    font-weight: 300;
    transition: all 1s ease 0s;
    top: 13px;
    left: 25px;
    color: $grey-color-label;

    &.activeLabel {
      top: -25px;
      left: 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  ul {
    position: absolute;
    top: 48px;
    left: 0;
    background: $white-color;
    display: block;
    margin: 0;
    width: 100%;
    border: 1px solid $grey-border-input-color;
    border-radius: 0 0 6px 6px;
    margin-top: -3px;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
    z-index: 50;

    li {
      list-style-type: none;
      padding: 5px 20px;

      &.activeSelectOption,
      &:hover {
        background-color: $grey-color;
      }
    }
  }
}

.CustomSelectV2 {
  position: relative;
  padding: 20px 0 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border-bottom: 1px solid $grey-green1-color;
  border-radius: 0;

  &.qListStyle {
    display: block;

    label {
      top: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4675px;
      padding: 0;
      color: $black-text-color;
    }
  }

  span {
    display: block;
    height: 19px;
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.41px;
    color: $grey-color-dark;
  }

  .CustomSelectArrow {
    width: 12px;
    transform: rotate(-90deg);
    position: absolute;
    right: 10px;
    top: 20px;
  }

  .CustomSelectCalendar {
    width: 24px;
    position: absolute;
    right: 0;
    top: 15px;
  }

  &.open {
    .CustomSelectArrow {
      transform: rotate(90deg);
      top: -5px;
    }
  }

  label {
    position: absolute;
    top: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4675px;
    padding: 0;
    color: $black-text-color;

    &.activeLabel {
      display: block;
    }
  }

  ul {
    position: absolute;
    top: 65px;
    left: 0;
    background: $white-color;
    display: block;
    margin: 0;
    width: 100%;
    border: 1px solid $grey-border-input-color;
    border-radius: 0 0 6px 6px;
    margin-top: -3px;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
    z-index: 50;

    li {
      list-style-type: none;
      padding: 5px 20px;

      &.activeSelectOption,
      &:hover {
        background-color: $grey-color;
      }
    }
  }
}
