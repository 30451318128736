.CardsListItem {
  .actionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-right: 5px;

    span {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.08px;
    }

    .actionIconContainer {
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--color-v2-icon3);
      }
    }
  }
}
