@import '@src/styles/vars.css';

.MyTasks {
  position: relative;

  .addTaskBut {
    position: absolute;
    top: 0;
    right: 0;
  }
}
