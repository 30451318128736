@import '@src/styles/vars.css';

.QCreateCustom {
  .mainHeader {
    background: $mint-green-color;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(178, 187, 198, 0.2);

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      padding-top: 33px;
      padding-bottom: 48px;
    }
  }

  .body {
    padding-top: 60px;
    padding-bottom: 60px;

    .inputContainer {
      max-width: 480px;
      margin-bottom: 30px;

      &.mt46 {
        margin-top: 46px;
      }
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-self: center;
      border-bottom: 1px solid $grey-green1-color;
      padding-bottom: 29px;
      margin-bottom: 26px;

      .mt3 {
        margin-top: 4px;
      }
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
    }

    .step {
      width: 100%;

      .footer {
        bottom: 43px;
        margin-left: -50px;

        .right {
          text-align: right;
        }
      }
    }

    .Step3 {
      .sectionsQuestionContainer {
        display: grid;
        grid-template-columns: 337px auto;
        gap: 63px;

        .sectionsContainer {
          .sectionList {
            ul {
              margin-left: 0;
              padding-left: 0;
              margin-top: 0;

              li {
                list-style-type: none;
                background: $grey-light-color;
                border-radius: 10px;
                padding: 10px;
                margin-bottom: 20px;
                cursor: pointer;

                &.sectionActive {
                  border: 1px solid $purple-color;
                }

                .sectionNum {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 16px;
                  color: $grey-color-dark;
                }

                .sectionTitle {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 25px;
                }
              }
            }
          }
        }
      }
    }

    .Step4 {
      .header {
        width: 815px;
        background:
          linear-gradient(
            0deg,
            $grey-light-but-color,
            $grey-light-but-color
          );
        border-radius: 16px;
        padding: 30px;

        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 27px;
        }

        .description {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          margin-top: 12px;
          white-space: pre-line;
        }

        .numQuestions {
          margin-top: 23px;

          div {
            display: inline-block;
            width: 18px;
            height: 18px;
            text-align: center;
            color: $grey-color-name;
            line-height: 14px;
            border: 2px solid $grey-color-name;
            font-size: 14px;
            font-weight: 600;
            border-radius: 50%;
            margin-right: 12px;
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .questionsBody {
        width: 815px;

        .section {
          margin-top: 50px;

          .sectionTitle {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            color: $grey-color-dark;
          }

          .sectionDescription {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 25px;
            color: $grey-color-dark;
          }

          .questionsContainer {
            margin-top: 30px;

            .textInput {
              max-width: 480px;
            }
          }
        }
      }
    }
  }
}

.modalSection {
  max-height: 90vh !important;
  height: initial !important;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.UpdateSectionQ {
  .header {
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: $grey-color-dark;
    }

    .description {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
      color: $grey-color-dark;
    }
  }
}

.AddQuestion {
  .movingField {
    background: $grey-light-color;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 14px;
    color: $grey-color-name;
    margin-top: 10px;

    .movingIcon {
      background: $grideperlevy;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 5px 10px;
    }

    .movingText {
      padding: 5px 10px;
    }
  }
}

.editLogoContainer {
  display: flex;
  align-items: center;

  .logo {
    height: 50px;
    width: 50px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 6px;
    overflow: hidden;
    fill: var(--color-v2-button-border3);
  }
}

.editPhotoButton {
  margin-left: 20px;
}
