@import '@src/styles/vars.css';

.CoachLogo {
  height: 50px;
  width: 50px;
  border-radius: 6px;
  clip-path: inset(0 0 round 10px);
  cursor: pointer;
  fill: var(--color-v2-button-border3);
}

.AddCertificate {
  .switchContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .actionButtonsContainer {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

.modal {
  z-index: 2000;
}
