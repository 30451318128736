@import '@src/styles/vars.css';

.DayPicker__horizontal > div > div {
  .DayPicker_transitionContainer {
    .CalendarMonthGrid__horizontal {
      .CalendarMonth {
        .CalendarDay__default {
          width: 39px !important;
          height: 35px !important;
          border: none;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: $grey-dark !important;

          .weekend {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: $grey-color-name;
          }

          .today {
            background-color: $green-color;
            height: 26px;
            width: 26px;
            margin: auto;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white-color;
          }

          &:hover {
            background: none;
            border: none;
            color: inherit;
          }
        }
      }
    }
  }

  .DayPickerNavigation__horizontal {
    display: flex;
    width: 100%;
    top: 25px;
    position: absolute;
    justify-content: space-between;
    padding: 0 12px;

    .DayPickerNavigation_button {
      width: 20px;
      height: 20px;
    }
  }

  .CalendarMonth_caption {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 50px;
    padding-top: 26px;
    text-align: center;
    color: $grey-color-dark;
  }

  .DayPicker_weekHeaders__horizontal {
    margin-left: 0;

    .DayPicker_weekHeader {
      padding: 0 17px !important;

      .DayPicker_weekHeader_ul {
        display: flex;
        padding: 0 5px;
        justify-content: space-between;

        .DayPicker_weekHeader_li {
          font-style: normal;
          font-weight: normal;
          font-size: 12px !important;
          line-height: 18px;
          text-align: center;
          color: $grey-color-dark;
        }
      }
    }
  }
}

.MyCalendar {
  .DayPicker__horizontal > div > div {
    .DayPicker_transitionContainer {
      .CalendarMonthGrid__horizontal {
        .CalendarMonth {
          .CalendarDay__default {
            width: 39px !important;
            height: 35px !important;
          }
        }
      }
    }
  }
}

.modalCalendar {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h4 { display: none; }

  .DayPicker__horizontal > div > div {
    .DayPicker_transitionContainer {
      .CalendarMonthGrid__horizontal {
        .CalendarMonth {
          .CalendarDay__default {
            .blocked {
              color: $grey-border-input-color-1;
            }
          }
        }
      }
    }
  }
}
