@import '@src/styles/vars.css';

.Questions {
  padding-top: 30px;

  .questionsContainer {
    display: grid;
    grid-template-columns: 300px auto;
    grid-gap: 60px;

    .sidebar {
      ul {
        margin-left: 0;
        padding-left: 0;

        li {
          list-style-type: none;
          margin-bottom: 30px;
          cursor: pointer;

          .menuItemContainer {
            color: $grey-color-name;
            transition: all 0.1s linear;

            .indexSection {
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
            }

            .nameSection {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 25px;
            }
          }

          &.activeMenu {
            .menuItemContainer {
              color: $black-text-color;
              padding-left: 16px;
              border-left: 4px solid $green-blue-color-text;
            }
          }

          &:hover:not(.activeMenu) {
            .menuItemContainer {
              color: $black-text-color;
              padding-left: 16px;
              border-left: 4px solid $green-blue-color-text;
            }
          }
        }
      }
    }

    .body {
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 30px;
        color: $grey-color-dark;
      }

      .description {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 30px;
        color: $grey-color-dark;
      }
    }
  }
}
