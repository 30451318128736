@import '@src/styles/vars.css';

.MyJoinedPrograms {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: $black-text-color;
    margin-bottom: 20px;
    margin-top: 30px;
  }
}
