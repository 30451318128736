.roadmap {
  width: 1090px;
  height: 100%;
  display: flex;
  border-radius: 20px;
  box-shadow: 0 20px 70px rgba(41, 83, 132, 10%);

  .leftPane {
    position: relative;
    width: 336px;
    background: var(--color-v2-background1);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 60px 0 0 80px;
  }

  .rightPane {
    flex-grow: 1;
    background: var(--color-v2-background2);
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    padding: 80px 20px 0 40px;
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    color: var(--color-v2-text1);
  }

  .rightTitle {
    font-size: 24px;
    font-weight: 600;
    color: var(--color-v2-text6);
    margin-bottom: 40px;
  }

  .navBox {
    margin-top: 40px;
    list-style: none;
    display: grid;
    grid-auto-flow: row;
    row-gap: 27px;
    font-size: 24px;
    color: var(--color-v2-text3);

    li.active {
      font-weight: 600;
    }
  }

  .listAndPlant {
    width: 100%;
    height: 420px;
    display: flex;
    position: relative;
  }

  .listContainer {
    position: relative;
    width: 424px;
    height: 400px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .itemsList {
    width: 100%;
    position: relative;

    li {
      margin-bottom: 16px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    li:last-child .itemLeft {
      background: var(--color-v2-background2);
    }
  }

  .dashedLine {
    position: absolute;
    width: 8px;
    top: 6px;
    left: 0;
    height: 98%;
    background:
      linear-gradient(
        to bottom,
        #9fb2db 0%,
        #9fb2db 75%,
        transparent 76%
      );
    background-size: 2px 11px;
    background-repeat: no-repeat repeat;
    background-position: 5px 0;
  }

  .item {
    display: flex;
    position: relative;
    z-index: 500;
  }

  .itemContent {
    flex-grow: 1;
  }

  .itemTitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-v2-text6);
  }

  .itemDescription {
    font-size: 14px;
    color: var(--color-v2-text3);
  }

  .itemLeft {
    margin-top: 6px;
    margin-right: 12px;
  }

  .itemBullet {
    width: 12px;
    height: 12px;
    background: var(--color-v2-background2);
    border: 2px solid #9fb2db;
    border-radius: 8px;
  }

  .continueButton {
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 220px;
  }
}
