.wrapper {
  background: #f7f7f7;
  border-radius: 20px;
  overflow: auto;
  max-width: 792px;
}

.titleContainer {
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }

  @media (min-width: 320px) and (max-width: 799px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.completedTitle {
  text-decoration: line-through;
}

.header {
  font-size: 24px;
  font-weight: bold;
  padding: 40px 48px 30px 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 2;
  max-width: 792px;
  width: calc(100vw - 40px);
}

.body {
  flex-grow: 1;
  padding: 0 48px 47px 44px;
  display: flex;
  flex-direction: column;

  &.stickyFooter {
    padding-bottom: 0;

    .Modal-stickyFooter {
      position: sticky;
      bottom: 0;
      padding-bottom: 20px;
    }
  }
}

.closeButton {
  background: var(--light-color-v2-background1);
  border-radius: 10px;
  border: none;
  width: 44px;
  height: 44px;
  cursor: pointer;
  position: absolute;
  right: -54px;
  top: 0;
}
