@import '@src/styles/vars.css';

.AddTemplateSectionQ {
  background: $mint-green-color;
  border-radius: 10px;
  padding: 30px;
  flex-grow: 1;
  overflow-y: auto;

  .header {
    display: grid;
    grid-template-columns: auto 350px;
    gap: 20px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.24px;
      margin-top: 15px;
      color: $grey-color-name;
    }

    .seeTmplButtContainer {
      text-align: end;
      width: 100%;

      .butSeeTmpl {
        width: 160px;
      }
    }
  }
}
