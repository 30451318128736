.ReactModalPortal {
  position: relative;

  & .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000006b;
    z-index: 98;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  & .modal-content {
    display: flex;
    flex-direction: column;
    margin: 20px;
    outline: none;

    /* min-width: 480px; */
    max-width: calc(100vw - (20px * 2));
    max-height: calc(100vh - (20px * 2));
    transform: scale(0.95);
    transition: transform 200ms ease-in-out;

    @media (min-width: 320px) and (max-width: 950px) {
      width: calc(100vw - (20px * 2));
    }

    &.ReactModal__Content--after-open {
      transform: scale(1);
    }

    &.ReactModal__Content--before-close {
      transform: scale(0.95);
    }
  }

  & .modal-content-disabled {
    pointer-events: none;
  }
}
