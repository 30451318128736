.customDataRow {
  margin-bottom: 10px;
}

.typeOptionsContainer {
  display: flex;
  justify-content: space-between;
}

.typeOption {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 137px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--color-v2-background2);
  margin-right: 20px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background-color: var(--color-v2-background1);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 10px;
      border: 2px solid var(--color-v2-border3);
    }
  }

  :global(.Image) {
    width: 140px;
    height: 90px;
    margin-bottom: 10px;
  }
}

.typeOptionText {
  font-size: 15px;
  line-height: 22px;
  font-weight: bold;
  position: relative;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--color-v2-icon4);
    position: absolute;
    left: -26px;
  }
}

.dataRowButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: initial;
  border-width: 1px;
  border-style: none none solid;
  border-color: var(--color-v2-border2);
  outline: none;
  width: 100%;
  padding: 3px 0;
  font-size: 16px;
  color: var(--color-v2-text1);
  border-radius: initial;
  font-weight: normal;
  height: auto;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--color-v2-icon3);
  }
}

.paymentOptionsContainer {
  display: flex;
  justify-content: space-between;
}

.paymentOption {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--color-v2-background2);
  margin-right: 20px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background-color: var(--color-v2-background1);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 10px;
      border: 2px solid var(--color-v2-border3);
    }
  }
}

.paymentOptionText {
  font-size: 15px;
  line-height: 22px;
  font-weight: bold;
}

.clientConferenceDurationContainer {
  display: flex;
  justify-content: space-between;
}

.clientConferenceDuration {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0.25;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--color-v2-background2);
  margin-right: 20px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background-color: var(--color-v2-background1);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 10px;
      border: 2px solid var(--color-v2-border3);
    }
  }
}

.clientConferenceDurationText {
  font-size: 15px;
  line-height: 22px;
  font-weight: bold;
}

.priceTextFieldContainer {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  :global(.TextField) {
    flex: 1;
  }

  .paymentPlanButton {
    background-color: initial;
    padding: 0;
    color: var(--color-v2-text4);
    margin-left: 10px;
  }
}

.paymentProcessingContainer {
  margin: 15px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--color-v2-background2);
  font-size: 15px;
  line-height: 20px;
  color: var(--color-v2-text3);
  display: flex;

  .paymentProcessingContent {
    flex: 1;
  }

  .paymentProcessingTitle {
    font-size: 17px;
    line-height: 24px;
    font-weight: bold;
    color: var(--color-v2-text1);
  }
}

.contractContainer {
  display: flex;
  align-items: center;
  cursor: pointer;

  .contractPreview {
    width: 50px;
    height: 50px;
    background-color: var(--color-v2-background2);
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 28px;
      height: 28px;
      fill: var(--color-v2-icon3);
    }
  }

  .attachContractText {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.41px;
    color: var(--color-v2-text3);
  }

  .contractName {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--color-v2-text1);
    padding-bottom: 6px;
  }

  .contractSize {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    color: var(--color-v2-text3);
  }
}

.personalInfoContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.disabled {
    cursor: not-allowed;
  }

  .selectPersonalInfo {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.41px;
    color: var(--color-v2-text3);
    padding: 3px 0;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--color-v2-icon3);
  }

  .personalInfoListContainer {
    flex: 1;
  }

  .personalInfoList {
    display: flex;
    flex-wrap: wrap;
  }

  .personalInfoItem {
    padding: 7px 15px;
    margin-bottom: 5px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: var(--color-v2-background7);
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.4px;
  }
}
