.NavTabs {
  display: flex;
  margin-top: 30px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
  }

  .NavTabsItem {
    margin-right: 30px;

    a {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      text-decoration: none;
      color: $grey-color-dark;
      display: inline-block;
      position: relative;

      .linkContainer {
        display: flex;
        align-items: center;

        .icoContainer {
          height: 15px;
          width: 18px;
          margin-top: 0;
          margin-right: 10px;

          svg {
            width: 100%;
            height: 100%;
          }

          &.mySquads {
            svg {
              path {
                fill: $grey-color-dark;
              }
            }
          }

          &.myJoinedPrograms {
            height: 16px;
            margin-top: 3px;
          }

          &.myToolbox {
            width: 20px;
            height: 20px;
            position: relative;

            svg {
              path {
                fill: $grey-color-dark;
              }
            }
          }

          &.mySessionNotes {
            width: 20px;
            height: 20px;
            position: relative;
          }

          &.userProfileTab {
            svg {
              path {
                fill: $grey-color-dark;
              }
            }
          }

          &.userCertificationTab {
            height: 16px;
            position: relative;
            top: 1px;

            svg {
              width: 100%;
              height: 100%;

              path {
                fill: $grey-color-dark;
              }
            }
          }

          &.userPayments {
            svg {
              path {
                fill: $grey-color-dark;
              }
            }
          }
        }
      }

      svg {
        fill: $grey-color-dark;
      }

      &.selected {
        color: $black-text-color;
        font-weight: bold;

        svg {
          fill: $green-color;
        }

        .mySquads,
        .myToolbox {
          svg {
            path {
              fill: $green-color !important;
            }
          }
        }

        .linkContainer {
          .userProfileTab {
            svg {
              path {
                fill: $green-color;
              }
            }
          }

          .userCertificationTab {
            svg {
              path {
                fill: $green-color;
              }
            }
          }

          .userPayments {
            svg {
              path {
                fill: $green-color;
              }
            }
          }
        }

        &::after {
          display: block;
          content: "";
          height: 3px;
          width: 100%;
          background-color: $green-color;
          position: absolute;
          margin-top: 6px;
        }
      }

      &:not(.selected) {
        &::after {
          display: block;
          content: "";
          height: 3px;
          width: 0%;
          background-color: $green-color;
          transition: width 0.4s ease-in-out;
          position: absolute;
          margin-top: 6px;
        }

        &:hover::after,
        &:focus::after {
          width: 100%;
        }
      }
    }
  }
}
