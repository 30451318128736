.Component {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
  }

  .img-visible {
    opacity: 1;
  }

  .img-hidden {
    opacity: 0;
  }

  .placeholder {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    transition: opacity 0.3s;

    &.loaded {
      opacity: 0;
    }
  }
}
