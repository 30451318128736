@import '@src/styles/vars.css';

.CourseMaterials {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .AddItemButtonBlock {
    margin-top: 12px;
  }

  .topMenuContainer {
    display: flex;

    .tab {
      background: $grey-light-but-color;
      border-radius: 39px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.192px;
      padding: 8px 15px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: $grey-color-name;
      margin-right: 10px;

      &.selected {
        background: $grey-color-dark;
        color: $white-color;
      }
    }
  }

  .contentContainer {
    flex-grow: 1;
    position: relative;
    height: calc(62vh - 48px);
    overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f5f6;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #25d2a2;
        border-radius: 2px;
      }

    .listContainer {
      position: absolute;
      width: 100%;
      margin: 12px 0;
      padding-right: 6px;

      .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 12px;

        &.fourColumn {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }
  }

  .butMenuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addFile {
      height: 40px;
      display: flex;
      padding: 0 15px;
      background: $green-blue-color-text;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      align-items: center;

      .plusIcon {
        display: flex;
        height: 17px;
        width: 17px;
        background: $green-color-light;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        color: $white-color;
        font-size: 14px;
        padding-bottom: 2px;
        margin-right: 10px;
      }
    }

    .choose {
      height: 40px;
    }
  }
}

.NoResults {
  width: 100%;
  min-height: 0;

  .NoResultsMainContainer {
    width: 100%;

    .NoResultsImgContainer {
      width: 100%;

      .iconNoResultMainContainer {
        display: flex;
        margin-top: 10vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .iconNoResultContainer {
          height: 30px;
          width: 30px;
          margin-bottom: 20px;

          .iconNoResult {
            path {
              fill: $grey-color-dark;
            }
          }
        }

        .iconNoResultText {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          text-align: center;
          letter-spacing: -0.4675px;
          color: $grey-color-dark;
        }
      }
    }
  }
}
