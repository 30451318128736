.page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.conference {
  border-radius: 26px;
  overflow: hidden;
  flex: 0.9;
  display: flex;
  flex-direction: column;
  background: var(--color-v2-background5);
  position: relative;
}

.actionsBar {
  background: #0009;
  padding: 30px 25px;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }
}

.actionButton {
  padding: 0;
  margin: 0 7px;
  width: 40px;
  height: 40px;
  background-color: #0006;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--light-color-v2-icon2);
  }

  &.danger {
    background-color: var(--color-v2-error);
  }

  &:hover {
    opacity: 0.8;
  }
}

.topBar {
  background: #0009;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  font-size: 16px;
  line-height: 22px;
  color: var(--light-color-v2-text2);
}

.otSessionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.streamsContainer {
  position: relative;
  flex: 1;
  padding: 10px;
  display: flex;

  &.oneToOne {
    .coachesStreams,
    .clientsStreams {
      position: initial;
    }

    .stream {
      position: absolute;
      left: 10px;
      top: 10px;
      bottom: 10px;
      right: 10px;
    }

    .publisher {
      position: absolute;
      height: 150px;
      width: 220px;
      top: initial;
      left: initial;
      bottom: 30px;
      right: 30px;
      z-index: 2;
    }
  }
}

.streams {
  position: relative;
  flex: 1;
  display: grid;
  grid-gap: 10px;
  overflow-y: auto;
  border-radius: 10px;

  &.moreThan2 {
    grid-template-columns: 1fr 1fr;
  }

  &.moreThan6 {
    grid-auto-flow: row;
    grid-auto-rows: 33%;

    /* grid-template-columns: unset; */
    grid-template-rows: unset;
  }
}

.coachesStreams {
  margin-right: 5px;
}

.clientsStreams {
  margin-left: 5px;
}

.stream {
  position: relative;
  background: #0009;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--light-color-v2-text2);
  text-align: center;

  & video {
    visibility: hidden;
    border-radius: 10px; /* fix for safari */
  }

  &.hasVideo {
    video {
      visibility: initial;
    }

    .streamNoAudioIcon {
      background-color: #0009;
      padding: 2px;
      border-radius: 50%;
    }
  }
}

.streamAvatarContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.streamAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-bottom: 15px;
}

.streamName {
  font-size: 16px;
  line-height: 22px;
  color: var(--light-color-v2-text2);
}

.streamNoAudioIcon {
  width: 24px;
  height: 24px;
  fill: var(--light-color-v2-icon2);
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.streamBadge {
  background: #0009;
  border-radius: 10px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 18px;
  color: var(--light-color-v2-text2);
  position: absolute;
  left: 10px;
  top: 10px;
}

:global(.OT_bar) {
  display: none !important;
}

:global(.OT_name) {
  display: none !important;
}

:global(.OT_archiving) {
  display: none !important;
}

:global(.OT_edge-bar-item) {
  display: none !important;
}

:global(.OT_video-poster) {
  display: none !important;
}

:global(.OT_video-loading) {
  display: none !important;
}

:global(.OT_audio-level-meter__audio-only-img::before) {
  display: none !important;
}

:global(.OT_audio-blocked-indicator) {
  display: none !important;
}

:global(.OT_video-blocked-indicator) {
  display: none !important;
}

:global(.OTPublisherContainer),
:global(.OTSubscriberContainer) {
  position: absolute !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

:global(.OT_widget-container) {
  background-color: initial !important;
}

:global(.OT_video-disabled-indicator) {
  bottom: 8px !important;
  right: 5px !important;
}
