@import '@src/styles/vars.css';

.customCheckBoxContainer {
  .customCheckBox {
    input {
      &:not(:checked),
      &:checked {
        position: absolute;
        left: -9999px;
      }

      &:not(:checked) + label,
      &:checked + label {
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        font-size: 14px;
        color: $grey-color-text;
      }

      &:not(:checked) + label::before,
      &:checked + label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border: 2px solid $green-color;
        border-radius: 4px;
        transition: all 0.2s;
      }

      &:not(:checked) + label::before {
        background: $white-color;
      }

      &:checked + label::before {
        background: $green-color;
      }

      &:not(:checked) + label::after,
      &:checked + label::after {
        content: '';
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $white-color;
        border-width: 0 2px 2px 0;
        position: absolute;
        transition: all 0.2s;
      }

      &:not(:checked) + label::after {
        opacity: 0;
        transform: rotate(45deg) scale(0);
      }

      &:checked + label::after {
        opacity: 1;
        transform: rotate(45deg) scale(1);
      }

      /* disabled checkbox */
      &:disabled:not(:checked) + label::before,
      &:disabled:checked + label::before {
        box-shadow: none;
        border-color: $disabled-color;
        background-color: $disabled-color;
      }

      &:disabled:checked + label::after {
        color: $disabled-text-color;
      }

      &:disabled + label {
        color: $disabled-color;
      }

      /* accessibility */
      &:checked:focus + label::before,
      &:not(:checked):focus + label::before {
        border: 2px solid $green-color;
      }
    }

    label:hover::before {
      border: 2px solid $green-color !important;
    }
  }
}
