@import "../../styles/vars.css";

.OtherUserProfileContainer {
  height: 100%;
  width: 100%;
  position: relative;

  .OtherUserProfile {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .childrenContainer {
      position: absolute;
      bottom: -3px;
      right: -3px;
    }
  }
}

.UserProfileImg {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.AvatarTitle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  color: $white-color;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  z-index: 1;
}
