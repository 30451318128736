@import '@src/styles/vars.css';

.MyPractice {
  .container {
    $minHeight: 150px;

    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;

    .imageContainer {
      a {
        text-decoration: none;

        .avatarLoader {
          min-height: $minHeight;
        }

        img {
          display: block;
          object-fit: cover;
          height: 140px;
          width: 100%;
        }
      }
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      color: $white-color;
      position: absolute;
      bottom: 0;
      margin-left: 20px;
      margin-bottom: 15px;
    }

    .settings {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      .icon {
        height: 20px;
        width: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
      }
    }
  }
}
