.addButtonsContainer {
  display: flex;

  :global(.Button) {
    height: 44px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 28px;
      height: 28px;
      fill: var(--light-color-v2-icon2);
      margin-right: 10px;
    }
  }

  .clearButton {
    background-color: initial;
    color: var(--color-v2-text4);
  }
}

.noResultsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list {
  margin-top: 20px;
  width: 60%;
}

.listItem {
  margin-bottom: 10px;
}
