@import '@src/styles/vars.css';

.LongTextTemplateContainer {
  margin-bottom: 30px;

  .name {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
  }

  .visual {
    position: relative;
    border-bottom: 1px solid $grey-green1-color;
    height: 30px;
    margin-top: 20px;

    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $grey-color-dark;
    }

    .labelAnswer {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $black-text-color;
    }
  }
}

.MultipleChoiceTemplateContainer,
.SingleChoiceTemplateContainer {
  margin-bottom: 30px;

  .name {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
  }

  ul {
    margin-left: 0;
    padding-left: 0;

    li {
      list-style-type: none;
      margin-bottom: 16px;
      position: relative;

      span {
        display: inline-block;
        vertical-align: text-bottom;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 7px;
        width: 7px;
        border: 1px solid $green-blue-color-text;
        background-color: $green-blue-color-text;
        margin-right: 16px;
        top: 8.2px;
        left: 4.8px;
      }

      &::before {
        content: '';
        display: inline-block;
        height: 17px;
        width: 17px;
        border: 1px solid $grey-color-name;
        margin-right: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .answer {
    margin-top: 20px;
    border-bottom: 1px solid $grey-green1-color;
  }
}

.SingleChoiceTemplateContainer {
  ul {
    li {
      &::before {
        border-radius: 50%;
      }

      &::after {
        border-radius: 50%;
      }
    }
  }
}
