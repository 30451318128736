@import "@src/styles/vars.css";

.programsList {
  width: 100%;
  display: grid;
  grid-gap: 8px;
}

.programsListItem {
  width: 480px;
  height: 95px;
  padding: 14px 20px;
  box-shadow: 0 4px 20px rgba(66, 56, 181, 10%);
  border-radius: 15px;
  color: var(--color-v2-text2);
  clip-path: inset(0 0);
  cursor: pointer;

  svg {
    position: absolute;
    margin-left: -20px;
    margin-top: -14px;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
  }

  .description {
    font-size: 14px;
    margin-top: 4px;
  }
}
