@import '@src/styles/vars.css';

.MainBanner {
  .bannerContainer {
    height: 70px;
    width: 100%;

    .banner {
      background: $purple-color;
      padding: 12px 20px;
      height: 100%;
      width: 100%;
      border-radius: 15px;
      display: grid;
      align-items: center;
      grid-template-columns: auto 160px 100px;
      gap: 20px;

      .imageContainer {
        width: 33px;
        display: flex;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .textBanner {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.41px;
        color: $white-color;

        .textTitle {
          font-size: 12px;
          line-height: 18px;
        }

        .textDescription {
          font-weight: initial;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .butBanner {
        background: $medium-magenta;
        border-radius: 10px;
        padding: 10px 15px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.41px;
        color: $white-color;
        text-align: center;

        &.clickable {
          cursor: pointer;
        }
      }
    }
  }
}
