.App {
  background-color: #f7f7f7;
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.App::-webkit-scrollbar {
  display: none;
}

.Content {
  height: calc(100% - 170px);
}

@media (min-width: 320px) and (max-width: 799px) {
  .App {
    overflow: auto;
    height: -webkit-fill-available;
    height: -moz-available;
  }
}
