.container {
  position: relative;
  display: inline-block;
}

.card {
  z-index: 1;
}

.publicationDayButton {
  margin: 0;
  height: auto;
  border-radius: initial;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 18px 18px 8px 8px;
  font-size: 12px;
  line-height: 14px;
  background-color: var(--color-v2-background2);
  color: var(--color-v2-text3);
  font-weight: normal;
  position: relative;
  top: -10px;
  width: 100%;
}
