@import './vars.css';

.ModalWindowContainer {
  width: 60vw;
  padding: 30px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25d2a2;
  }

  @media (min-width: 320px) and (max-width: 799px) {
    overflow: scroll;
    width: 90vw;
    margin: 0 auto;
  }

  &.h90vh {
    height: 90vh;
  }

  &.modalAddFolder {
    height: 300px;
  }

  &.customDataPicker {
    height: 400px;
    width: 650px;
    margin-top: -15%;
  }

  background: $white-color;
  box-shadow: 0 6px 20px rgba(140, 147, 162, 10%);
  border-radius: 10px;
  position: relative;

  .closeBut {
    height: 40px;
    width: 40px;
    background: $grey-color;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
  }
}

.halfContainer {
  width: 650px;
  height: auto;
  min-height: 350px;
  max-height: 80vh;
}

.littleHContainer {
  height: 280px;
}

.greyHeaderContainer {
  padding: 0;
  background: $grey-light-color;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  &.orWhite {
    background: $white-color;
  }
}

.marginTopMinus50Percent {
  margin-top: -50%;
}

.whiteBody {
  padding: 30px;
  background: $white-color;
  border-radius: 10px;
  flex-grow: 1;
  height: 100%;
  max-height: 70vh;
  position: relative;

  &.noP30 {
    padding: initial;
  }

  .whiteBodyContent {
    border-radius: 10px 10px 0 0;
    height: 100%;
    width: 100%;
  }
}

.greyHeader {
  padding: 30px;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }
}

.littleContainer {
  height: 324px;

  &.Calendar {
    height: 480px;
  }

  &.List {
    height: initial;
    max-height: 95vh;
    border-radius: 10px;

    @media (min-width: 320px) and (max-width: 799px) {
      max-height: none;
      height: 100%;
    }
  }

  &.top-100 {
    @media (min-width: 320px) and (max-width: 799px) {
      top: 100px;
    }
  }

  &.w440 {
    max-width: 440px;

    .greyHeader {
      padding: 20px 20px 20px 40px;
    }
  }

  &.w500 {
    max-width: 500px;

    .greyHeader {
      padding: 20px 20px 20px 40px;
    }
  }

  &.w610 {
    max-width: 610px;

    .greyHeader {
      padding: 20px 20px 20px 40px;
    }
  }

  .whiteBody {
    padding: 20px;
    border-radius: 0 0 10px 10px;

    &.p0 {
      padding: 0;
    }
  }

  .greyHeader {
    padding: 20px;
  }
}

.react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__item__content {
  background-color: $purple-color !important;
}

.maxContent {
  height: 100%;
  max-height: initial;
}

.ModalWindowContainer .closeBut.greyHeaderBut {
  background: $white-color;
  border-radius: 10px;

  svg {
    fill: $grey-color-name;
  }
}

.greenModal {
  background-color: $mint-green-color;
  padding: 0;

  .modalCloseBut {
    background: $white-color;
    border-radius: 10px;
  }
}

.windowContainerBodyHeader {
  height: 60vh;
  min-height: 250px;
  flex-grow: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .mainContainer {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  .inputContainer {
    position: relative;
  }

  .searchContainer {
    padding: 0;
    background: none;
    border-radius: 0;

    .searchContainerInput {
      border: 1px solid $blue-grey-color;
      border-radius: 10px;
    }
  }

  .resultContainer {
    flex-grow: 1;
    overflow-y: auto;
  }

  .footerButContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .footerAddAllBut {
      width: 107px;
    }
  }

  .filterContainer {
    display: flex;
    justify-content: space-between;

    .filterButContainer {
      display: flex;
    }
  }
}

.noPadding {
  padding: initial;
}

.maxHeight80vh {
  height: initial;
  max-height: 80vh;
}

.maxHeight70vh {
  max-height: 70vh;
}

.maxHeight60vh {
  max-height: 60vh;
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .currentPaymentContainer {
    flex-grow: 1;
  }
}

.customRangeDatePicker {
  @media (min-width: 370px) {
    .DayPicker__horizontal > div > div {
      width: 600px !important;
    }
  }

  .CalendarMonthGrid_month__horizontal {
    width: 300px;
    padding: 0 10px;
  }

  @media (min-width: 370px) {
    /* stylelint-disable-next-line max-line-length */
    .DayPicker__horizontal
      > div
      > div
      .DayPicker_weekHeaders__horizontal
      .DayPicker_weekHeader
      .DayPicker_weekHeader_ul {
      width: 295.5px;
      padding: 0 10px;
    }
  }

  /* stylelint-disable-next-line max-line-length */
  .DayPicker__horizontal
    > div
    > div
    .DayPicker_transitionContainer
    .CalendarMonthGrid__horizontal
    .CalendarMonth
    .CalendarDay__default {
    .dayContainer {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 1.5px;
      cursor: default;

      &.selectable:hover {
        background: $ghostly-white;
        cursor: pointer;
      }

      .day {
        position: absolute;
        height: 33.6px;
        width: 33.6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      .dayBlocked {
        position: absolute;
        height: 33.6px;
        color: $grey-color-name;
        width: 33.6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      &:hover {
        .day {
          background: linear-gradient(
            0deg,
            $purple-color -3.75%,
            $moderate-slate-blue 100%
          );
          box-shadow: 0 4px 10px $purple-color-opacity;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          transition-property: background, color, box-shadow;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          color: $white-color;
        }
      }
    }
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: $ghostly-white;
  }

  .CalendarDay__selected_span {
    background: $ghostly-white;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $ghostly-white;

    .dayContainer {
      position: relative;
      width: 100%;
      height: 100%;

      .day {
        position: absolute;
        height: 33.6px;
        width: 33.6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: linear-gradient(
          0deg,
          $purple-color -3.75%,
          $moderate-slate-blue 100%
        );
        box-shadow: 0 4px 10px $purple-color-opacity;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        transition-property: background, color, box-shadow;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        color: $white-color;
      }
    }
  }
}
