.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 10;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 95px;
  padding: 70px 0 0;

  @media (min-width: 320px) and (max-width: 950px) {
    margin: 0;
    padding: 0;
  }
}
