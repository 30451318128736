.TextField {
  display: block;

  input {
    border-width: 1px;
    border-style: none none solid none;
    border-color: var(--color-v2-border2);
    outline: none;
    width: 100%;
    padding: 3px 0;
    font-size: 16px;
    color: var(--color-v2-text1);

    &::placeholder {
      color: var(--color-v2-text3);
    }
  }

  .error {
    color: var(--color-v2-error);
    font-size: 14px;
    text-align: left;
  }

  &.disabled {
    input {
      background-color: rgba(0, 0, 0, 0);
      cursor: not-allowed;
    }
  }

  &.search {
    padding: 13px 10px 13px 16px;
    border-radius: 10px;
    border: 1px solid var(--color-v2-border2);

    .inputContainer {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--color-v2-icon3);
      }
    }

    input {
      padding: 0 5px 0 0;
      border: none;
    }
  }
}
