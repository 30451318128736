.Component {
    position: relative;
}

.dropContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px dashed var(--color-v2-border2);
    padding: 35px 50px;
    margin-bottom: 12px;
    cursor: pointer;

&.active {
     background-color: var(--color-v2-background2);
     border: 2px solid var(--color-v2-text5);
 }

.fileInput {
    display: none;
}

.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.4675px;
    color: var(--color-v2-text5);
    padding: 12px 0 8px;
}

.description {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: var(--color-v2-text3);
}

svg {
    width: 64px;
    height: 64px;
}
}

.dropContainerFooter {
    display: flex;
    justify-content: space-between;

.note {
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.41px;
    color: var(--color-v2-text3);
}
}

.files {
    padding-top: 20px;

:global(.FilesListItem:last-child) {
    margin-bottom: 0;
}
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}

.mozillaClipPathWorkaround {
svg {
g {
    clip-path: none !important;
}
}
}
