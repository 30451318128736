.Component {
  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  button {
    height: 60px;
    font-size: 18px;
    font-weight: bold;
    background: var(--color-v2-button-fill1);
    margin-top: 20px;
  }

  svg {
    fill: var(--color-v2-text3);
  }
}

.scrollContainer {
  flex-grow: 1;
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--color-v2-border2);
  margin: 0 0 10px;
}

.arrow {
  fill: var(--color-v2-text3);
  height: 40px;
  width: 40px;
  padding: 7px;
  background: var(--color-v2-background2);
  border-radius: 10px;
  transform: rotate(90deg);

  &.pointDown {
    transform: rotate(270deg);
  }
}

.courseItem {
  background: var(--color-v2-background2);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--color-v2-background2);
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-v2-text1);

  :global(.RadioButton-mark) {
    margin: 0 15px 0 6px;
    background: none;
    border: 1px solid var(--color-v2-text3);
  }

  &:global(.Selectable-selected) {
    border: 1px solid var(--color-v2-global1);
  }
}

.noResultsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBody {
  padding: 24px 20px !important;

  :global(.SelectCourse) {
    width: 440px;
    height: 480px;
  }

  :global(.Scrollbar-wrapper) {
    inset: 0 10px 0 0 !important;
  }
}
