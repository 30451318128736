@import "@src/styles/vars.css";

.EditCoachProfile {
  height: 480px;
  width: 640px;
}

.CoachLogo {
  height: 50px;
  width: 100px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  .CoachLogoLabel {
    position: absolute;
    top: 15px;
    left: 10px;
  }

  .OverlayImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
    background: linear-gradient(0deg, $periwinkle-crayola, $periwinkle-crayola);
  }
}

.coachProfileContainer {
  .bottomContainer {
    display: flex;
  }

  .bottomContainerSpacer {
    flex-grow: 0;
    width: 20px;
  }
}

.greyBoard {
  background:
    linear-gradient(
      0deg,
      $grey-light-but-color,
      $grey-light-but-color
    );
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  flex-basis: 0;
  flex-grow: 1;

  .switchContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .mentor {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .greyBoardText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 15px;
    color: $grey-color-dark;
  }

  .greyInput {
    .greyInputLabel {
      padding: 20px 0 0 !important;
    }

    input {
      background: $grey-light-but-color !important;
    }

    input:focus + label {
      padding: 1px 0 0 !important;
    }
  }
}

.ChoiceCategory {
  .ChoiceCategoryExplanation {
    margin-top: 15px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $grey-color-dark;
  }

  .categoriesContainer {
    .category {
      background: $grey-light-but-color;
      border-radius: 20px;
      padding: 12px 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-right: 11px;
      margin-bottom: 11px;
      display: inline-block;
      cursor: pointer;
      color: $grey-color-name;
      transition: all 0.2s ease;

      &.selected {
        background: $grey-color-name;
        color: $white-color;
        padding: 9px;
        padding-right: 16px;

        .categoriesNum {
          display: inline-block;
          height: 22px;
          width: 22px;
          margin-right: 9px;
          border-radius: 50%;
          text-align: center;
          background: $grey-color-dark;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 22px;
          color: $white-color;
        }
      }
    }
  }
}

.Certificates {
  display: block;

  .NoItemsExplanation {
    margin-top: 10px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $grey-color-dark;
  }

  .addItemBox {
    border: 1px dashed $grey-color-dark;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    background: $grey-light-color;
    text-align: center;
    color: $grey-color-dark;
    cursor: pointer;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .titleTextContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.4675px;
      }

      .addCardContainer {
        margin-top: initial;

        .but {
          padding: 10px 20px;
          border-radius: 10px;

          .butIcon {
            color: $grey-color-name;
            display: flex;
            align-items: center;
            width: 10px;
          }

          .butText {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $grey-color-name;
          }
        }
      }

      .description {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.41px;
        color: $grey-color-dark;
      }
    }
  }

  .listCardContainer {
    display: block;

    .paymentCardListContainer {
      display: block;

      .paymentCardScrollListContainer {
        display: block;

        .paymentCardContainer {
          border: 1px dashed $grey-color-dark;
          border-radius: 12px;
          margin-bottom: 20px;
          padding: 20px;

          &.active {
            border: 1px solid $white-color;
          }

          .paymentCardAndMenuContainer {
            display: grid;
            grid-template-columns: 120px auto;
            gap: 15px;
            align-items: center;
            height: initial;

            .paymentCard {
              padding: 8px 0;

              img {
                max-height: 25px;
              }

              .cardNumber {
                font-style: normal;
                font-weight: normal;
                font-size: 6.2906px;
                line-height: 9px;
                padding-left: 8px;
                padding-top: 5px;
                padding-bottom: 8px;
                color: $white-color;
              }

              .date {
                font-style: normal;
                font-weight: normal;
                font-size: 6.2906px;
                line-height: 9px;
                padding-left: 8px;
                color: $white-color;
              }
            }

            .paymentCardMenuContainer {
              display: block;
            }
          }

          .checkContainer {
            height: 28px;
            padding-top: 0;
          }
        }
      }
    }
  }

  .addCardContainer {
    background: $white-color;
    box-shadow: 0 4px 15px rgba(66, 56, 181, 5%);
    border-radius: 10px;
    margin-top: 10px;
    width: max-content;

    .but {
      display: flex;
      align-items: center;
      padding: 20px 25px;
      cursor: pointer;

      .butIcon {
        height: 18px;
        width: 18px;

        svg {
          fill: $black-text-color;
        }
      }

      .butText {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: $grey-color-name;
        padding-left: 8px;
      }
    }
  }

  .previewListCardContainer {
    border: 1px dashed $grey-color-dark;
    border-radius: 12px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .message {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $grey-color-name;
    }

    .addCardContainer {
      margin-top: 10px;

      .but {
        padding: 10px 20px;
        border-radius: 10px;

        .butIcon {
          color: $grey-color-name;
          display: flex;
          align-items: center;
          width: 10px;
        }

        .butText {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          text-align: center;
          color: $grey-color-name;
        }
      }
    }
  }
}

.AddCertificate {
  .switchContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}

.modalWindow {
  background: $white-color;

  .modalHeader {
    height: 70px;
  }
}

.modal {
  z-index: 2000;
}
