.Component {
  position: relative;
}

.container {
  display: flex;
  justify-content: space-between;
}

.avatarEditor {
  overflow: hidden;
  border-radius: 10px;
}

.settingsContainer {
  margin-left: 20px;

  .setting {
    margin-bottom: 10px;

    .settingName {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4675px;
      color: var(--color-v2-text1);
      margin-bottom: 10px;
    }

    .settingContent {
      .rotateButton {
        width: 100px;

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}

.footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
