.Component {
  display: flex;
  flex-direction: column;
  min-width: 0; /* to make .title:text-overflow work with flex */
}

.row {
  display: flex;
  align-items: center;
  min-width: 0; /* to make .title:text-overflow work with flex */
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 10px;
}

.userAvatar {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  flex-shrink: 0;
  margin-right: 10px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.courseSize {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-v2-text3);
}

.courseStatus {
  flex-shrink: 0;
  margin-left: 10px;
  color: var(--color-v2-text3);
}
