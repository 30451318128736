.Modal {
  background: #f7f7f7;
  border-radius: 20px;
  overflow: auto;
  width: 792px;

  @media (min-width: 320px) and (max-width: 950px) {
    width: auto;
  }

  .header {
    font-size: 24px;
    font-weight: bold;
    padding: 40px 48px 30px 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0;
    z-index: 2;

    @media (min-width: 320px) and (max-width: 950px) {
      padding: 18px 16px 16px 18px;
      font-size: 18px;
    }
  }

  .body {
    flex-grow: 1;
    padding: 0 48px 47px 44px;
    display: flex;
    flex-direction: column;

    @media (min-width: 320px) and (max-width: 950px) {
      padding: 0;
    }

    &.stickyFooter {
      padding-bottom: 0;

      .Modal-stickyFooter {
        position: sticky;
        bottom: 0;
        padding-bottom: 20px;
      }
    }
  }

  .closeButton {
    background: var(--light-color-v2-background1);
    border-radius: 10px;
    border: none;
    width: 44px;
    height: 44px;
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 0;

    @media (min-width: 320px) and (max-width: 950px) {
      position: static;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: #e9e9e9;
    }
  }
}
