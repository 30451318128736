@import "../../styles/vars.css";

.overlayCustomClassName {
  background: rgba(49, 44, 66, 30%);
  z-index: 2000; /* show above modals */
}

.CustomConfirmAlert {
  text-align: center;
  width: 432px;
  padding: 40px 36px;
  background: $white-color;
  box-shadow: 0 6px 20px rgba(140, 147, 162, 10%);
  border-radius: 20px;

  .titleContainer {
    margin-top: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }

  .elementContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .messageContainer {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    padding-top: 10px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .buttContainer {
      width: 160px;
      padding: 10px;
      margin: 10px;
      cursor: pointer;
      background: $periwinkle-crayola;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: $grey-color-name;

      &.danger {
        color: $white-color;
        background: $red-notify-color;
      }

      &.confirm {
        background: linear-gradient(0deg, #f24c00 0%, #f24c00 100%);
        color: $white-color;
      }

      &.attention {
        color: $white-color;
        background: $alert-color;
      }
    }
  }
}
