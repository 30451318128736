@import '@src/styles/vars.css';

.MoveFileInFolders {
  height: 100%;

  .container {
    margin-top: 45px;
    height: 70%;
    overflow: hidden;
    overflow-y: auto;
    background: $grey-light-color;
    border-radius: 10px;
    box-shadow: inset -0 -0 16px -10px rgba(0, 0, 0, 0.75);

    ul {
      padding: 20px;
      margin: 0;

      li {
        display: block;
        list-style-type: none;
        transition: all 0.5s ease;
        cursor: pointer;
        background-color: $green-color;
        padding: 10px 25px;
        margin-top: 5px;
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        &:hover {
          transform: scale(1.03);
          box-shadow: 0 0 24px -11px rgba(0, 0, 0, 0.67);
        }
      }
    }
  }
}
