.AvatarPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .letters {
    color: var(--light-color-v2-text2);
    font-weight: 600;
  }
}
