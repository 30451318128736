.Component {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.align-right {
    flex-direction: row-reverse;
  }
}

.content {
  flex-grow: 1;
  min-width: 0; /* to make .title:text-overflow work with flex */
}
