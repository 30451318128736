.footer {
  display: flex;
  justify-content: flex-end;
}

.textAreaContainer {
  margin-bottom: 20px;
}

.textArea.textArea {
  border-style: solid;
  min-height: 150px;
  resize: none;
  border-radius: 10px;
  padding: 5px 10px;
}
