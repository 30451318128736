.container {
  display: flex;
  padding: 20px 5px 20px 15px;
  border-radius: 15px;
  background-color: var(--color-v2-background2);
  margin-bottom: 10px;
  cursor: pointer;

  &.task {
    .iconContainer {
      background-color: var(--color-v2-global1);
    }
  }

  &.conference {
    .iconContainer {
      background-color: var(--color-v2-global2);
    }
  }
}

.iconContainer {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  svg {
    width: 28px;
    height: 28px;
    fill: var(--light-color-v2-icon2);
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--color-v2-text1);
}

.description {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.24px;
  color: var(--color-v2-text3);
  padding-top: 10px;
}

.timeContainer {
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    fill: var(--color-v2-icon3);
  }

  .time {
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    color: var(--color-v2-text1);
  }
}

.moreButton {
  padding: 0;
  background-color: initial;
  position: relative;
  top: 2px;

  svg {
    width: 28px;
    height: 28px;
    fill: var(--color-v2-icon3);
  }
}
