.textFieldContainer {
  display: flex;
  align-items: baseline;

  :global(.TextField) {
    flex: 0;
    margin-right: 5px;

    input {
      font-weight: bold;
      color: var(--color-v2-text1);
      min-width: 40px;
      font-size: 22px;
    }
  }

  .textFieldDurationUnit {
    flex: 1;
    font-size: 22px;
    line-height: 27px;
    color: var(--color-v2-text1);
  }
}

.durationUnitsContainer {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.durationUnit {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 60px;
  padding: 11px 0;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--color-v2-background2);
  margin-right: 20px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background-color: var(--color-v2-background1);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 10px;
      border: 2px solid var(--color-v2-global2);
    }
  }
}

.durationUnitText {
  font-size: 15px;
  line-height: 22px;
  font-weight: bold;
}

.durationUnitIntervalText {
  font-size: 13px;
  line-height: 18px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
