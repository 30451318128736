.leftPaneLayout {
  width: 100%;
  height: 100vh;
  display: flex;

  .leftPane {
    width: 35%;
    min-width: 350px;
    background: var(--color-v2-background2);
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .rightPane {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
