.container {
  border-radius: 16px;
  background-color: var(--color-v2-background2);
  padding: 20px 30px;
}

.title {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.4675px;
  color: var(--color-v2-text1);
  margin-bottom: 12px;
}

.description {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.24px;
  color: var(--color-v2-text1);
  margin-bottom: 10px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 8px;
    width: 28px;
    height: 28px;
    fill: var(--color-v2-icon3);
  }

  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4675px;
    color: var(--color-v2-text1);
    margin-right: 14px;
  }

  .sub {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: var(--color-v2-text3);
  }
}
