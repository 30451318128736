@import "@src/styles/vars.css";

.hrClass {
  margin-left: -20px;
  margin-right: -20px;
  border: 0;
  height: 1px;
  background: rgba(178, 187, 198, 20%);
}

.buttonSendCodeContainer {
  margin-top: 40px;
}

.enterCodeText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.sendToContainer {
  text-align: center;
  margin-top: 9px;

  .sendTo {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    padding-right: 15px;
  }

  .sendToVal {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $grey-color-dark;
  }
}

.Accounts {
  h3 {
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: -0.4675px;
  }

  .topMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      display: block;
    }

    .addAccButtonNoModal {
      background: $blue-color-eggs-wandering-thrush;
      border-radius: 10px;
      padding: 5px 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: $white-color;

      .textButContainer {
        display: flex;
        align-items: center;

        .pluse {
          font-weight: initial;
          font-size: 18px;
          padding-right: 5px;
        }
      }
    }
  }

  .accountItemsContainer {
    display: flex;
    margin-top: 25px;

    .typeAccount {
      width: 19px;
    }

    .space {
      flex-grow: 1;
      border-bottom: 1px dashed $grey-blue2-color;
      margin-top: -5px;
      height: 22px;
      margin-left: 9px;
    }

    .delAccount {
      width: 22px;
      margin-top: -10px;
      cursor: pointer;
    }

    .check {
      width: 25px;
      margin-left: 6px;
      margin-top: -10px;

      svg {
        fill: $green-blue-color-text;
      }
    }

    .accountName {
      margin-left: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }
  }

  .addAccButton {
    background: $grey-color-dark;
    padding: 10px 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    margin-top: 20px;
    color: $white-color;
  }
}
