.wrapper {
  padding: 48px 30px 60px;
  background-color: #fefefe;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  /* action buttons */
  [class~='bgnWlC'] {
    width: 136px;
    height: 30px;
  }

  /* TODO check irjsJp */
  [class~='irjsJp'] button {
    width: 136px;
  }

  [class~='irjsJp'] button,
  [class~='bgnWlC'] {
    background: #571f5b;
    border-color: #571f5b;
  }

  [class~='irjsJp'] button:hover {
    background: #593562;
  }

  /* time column, deprecated */
  [class~='dfytzi'] {
    white-space: nowrap;
  }

  @media (min-width: 320px) and (max-width: 950px) {
    /* header */

    /* wrapper */
    [class~='fSPSze'] {
      gap: 12px;
      position: relative;
    }

    /* arrow buttons */

    [class~='hAnMYx'] {
      font-size: 16px;
    }

    /* range buttons */
    [class~='gAjstm'],
    [class~='hMsmtF'],
    [class~='fcQdJR'] {
      height: 31px !important;
    }

    /* TODO check */
    [class~='hoDtyO'] {
      height: 24px;
    }

    [class~='fhZAzN ']:hover {
      height: 24px;
    }

    /* day view todo */

    /* TODO check irjsJp */
    [class~='irjsJp'] {
      margin-left: auto;
      max-width: fit-content;
    }

    [class~='irjsJp'] button {
      min-width: auto !important;
      width: 106px;
    }

    [class~='irjsJp'] > div {
      min-width: auto !important;
      width: 106px;
      padding: 0 11px;
    }

    /* calendar */

    [class~='cjZIHL'] {
      z-index: 2;
    }

    /* TODO check hEHWQ */
    [class~='cjZIHL'] [class~='hEHWQ']:nth-child(2) {
      max-width: inherit;
    }

    [class~='cjZIHL'] [class~='hEHWQ']:nth-child(3) {
      display: none;
    }

    [class~='iqyeRN'] {
      background-color: #fefefe;
      z-index: 1;
      white-space: nowrap;
    }

    [class~='bzXHpb'] {
      max-width: fit-content;
    }

    [class~='fWDHKK'] {
      max-width: fit-content;
    }

    /* time column deprecated */
    [class~='jsopLW'] {
      flex: 1;
    }
  }

  @media (min-width: 320px) and (max-width: 400px) {
    /* TODO check jULYvv */
    [class~='jULYvv'] {
      max-width: 80%;
    }
  }
}

.modalWrapper {
  @media (min-width: 320px) and (max-width: 950px) {
    width: auto;
  }

  .eventInfoHeaderModal {
      padding-top: 20px;
      padding-bottom: 20px;

    @media (min-width: 320px) and (max-width: 950px) {
      padding: 18px 16px 16px 18px;
      font-size: 18px;
    }

    & button {
      @media (min-width: 320px) and (max-width: 950px) {
        position: static;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #e9e9e9;
      }
    }
  }

  .bodyModal {
    @media (min-width: 320px) and (max-width: 950px) {
      padding: 0;
    }
  }
}

@media (max-width: 950px) {
  .wrapper {
    padding: 20px;
    height: 100%;
  }
}

@media (min-width: 320px) and (max-width: 368px) {
  .wrapper {
    padding: 20px 10px 20px;

    [class~='fSPSze'] {
      gap: 8px;
      position: relative;
    }
  }
}
