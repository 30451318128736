.createFooterButtContainer {
  display: flex;
  justify-content: flex-end;

  .addFileButtContainer {
    max-width: 150px;
    margin-right: 20px;
  }

  .createButtContainer {
    max-width: 200px;
  }
}
