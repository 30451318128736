.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  font-size: 48px;
  color: var(--color-v2-text1);
  margin: 30px 0 50px;
}
