@import '@src/styles/vars.css';

.CourseMaterials {
  height: 100%;

  .contentContainer {
    flex-grow: 1;
    position: relative;
    height: 100%;

    .listContainer {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @media (min-width: 365px) and (max-width: 1224px) {
          gap: 10px;
        }
      }
    }
  }

  .butMenuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addFile {
      height: 40px;
      display: flex;
      padding: 0 15px;
      background: $green-blue-color-text;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      align-items: center;

      .plusIcon {
        display: flex;
        height: 17px;
        width: 17px;
        background: $green-color-light;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        color: $white-color;
        font-size: 14px;
        padding-bottom: 2px;
        margin-right: 10px;
      }
    }

    .choose {
      height: 40px;
    }
  }
}

.NoResults {
  width: 100%;
  min-height: 0;

  .NoResultsMainContainer {
    width: 100%;

    .NoResultsImgContainer {
      width: 100%;

      .iconNoResultMainContainer {
        display: flex;
        margin-top: 10vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .iconNoResultContainer {
          height: 30px;
          width: 30px;
          margin-bottom: 20px;

          .iconNoResult {
            path {
              fill: $grey-color-dark;
            }
          }
        }

        .iconNoResultText {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          text-align: center;
          letter-spacing: -0.4675px;
          color: $grey-color-dark;
        }
      }
    }
  }
}
