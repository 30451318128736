@import '@src/styles/vars.css';

.customInputContainer {
  position: relative;

  &.customInputContainerShared {
    background: $grey-light-but-color;
    border-radius: 12px 0 0 12px;
  }

  .customInputContainerDisabledContainer {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    opacity: 1;
  }

  .customInput {
    position: relative;
    background: $white-color;
    border: 1px solid $white-color;
    border-radius: 6px;

    &.border {
      border: 1px solid $grey-light-but-color;
    }

    &.shared {
      border-radius: 12px 0 0 12px;

      input {
        font-size: 13px;
        line-height: 16px;
        padding: 16px 10px 10px 16px;
        background: $grey-light-but-color;
        border-radius: 12px 0 0 12px;

        &:focus + label {
          font-weight: initial;
          font-size: 12px;
          line-height: 16px;
          padding: 1px 0 0 16px;
        }
      }
    }

    &.invalid.touched.filled {
      border-color: $alert-color;
    }

    input {
      background: none;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $grey-color-text;
      padding: 21px 10px 13px $first-padding;
      margin: 0;
      width: 100%;
      border-radius: 6px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus + label {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: $grey-color-label;
        padding: 1px 0 0 $first-padding;
      }
    }

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $grey-color-label;
      transition: 0.2s ease all;
      padding: 17px 0 0 $first-padding;
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;

      &.standardLabel {
        font-size: 14px;
        line-height: 17px;
        font-style: normal;
        font-weight: initial;
      }
    }

    &.filled label {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: $grey-color-label;
      padding: 1px 0 0 $first-padding;
    }

    &.standardInput {
      input {
        font-size: 13px;
        line-height: 16px;
        padding: 18px 10px 10px 16px;

        &:focus + label {
          font-weight: initial;
          font-size: 12px;
          line-height: 16px;
          padding: 1px 0 0 16px;
        }
      }

      label {
        padding: 17px 0 0 16px;

        &.standardLabel {
          font-size: 13px;
          line-height: 10px;
        }
      }

      &.filled label {
        font-weight: initial;
        font-size: 12px;
        line-height: 16px;
        padding: 1px 0 0 16px;
      }
    }
  }

  .cardInput {
    height: 30px;

    input {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      display: block;
      padding: 8px 10px;
      color: $black-text-color;

      &:focus + label {
        top: -15px;
        left: -10px;
        font-size: 10px;
        line-height: 14px;
      }
    }

    label {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      color: $grey-color-dark;
      position: absolute;
      transition: all 0.5s ease 0s;
      padding: 8px 0 0 11px;
    }

    &.filled label {
      top: -15px;
      left: -10px;
      font-size: 10px;
      line-height: 14px;
    }
  }

  .hideLabel {
    opacity: 0;
  }
}

.customInputContainer .customInputModal {
  border: 1px solid $grey-border-input-color;
  border-radius: 6px;
  margin-top: 26px;
  height: 48px;

  input {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: block;
    padding: 13px 20px;
    color: $black-text-color;

    &:focus + .label {
      top: -25px;
      left: 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  .label {
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    font-weight: 300;
    transition: all 0.5s ease 0s;
    top: 13px;
    left: 25px;
    padding: 0;
    color: $grey-color-label;
  }

  .activeLink {
    top: -25px;
    left: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
}

.customListSelectModal {
  .listItem {
    cursor: pointer;
    border: 1px solid $grey-color;
    padding: 10px 20px;
    background: $grey-color;
  }
}

.customInputContainer.QStandardContainer {
  .QStandardInput {
    border-radius: initial;
    border: initial;

    input {
      padding: 20px 0 10px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      border-bottom: 1px solid $grey-green1-color;
      border-radius: 0;

      &:focus {
        border-bottom: 1px solid $purple-color;
      }
    }

    &.noPurple {
      input {
        &:focus {
          border-bottom: none;
        }
      }
    }

    &:not(.filled) input {
      &:focus + label {
        top: -2px;
        left: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        padding: 1px 0 0 0;
      }
    }

    &.filled label.QStandardLabel {
      top: -2px;
      left: 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      padding: 1px 0 0 0;
    }

    label.QStandardLabel {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      padding: 17px 0 0 0;
      color: $grey-color-dark;
    }
  }

  .CodeInput {
    input {
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      letter-spacing: 15px;
    }
  }

  .hideLabel {
    opacity: 0;
  }
}
