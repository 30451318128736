.Component {
  &.opened .content {
    display: block;
  }
}

.header {
  cursor: pointer;
}

.content {
  display: none;
}
