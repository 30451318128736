.searchContainer {
  padding: 15px;
  background: $mint-green-dark-color;
  border-radius: 15px;

  .search {
    width: 100%;
    position: relative;

    .input {
      border-radius: 10px;
      border: initial;
    }

    .iconContainer {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 15px;
      right: 14px;
      cursor: pointer;

      .icon {
        display: inline-block;
        width: 30px;
        height: auto;
        margin-right: initial;
      }
    }
  }
}
