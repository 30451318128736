.container {
  display: flex;
  border: 1px solid var(--color-v2-border2);
  border-radius: 15px;
  padding: 12px 20px 12px 12px;
  margin-bottom: 12px;
  align-items: center;
}

.preview {
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;

  img {
    background-color: var(--color-v2-background1);
  }
}

.previewPlaceholder {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-v2-background2);

  svg {
    width: 28px;
    height: 28px;
    fill: var(--color-v2-icon3);
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 3px 12px 3px 0;
}

.name {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--color-v2-text1);
  padding-bottom: 6px;
}

.size {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: var(--color-v2-text3);
}

.deleteButton {
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--color-v2-icon3);
  }
}
