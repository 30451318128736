@import '../../styles/vars.css';

.MyLibrary {
  padding: 48px 63px;
  height: 100%;

  @media (min-width: 320px) and (max-width: 1224px) {
    padding: 26px 20px;
  }
}

.FolderCard {
  position: relative;
  height: 100%;
  width: 100%;
  background: $white-color-1;
  border-radius: 15px;
  cursor: pointer;

  &.systemFolder {
    display: block;
  }

  .mainContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 18%;

    .iconContainer {
      height: 48px;
      width: 48px;
    }

    .contentContainer {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      padding-top: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 130px;
    }
  }

  .checkboxContainer {
    position: absolute;
    top: 15px;
    left: 13px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1.1594px solid $green-grey-dark-color;
    transition: all 0.3s ease;

    &.partialSelect {
      background: $green-blue-color-text;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        background: $white-color;
        border-radius: 50%;
        left: 4px;
        top: 4px;
      }
    }

    &.selected {
      background: $green-blue-color-text;

      svg {
        fill: $white-color;
      }
    }
  }
}

.FileCard {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 250px;
  background: rgba(31, 49, 60, 0.1);
  border-radius: 15px;
  cursor: pointer;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12% 16px 12%;

    .iconContainer {
      height: 36px;
      width: 36px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .imagePreview {
        display: block;
      }

      svg {
        fill: $grey-color-dark;
      }
    }

    .imageContentContainer,
    .fileContentContainer {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      .name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 130px;
      }

      .size {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        text-align: left;
        padding-top: 2px;
        color: $grey-color-dark;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .url {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 130px;
      }
    }
  }

  .checkboxContainer {
    position: absolute;
    top: 15px;
    left: 13px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1.1594px solid $green-grey-dark-color;
    transition: all 0.3s ease;

    &.selected {
      background: $green-blue-color-text;

      svg {
        fill: $white-color;
      }
    }
  }

  .moreButtonContainer {
    position: absolute;
    right: 13px;
    top: 15px;
  }
}
